import React from "react";

import {
  Typography,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  inputLayout: {
    display: "grid",
  },
}));

function DFSelect({
  name,
  label,
  options,
  control,
  rules,
  gutterBottom,
  ...props
}) {
  const classes = useStyles();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      onChange={([, data]) => data}
      render={({ field, fieldState, formState: { errors } }) => {
        return (
          <div
            className={classes.inputLayout}
            style={gutterBottom ? { marginBottom: 10 } : {}}
          >
            <Typography variant="caption" color="textSecondary">
              {label}
            </Typography>

            <FormControl
              {...props}
              variant="outlined"
              error={fieldState.invalid}
            >
              <Select
                name={name}
                displayEmpty
                {...field}
                {...props}
                renderValue={(selected) => {
                  if (selected?.length === 0 || selected === undefined) {
                    return (
                      <span style={{ color: "rgba(82,89,117,.4)" }}>
                        {label}
                      </span>
                    );
                  }
                  const newSelected = options.find(
                    (item) => item.value === selected
                  );

                  return newSelected?.name;
                }}
              >
                {options.map((val, idx) => (
                  <MenuItem key={idx} value={val.value}>
                    {val.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors[name]?.message}</FormHelperText>
            </FormControl>
          </div>
        );
      }}
    />
  );
}

export default DFSelect;
