import { LinearProgress, useTheme } from "@material-ui/core";
import DFSplashScreen from "components/DFSplashScreen";
import useUserContext from "hooks/context-hooks/useUserContext";
import Main from "layouts/Main";
import React, { Suspense } from "react";

export default function ProtectedRoute({
  component,
  states,
  countries,
  title,
  ...props
}) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { checkRoles, user } = useUserContext();
  const theme = useTheme();

  let Component = component;
  const triggerReFetch = () => {
    checkRoles(user);
  };

  // useEffect(() => {
  //   if (!user?.current_office?.id) {
  //     // window.location.replace("/");
  //     checkRoles(user);
  //   }
  // }, []);
  if (user?.current_office?.id) {
    return (
      <>
        {/* {isDesktop && browserName !== "Electron" ? (
          <Alert
            variant="filled"
            severity="info"
            action={
              <Button
                color="inherit"
                size="small"
                href="https://app.dentistfind.com/"
              >
                Download
              </Button>
            }
          >
            <Typography variant="caption">
              Download our desktop app here.
            </Typography>
          </Alert>
        ) : null} */}

        <Main
          title={title}
          {...props}
          states={states}
          countries={countries}
          user={user}
          getUser={checkRoles}
          mobileOpen={mobileOpen}
          setMobileOpen={setMobileOpen}
        >
          <Suspense fallback={<LinearProgress />}>
            <Component
              user={user}
              {...props}
              getUser={checkRoles}
              mobileOpen={mobileOpen}
              setMobileOpen={setMobileOpen}
              states={states}
              countries={countries}
              triggerReFetch={triggerReFetch}
            />
          </Suspense>
        </Main>
      </>
    );
  } else {
    return <DFSplashScreen />;
  }
}
