import React, { createContext, useEffect, useRef, useState } from "react";
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import "./App.css";
import Login from "./pages/Login";
import states from "./utils/states.json";
import countries from "./utils/country_list";
import ForgotPassword from "./pages/ForgotPassword";
import ProtectedRoute from "./routes/ProtectedRoute";
import { routes } from "./routes/routes";
import { DFSnackbar } from "./components/DFCustoms";
import CallFeature from "./components/CallFeature";
import CommunicationDataProvider from "./pages/communications/CommunicationDataProvider";
import UserManager from "context-providers/UserManager";
import ContactListManager from "context-providers/ContactListManager";
import { ENVIRONMENT, appMode, domains, modesMap } from "config/config";
import { createBrowserHistory } from "history";
const twentyFourHoursInMs = 1000 * 60 * 60 * 24;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: twentyFourHoursInMs,
    },
  },
});
const history = createBrowserHistory();

export const SnackbarContext = createContext();
export const ModalsOpenContext = createContext();

const App = () => {
  const [snack, setSnack] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const modalsOpen = useRef([]);
  const currentPathname = useRef("");

  useEffect(() => {
    const currentHash = window.location.hash;
    modalsOpen.current = [];
    if (
      currentHash.includes("?") &&
      !currentHash.includes("notification_type")
    ) {
      window.history.replaceState(
        null,
        "",
        `${
          appMode === modesMap.PWA && process.env.NODE_ENV !== "development"
            ? domains[ENVIRONMENT]
            : "http://localhost:3000"
        }/${currentHash.substring(0, currentHash.indexOf("?"))}`
      );
    }
  }, []);

  const handleBackButtonPress = (event) => {
    const newPathName = history.location.hash.split("?")[0];
    const hash = history.location.hash;
    if (
      modalsOpen.current.length === 0 &&
      history.location.hash.includes("?") &&
      (newPathName === currentPathname.current ||
        newPathName.includes("view_appointment"))
    ) {
      if (
        hash.includes("appointment_card_popover") ||
        hash.includes("side_navigation")
      ) {
        window.history.go(-2);
      } else history.back();
    } else if (modalsOpen.current.length === 0 && hash.includes("?")) {
      history.push(newPathName);
    }
    // else {!hash.includes("appointment_card_popover")
    //   if (
    //     newPathName === currentPathname.current
    //     // &&
    //     // history.location.hash.includes("?") &&
    //     // modalsOpen.current.length !== 0
    //   ) {
    //     history.back();
    //     console.log("going back because pathname is the same");
    //   }
    // }
    // debugger;
    currentPathname.current = newPathName;
  };
  useEffect(() => {
    window.addEventListener("popstate", handleBackButtonPress);
    return () => {
      window.removeEventListener("popstate", handleBackButtonPress);
    };
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarContext.Provider value={{ snack, setSnack }}>
        <ModalsOpenContext.Provider value={{ modalsOpen }}>
          <DFSnackbar actions={{ snack, setSnack }} />
          <Router history={history}>
            <UserManager history={history} snack={snack} setSnack={setSnack}>
              <ContactListManager>
                <Switch>
                  <Route path={"/"} exact component={Login} />
                  <Route
                    path={"/forgot_password"}
                    exact
                    component={ForgotPassword}
                  />
                  <CommunicationDataProvider>
                    <CallFeature>
                      {routes.map(
                        ({ path, component, exact, componentProps, title }) => (
                          <Route
                            key={path}
                            path={path}
                            exact={exact}
                            render={(props) => (
                              <ProtectedRoute
                                title={title}
                                component={component}
                                {...componentProps}
                                {...props}
                                states={states}
                                countries={countries}
                              />
                            )}
                          />
                        )
                      )}
                    </CallFeature>
                  </CommunicationDataProvider>
                  <Redirect to="/" />
                </Switch>
              </ContactListManager>
            </UserManager>
          </Router>
        </ModalsOpenContext.Provider>
      </SnackbarContext.Provider>
    </QueryClientProvider>
  );
};

export default App;
