import React, { useState, useEffect } from "react";

import moment from "moment";
import { DatePicker } from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

export default function DFDatePicker({
  formik,
  name,
  gutterBottom,
  label,
  required,
  minDate,
  variant,
  format,
  ...props
}) {
  const [date, setDate] = useState(null);
  useEffect(() => {
    const initDate = formik.initialValues[name]
      ? moment(formik.initialValues[name])
      : null;
    setDate(initDate);
  }, [formik.initialValues, name]);
  const handleChange = (e) => {
    setDate(e);
    formik.setFieldValue(name, e._d);
  };
  return (
    <div style={gutterBottom ? { marginBottom: 10 } : {}}>
      <Typography variant="caption" color="textSecondary">
        {label}
        {required ? "*" : ""}
      </Typography>

      <DatePicker
        format={format}
        id={name}
        name={name}
        {...props}
        autoOk
        variant={variant}
        minDate={minDate}
        inputVariant="outlined"
        value={date}
        onChange={handleChange}
        disableToolbar={true}
        TextFieldComponent={(params) => (
          <TextField
            {...params}
            size="small"
            placeholder="Select date"
            {...props}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            helperText={formik.touched[name] && formik.errors[name]}
          />
        )}
      />
    </div>
  );
}
