import { App } from "@capacitor/app";
import { ModalsOpenContext } from "App";
import { appMode, modesMap } from "config/config";
import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

export default function useModal(key) {
  const history = useHistory();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState();
  const { modalsOpen } = useContext(ModalsOpenContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleBackButtonPress = (event) => {
    setIsOpen(false);
    setAnchorEl(null);

    // if (anchorEl || isOpen) {
    //   event.preventDefault();
    //   if (modalsOpen.current[modalsOpen.current.length - 1] === key) {
    //     const newModalsOpen = modalsOpen.current.filter((it) => it !== key);
    //     modalsOpen.current = newModalsOpen;
    //     setIsOpen(false);
    //     setAnchorEl(null);
    //   }
    //   const includes_view_appointment =
    //     window.location.hash.includes("view_appointment");
    //   const includes_params = window.location.hash.includes("?");
    //   if (
    //     modalsOpen.current.length === 0 &&
    //     !includes_view_appointment &&
    //     includes_params
    //   ) {
    //     history.push(location.pathname);
    //   }

    // history.push(location.pathname);
    // history.forward();
    // setIsOpen(false);
    // setAnchorEl(null);
    // }
  };
  const open = (anchorEl) => {
    if (anchorEl) setAnchorEl(anchorEl);
    else setIsOpen(true);

    // if (appMode !== modesMap.PWA) {
    //   if (appMode === modesMap.ANDROID) {
    //     modalsOpen.current.push(key);
    //   }

    //   if (anchorEl) setAnchorEl(anchorEl);
    //   else setIsOpen(true);
    //   return;
    // }

    // let newModalsOpen = modalsOpen.current || [];
    // newModalsOpen.push(key);
    // modalsOpen.current = newModalsOpen;
    // const params = history.location.search;
    // if (params !== "") {
    //   history.push(`${params}&${key}`);
    // } else {
    //   history.push(`?${key}`);
    // }
    // if (anchorEl) setAnchorEl(anchorEl);
    // else setIsOpen(true);
    // // history.push("#");
    // // if (anchorEl) setAnchorEl(anchorEl);
    // // else setIsOpen(true);
  };

  const close = (goBack = true) => {
    // if (appMode !== modesMap.PWA) {
    setAnchorEl(false);
    setIsOpen(false);
    // return;
    // }

    // if (window.location.hash.includes("notification_type")) {
    //   setIsOpen(false);
    //   history.push("#");
    //   return;
    // }

    // if (goBack) {
    //   history.goBack();
    // } else {
    //   setAnchorEl(false);
    //   setIsOpen(false);
    //   const newModalsOpen = modalsOpen.current.filter((it) => it !== key);
    //   modalsOpen.current = newModalsOpen;
    //   debugger;
    //   // debugger;
    //   // history.push("#");
    //   // history.push(location.pathname);
    // }
    // history.forward();
    // setAnchorEl(false);
    // setIsOpen(false);
  };

  // useEffect(() => {
  //   if (appMode === modesMap.PWA)
  //     window.addEventListener("popstate", handleBackButtonPress);
  //   let backButtonListener;
  //   if (appMode === modesMap.ANDROID) {
  //     backButtonListener = App.addListener("backButton", (canGoback) => {
  //       if (isOpen) {
  //         if (modalsOpen.current[modalsOpen.current.length - 1] === key) {
  //           let arr = modalsOpen.current;
  //           arr.pop();
  //           modalsOpen.current = arr;
  //           close();
  //         }
  //       } else {
  //         if (!canGoback) {
  //           App.exitApp();
  //         } else {
  //           window.history.back();
  //         }
  //       }
  //     });
  //   }
  //   return () => {
  //     if (appMode === modesMap.PWA)
  //       window.removeEventListener("popstate", handleBackButtonPress);
  //     if (appMode === modesMap.ANDROID) backButtonListener.remove();
  //   };
  // }, [isOpen, anchorEl]);
  // }, []);

  return [anchorEl ? anchorEl : isOpen, close, open];
}
