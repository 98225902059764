import React, { useEffect, useRef, useState } from "react";
import { useScrollTrigger } from "@material-ui/core";
import SearchFilter from "components/SearchFilter";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
export default function useSearchFilter({
  listFilters,
  initialState,
  localStorageKey,
  searchBy,
  hasSearch,
  resetFiltersOnchange,
  enableDatesAfterToday = false,
}) {
  const trigger = useScrollTrigger();
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState(initialState);
  const [filtersPresent, setFiltersPresent] = useState(false);
  const history = useHistory();

  const currentPathname = useRef("");

  useEffect(() => {
    const unlisten = history.listen((event) => {
      // This function will be called whenever the route changes
      let curr = currentPathname.current.replace("#", "");
      const newPathname = history.location.pathname;
      if (curr !== newPathname) setSearch("");
      currentPathname.current = newPathname;
    });

    // Cleanup function to unsubscribe from the listener when the component unmounts
    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(filters));
  }, [filters]);

  const handleSearch = (e) => {
    setSearch(e);
    if (resetFiltersOnchange === true) setFilters(initialState);
  };
  const handleApplyFilter = (e) => {
    setFilters(e);
  };
  const displayFilter = (filterPosition) => {
    return (
      <SearchFilter
        position={filterPosition}
        trigger={filterPosition ? false : trigger}
        filters={listFilters}
        savedFilters={filters}
        initialState={initialState}
        handleSearch={handleSearch}
        applyFilter={handleApplyFilter}
        filtersPresent={filtersPresent}
        setFiltersPresent={setFiltersPresent}
        hasSearch={hasSearch}
        searchBy={searchBy}
        localStorageKey={localStorageKey}
        enableDatesAfterToday={enableDatesAfterToday}
      />
    );
  };

  return [displayFilter, { search, filters, filtersPresent }];
}
