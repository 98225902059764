import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { useInView } from "react-intersection-observer";
import { useTheme } from "@material-ui/core/styles";
import { Typography, CircularProgress } from "@material-ui/core";
import LogDetails from "./LogDetails";
import { allConvoStyles } from "assets/materialStyles";
import { useRef } from "react";
import BubbleIncomingCall from "./BubbleIncomingCall";
import BubbleOutgoingCall from "./BubbleOutgoingCall";
import BubbleSmsLog from "./BubbleSmsLog";
import BubbleWebFormLog from "./BubbleWebFormLog";
import BubbleChatWidgetLog from "./BubbleChatWidgetLog";
import DividerWithText from "./DFCustoms/DividerWithText";
import useModal from "hooks/useModal";

export default function AllConvoNew({
  logHistoryProps,
  upsertContactCallBack,
  user,
}) {
  const { ref, inView } = useInView();
  const classes = allConvoStyles();
  const theme = useTheme();
  const [isOpen, closeModal, openModal] = useModal("log_details");
  const [singleData, setSingleData] = useState("");
  const playingAudioRef = useRef(null);

  const { data, fetchNextPage, isFetchingNextPage, isLoading, refetch } =
    logHistoryProps;

  const [convo, setConvo] = useState([]);

  useEffect(() => {
    let arr = [];
    data?.pages?.map((item) => {
      item?.data?.map((log) => {
        arr.push(log);
      });
    });

    setConvo(arr);
  }, [data]);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  const lastElementRef = useCallback((node) => {
    if (node?.id === "scroll-into-view") node.scrollIntoView();
  }, []);

  const handleOpenDialog = async (e) => {
    openModal();
    if (playingAudioRef.current) playingAudioRef.current.pause();
    setSingleData(e);
  };

  const divider = (val, idx) => {
    let dividerText = null;
    if (
      !moment(val.created_at).isSame(moment(convo[idx + 1]?.created_at), "day")
    ) {
      if (moment(val.created_at).isSame(moment(), "year")) {
        if (moment(val.created_at).isSame(moment(), "day")) {
          dividerText = "Today";
        } else {
          // return formatted date without the year
          dividerText = moment(val.created_at).format("MMM D");
        }
      } else {
        // return formatted date with the year
        dividerText = moment(val.created_at).format("MMM D, YYYY");
      }
    }

    if (dividerText) {
      return (
        <DividerWithText>
          <Typography
            component="p"
            variant="caption"
            align="center"
            style={{ fontSize: ".6rem", color: "rgba(0,0,0,.5)" }}
            gutterBottom
          >
            {dividerText}
          </Typography>
        </DividerWithText>
      );
    }
  };

  return (
    <>
      {isLoading || isFetchingNextPage ? (
        <div style={{ display: "grid", placeItems: "center" }}>
          <CircularProgress />
        </div>
      ) : null}
      <div style={{ display: "flex", flexDirection: "column-reverse" }}>
        {convo?.map((val, idx) => (
          <div
            ref={convo?.length === idx + 1 ? ref : lastElementRef}
            key={idx}
            id={idx === 0 ? "scroll-into-view" : ""}
            style={{ padding: "5px 5px 0", display: "grid" }}
          >
            {divider(val, idx)}

            {val.type === "call_log" && (
              <BubbleIncomingCall
                handleOpenDialog={handleOpenDialog}
                val={val}
                classes={classes}
                theme={theme}
                playingAudioRef={playingAudioRef}
              />
            )}

            {val.call_type === 5 && (
              <BubbleOutgoingCall
                handleOpenDialog={handleOpenDialog}
                val={val}
                classes={classes}
                theme={theme}
                playingAudioRef={playingAudioRef}
              />
            )}

            {val.type === "sms_log" && (
              <BubbleSmsLog
                val={val}
                handleOpenDialog={handleOpenDialog}
                classes={classes}
              />
            )}
            {val.type === "web_form_log" && (
              <BubbleWebFormLog
                handleOpenDialog={handleOpenDialog}
                val={val}
                classes={classes}
              />
            )}
            {val.type === "ChatWidget" && val.body !== "first_message" && (
              <BubbleChatWidgetLog
                handleOpenDialog={handleOpenDialog}
                val={val}
                classes={val}
              />
            )}
          </div>
        ))}
      </div>
      {convo?.length === 0 && !isLoading ? (
        <div style={{ padding: "50px 0" }}>
          <Typography align="center" color="textSecondary">
            Nothing to show...
          </Typography>
        </div>
      ) : null}
      <LogDetails
        upsertContactCallBack={upsertContactCallBack}
        updateLogDetailsCallBack={({ isBooked, patientLogType, notes }) => {
          refetch();
        }}
        playingAudioRef={playingAudioRef}
        user={user}
        refetchLogs={refetch}
        open={isOpen}
        singleData={singleData}
        // updateLogs={updateLogs}
        close={closeModal}
      />
    </>
  );
}
