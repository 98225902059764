import React from "react";

import { useTheme } from "@material-ui/core/styles";

export default function DFOutgoingCall() {
  const theme = useTheme();
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0009 14.9246L15.8092 13.1155C16.0528 12.8749 16.3609 12.7102 16.6962 12.6414C17.0316 12.5725 17.3796 12.6026 17.6982 12.7279L19.9021 13.6082C20.224 13.7389 20.5001 13.962 20.6955 14.2495C20.8909 14.5369 20.9968 14.8758 21 15.2234V19.2614C20.9981 19.4978 20.9484 19.7315 20.8539 19.9482C20.7594 20.1649 20.6221 20.3603 20.4501 20.5225C20.2781 20.6847 20.0751 20.8105 19.8533 20.8921C19.6315 20.9738 19.3955 21.0097 19.1594 20.9977C3.71616 20.0367 0.600064 6.95351 0.0107509 1.94636C-0.0166054 1.70047 0.00838933 1.45158 0.0840907 1.21605C0.159792 0.980522 0.284485 0.763698 0.449965 0.579841C0.615445 0.395985 0.817963 0.249264 1.04419 0.14933C1.27043 0.049396 1.51525 -0.00148531 1.76255 3.3006e-05H5.6617C6.00965 0.00106334 6.34933 0.106207 6.63705 0.301939C6.92478 0.497672 7.14739 0.775042 7.27626 1.09837L8.15619 3.30313C8.28556 3.62058 8.31857 3.96912 8.25109 4.30522C8.18361 4.64132 8.01863 4.95008 7.77677 5.19293L5.96847 7.00196C5.96847 7.00196 7.00986 14.0523 14.0009 14.9246Z"
        fill="white"
      />
    </svg>
  );
}
