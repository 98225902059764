import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import DataUsageOutlinedIcon from "@material-ui/icons/DataUsageOutlined";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import ForumOutlinedIcon from "@material-ui/icons/ForumOutlined";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import DialpadIcon from "@material-ui/icons/Dialpad";
import React from "react";

const useStyles = makeStyles((theme) => ({
  appBarDown: {
    top: "auto",
    bottom: 0,
    backgroundColor: "#323233",
  },
  toolbarDown: {
    display: "grid",
    alignItems: "stretch",
    gridTemplateColumns: "repeat(5,1fr)",
  },
  toolbarDown2: {
    color: "#fff",
    backgroundColor: "#323233",
    display: "grid",
    alignItems: "stretch",
    gridTemplateColumns: "repeat(3,1fr)",
  },

  iconUnderline: {
    position: "relative",
    "&::after": {
      content: '""',
      width: "100%",
      height: 2,
      position: "absolute",
      bottom: 0,
      backgroundColor: "#fff",
    },
  },
  popOverRoot: {
    "& div:nth-of-type(1)": {
      position: "unset",
    },
  },
}));

export default function BottomNavigation(props) {
  const classes = useStyles();
  return (
    <AppBar position="fixed" color="primary" className={classes.appBarDown}>
      <Toolbar disableGutters={true} className={classes.toolbarDown}>
        <Tooltip title="Dashboard" placement="top">
          <Button
            fullWidth
            onClick={() => {
              props.history.push("/dashboard");
            }}
            className={
              props.match.path === "/dashboard" ? classes.iconUnderline : ""
            }
            color="inherit"
          >
            <DataUsageOutlinedIcon />
          </Button>
        </Tooltip>
        <Tooltip title="Contacts" placement="top">
          <Button
            fullWidth
            onClick={() => {
              props.history.push("/patients");
            }}
            className={
              props.match.path === "/patients" ? classes.iconUnderline : ""
            }
            color="inherit"
          >
            <GroupOutlinedIcon />
          </Button>
        </Tooltip>
        <Tooltip title="Dialer" placement="top">
          <Button
            fullWidth
            onClick={() => {
              props.history.push("/dialer");
            }}
            className={
              props.match.path === "/dialer" ? classes.iconUnderline : ""
            }
            color="inherit"
          >
            <DialpadIcon />
          </Button>
        </Tooltip>
        <Tooltip title="Communications" placement="top">
          <Button
            onClick={() => {
              props.history.push("/communications");
            }}
            fullWidth
            className={
              props.match.path === "/communications"
                ? classes.iconUnderline
                : ""
            }
            color="inherit"
          >
            <ForumOutlinedIcon />
          </Button>
        </Tooltip>

        <Tooltip title="Appointments" placement="top">
          <Button
            fullWidth
            onClick={() => {
              props.history.push("/appointments");
            }}
            className={
              props.match.path === "/appointments" ||
              props.match.path === "/add_appointment/:id" ||
              props.match.path === "/add_appointment" ||
              props.match.path === "/view_appointment/:id"
                ? classes.iconUnderline
                : ""
            }
            color="inherit"
          >
            <DateRangeOutlinedIcon />
          </Button>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
}
