import { lazy } from "react";
const Dashboard = lazy(() => import("pages/dashboard"));
const Contacts = lazy(() => import("pages/contacts"));
const MyProfile = lazy(() => import("pages/MyProfile"));
const ClinicInfo = lazy(() => import("pages/ClinicInfo"));
const CallLogs = lazy(() => import("pages/callLogs"));
const Dialer = lazy(() => import("pages/dialer"));
const SMSLogs = lazy(() => import("pages/smsLogs"));
const WebFormLogs = lazy(() => import("pages/webFormLogs"));
const Appointments = lazy(() => import("pages/appointments"));
const ViewAppointment = lazy(() => import("pages/ViewAppointment"));
const Communications = lazy(() => import("pages/communications"));
const NewPatientTrackingForm = lazy(() =>
  import("pages/patient-tracking-form")
);

export const routes = [
  {
    path: "/dashboard",
    title: "DASHBOARD",
    component: Dashboard,
    componentProps: {
      hasDrawer: true,
      hasBackButton: false,
    },
    exact: true,
  },
  {
    path: "/patients",
    title: "CONTACTS",
    component: Contacts,
    componentProps: {
      hasDrawer: true,
      hasBackButton: true,
    },
    exact: true,
  },
  {
    path: "/call_logs",
    title: "CALL LOGS",
    component: CallLogs,
    componentProps: {
      hasDrawer: true,
      hasBackButton: true,
    },
    exact: true,
  },
  {
    path: "/sms_logs",
    title: "SMS LOGS",
    component: SMSLogs,
    componentProps: {
      hasDrawer: true,
      hasBackButton: true,
    },
    exact: true,
  },
  {
    path: "/web_form_logs",
    title: "WEB FORM LOGS",
    component: WebFormLogs,
    componentProps: {
      hasDrawer: true,
      hasBackButton: true,
    },
    exact: true,
  },
  {
    path: "/profile",
    title: "",
    component: MyProfile,
    componentProps: {
      hasDrawer: true,
      hasBackButton: false,
    },
    exact: true,
  },
  {
    path: "/clinic",
    title: "",
    component: ClinicInfo,
    componentProps: {
      hasDrawer: true,
      hasBackButton: false,
    },
    exact: true,
  },
  {
    path: "/appointments",
    title: "APPOINTMENTS",
    component: Appointments,
    componentProps: {
      hasDrawer: true,
      hasBackButton: true,
    },
    exact: true,
  },
  {
    path: "/view_appointment/:id",
    title: "",
    component: ViewAppointment,
    componentProps: {
      hasDrawer: true,
      hasBackButton: true,
    },
    exact: true,
  },
  {
    path: "/communications",
    title: "COMMUNICATIONS",
    component: Communications,
    componentProps: {
      hasDrawer: true,
      hasBackButton: true,
    },
    exact: true,
  },
  {
    path: "/dialer",
    title: "DIALER",
    component: Dialer,
    componentProps: {
      hasDrawer: true,
      hasBackButton: false,
    },
    exact: true,
  },
  {
    path: "/patient-tracking-form",
    title: "New Patient Tracking Form",
    component: NewPatientTrackingForm,
    componentProps: {
      hasDrawer: true,
      hasBackButton: false,
    },
    exact: true,
  },
];
