import { GET, PUT, POST } from "utils/restUtils";
import config from "config/config";
import useUserContext from "hooks/context-hooks/useUserContext";

export default function useContactService() {
  const { token, officeId } = useUserContext();

  const customHeader = {
    headers: {
      "api-token": token,
      current_office: officeId,
    },
  };

  const getAllContacts = (param) => {
    return GET(
      `${config.BASE_URL}/api/v1/patient_leads?current_office=${officeId}&sort=${param.sort}&search=${param.search}&patient_status=${param.filter}&page=${param.page}`,
      customHeader
    );
  };

  const getPatientInfo = (id) => {
    return GET(
      `${config.BASE_URL}/api/v1/patient_leads/${id}?current_office=${officeId}`,
      customHeader
    );
  };

  const addPatient = (data) => {
    return POST(
      `${config.BASE_URL}/api/v1/patient_leads?api_token=${token}&current_office=${officeId}`,
      data
    );
  };

  const updatePatientInfo = (id, data) => {
    return PUT(
      `${config.BASE_URL}/api/v1/patient_leads/${id}?api_token=${token}&current_office=${officeId}`,
      data
    );
  };

  const getPatientList = () => {
    return GET(
      `${config.BASE_URL}/api/v1/autocompletes/autocomplete_patient_lead_name?api_token=${token}&current_office=${officeId}`
    );
  };

  return {
    getPatientList,
    getPatientInfo,
    getAllContacts,
    addPatient,
    updatePatientInfo,
  };
}

//Additional Rest Endpoints

export const getPatientInfo = (params, customHeader) => {
  return GET(
    `${config.BASE_URL}/api/v1/patient_leads/${params.id}?current_office=${params.current_office}`,
    customHeader
  );
};

export const getRecentlyUpdatedContacts = (params, data, customHeader) => {
  console.log(data);
  return GET(
    `${config.BASE_URL}/api/v1/patient_leads?current_office=${params.current_office.id}&sort=-updated_at&page=1`,

    customHeader
  );
};

export const getMediumList = (params, data) => {
  return GET(
    `${config.BASE_URL}/api/v1/patient_leads/list_mediums_for_patient?current_office=${params?.current_office?.id}`,
    data
  );
};

export const getSourceList = (params, data) => {
  return GET(
    `${config.BASE_URL}/api/v1/patient_leads/list_sources_for_patient?current_office=${params?.current_office?.id}&medium_id=${params?.medium_id}&patient_id=${params?.patient_id}`,
    data
  );
};

export const getContactTypes = (data) => {
  return GET(
    `${config.BASE_URL}/api/v1/patient_leads/list_contact_types`,
    data
  );
};
