import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";

moment.locale("EST5EDT");

const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltipPlacementTop: {
        margin: "4px 0",
      },
      tooltipPlacementBottom: {
        margin: "4px 0",
      },
    },
  },
  typography: { useNextVariants: true },
  palette: {
    primary: {
      main: "#00ac8c",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#fb005b",
      contrastText: "#ffffff",
    },
    background: {
      default: "#fff",
    },
    text: {
      primary: "#393E41",
    },
  },
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <MuiPickersUtilsProvider
      libInstance={moment}
      utils={MomentUtils}
      locale="EST5EDT"
    >
      <App />
    </MuiPickersUtilsProvider>
  </MuiThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

serviceWorkerRegistration.register();
