import { PATIENT_STATUS, LOG_STATUS } from "./constants";
import parsePhoneNumber from "libphonenumber-js";
import { capitalize, includes, split } from "lodash";

export const patientStatus = (e, keyToSearch, keyToReturn) => {
  const data = PATIENT_STATUS.filter((val) => val[keyToSearch] === e);
  return data?.[0]?.[keyToReturn];
};
export const logStatus = (e, b, c) => {
  const data = LOG_STATUS.filter((val) => val[b] === e);
  return data?.[0]?.[c];
};

export const patientStatusIdentifier = (type, e) => {
  switch (type) {
    case "Existing Patient":
    case "Existing Patients":
      return e === "string" ? "Existing Patient" : 2;
    case "Non Patient Related":
      return e === "string" ? "Non Patient" : 3;
    case "Potential Patient":
    case "Potential Patients":
      return e === "string" ? "Potential Patient" : 1;
    case "Unknown":
    case "unknown":
      return e === "string" ? "Unknown" : 4;
    case "By office":
    case "By Office":
      return e === "string" ? "By Office" : 5;
    case "Spam":
    case "spam":
      return e === "string" ? "Spam" : 6;
    case "Wrong number":
    case "Wrong Number":
      return e === "string" ? "Wrong Number" : 7;
    case "Untagged":
      return e === "string" ? "Untagged" : 8;
    default:
      return;
  }
};

export const logLogType = (log, e) => {
  switch (log) {
    case "all_logs_data":
    case "all_logs_status_breakdown":
      return e ? "all_logs" : "type";
    case "call_log":
      // case "call_logs_status_breakdowns_breakdown":
      return e ? "call_logs" : "call_type";
    case "sms_log":
    case "sms_logs_status_breakdown":
      return e ? "sms_logs" : "sms_type";
    case "web_form_log":
    case "web_logs_status_breakdown":
      return e ? "web_logs" : "web_type";
    case "df_call_logs":
      return "df_call_logs";
    default:
      return;
  }
};

export const logTypeNameIdentifier = (e) => {
  switch (e) {
    case "all_log":
      return "all_type_name";
    case "call_log":
      return "call_type_name";
    case "sms_log":
      return "sms_type_name";
    case "web_form_log":
      return "web_type_name";
    default:
      return;
  }
};

export const callStatusIdentifier = (e, b, c) => {
  switch (e) {
    case "completed":
    case "Completed":
      return b ? c.palette.primary.main : "completed";
    case "no-answer":
      return b ? c.palette.secondary.main : "no-answer";
    case "Busy":
    case "busy":
      return b ? c.palette.warning.main : "busy";
    default:
      return;
  }
};

export const smsStatusIdentifier = (e, b, c) => {
  switch (e) {
    case "delivered":
      return b ? c.palette.primary.main : "delivered";
    case "sent":
      return b ? c.palette.info.main : "sent";
    case "failed":
      return b ? c.palette.secondary.main : "failed";
    case "queued":
      return b ? c.palette.warning.main : "queued";
    case "sending":
      return b ? c.palette.warning.main : "sending";
    default:
      return;
  }
};

export const appointmentStatusChecker = (e) => {
  if (e) {
    return "true";
  } else if (e === null) {
    return "";
  } else {
    return "false";
  }
};
export const appointmentStatusReverse = (e) => {
  if (e === "true") {
    return true;
  } else if (e === "") {
    return null;
  } else {
    return false;
  }
};

export const logTypeDetailText = ({ type, call_sid }, b) => {
  switch (type) {
    case "call_log":
      return b ? "Call Details" : "call_type_name";
    case "sms_log":
      return b ? "SMS Details" : "sms_type_name";
    case "web_form_log":
      return b ? "Web Form Details" : "web_type_name";
    case "ChatWidget":
      return b ? "Chat Details" : "ambot";
    default:
      if (call_sid) {
        return "Call Details";
      }
      return;
  }
};
export const alertTypeIdentifier = (e) => {
  switch (e) {
    case "call_log":
      return "Call Log";
    case "sms_log":
      return "SMS Log";
    case "web_form_log":
      return "Web Form Log";
    default:
      return;
  }
};

const webFormTypes = (type) => {
  switch (type) {
    case "Menu Contact Form":
      return "Menu Contact Form";
    case "Slider Form":
      return "Slider Form";
    case "Contact-us-menu-form":
      return "Contact Us Menu Form";

    case "Mega-menu-form":
      return "Mega-menu-form";

    case "Banner Form":
      return "Banner Form";

    case "Reviews Form":
      return "Reviews Form";

    case "Contact Form":
      return "Online Message";

    case "Booking an Appointment":
      return "Appointment Request";

    case "Booking An Appointment":
      return "Appointment Request";

    case "Book an Appointment":
      return "Appointment Request";

    case "Book An Appointment":
      return "Appointment Request";

    case "Service Sidebar":
      return "Service Sidebar";

    case "Home Page Form":
      return "Home Page Form";

    case "Homepage Form":
      return "Homepage Form";

    default:
      return null;
  }
};

export const notifHeaderText = (type, formType) => {
  switch (type) {
    case "sms":
      return "New Text Message";

    case "web-form":
      return webFormTypes(formType);

    default:
      return null;
  }
};

export const notifLogIdentifier = (type, e) => {
  switch (type) {
    case 10:
      return e ? "call_log" : "call_log_id";

    case 11:
      return e ? "sms_log" : "sms_log_id";

    case 12:
      return e ? "web_form_log" : "web_form_id";
    case 46:
      return e ? "ChatWidget" : "log_id";
    case 5:
      return e ? "call_log" : "call_log_id";
    default:
      return null;
  }
};

export const formatPhoneNumber = (str, code) => {
  let data = str ? str.replace(/\s/g, "") : "";
  let phoneNUm = code ? parsePhoneNumber(data, code) : parsePhoneNumber(data);
  let newPhoneNum = "";
  if (phoneNUm) {
    newPhoneNum = phoneNUm.formatInternational();
  } else {
    newPhoneNum = data;
  }

  return newPhoneNum;
};

export const countryToFlag = (isoCode) => {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
};

export const fromAndToFormatter = (data, direction) => {
  if (direction === "from") {
    switch (data?.type) {
      case "call_log":
        return data?.from || "N/A";
      case "sms_log":
        return data?.from || "N/A";
      case "web_form_log":
        return data?.email || "N/A";
      case "ChatWidget":
        return data?.email || "N/A";
      default:
        if (data.call_type === 5) return data?.from;
        else return "N/A";
    }
  } else if (direction === "to") {
    switch (data?.type) {
      case "call_log":
        return data?.to || "N/A";
      case "sms_log":
        return data?.to || "N/A";
      case "web_form_log":
        return "N/A";
      case "ChatWidget":
        if (data.direction === "out") {
          return data?.patient_leads[0].email || "N/A";
        } else {
          return "N/A";
        }
      default:
        if (data.call_type === 5) return data?.to;
        else return "N/A";
    }
  }
};

export const formatDisplayedNumber = (val, showCountryCode = true) => {
  let number = val?.slice(-10);
  let countryCode = "";

  if (showCountryCode) {
    countryCode = val?.slice(0, -10) + " ";
  }
  let cleaned = ("" + number).replace(/\D/g, "");

  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return countryCode + "(" + match[1] + ") " + match[2] + "-" + match[3];
  } else {
    return val;
  }
};

export const checkSmsNotificationType = (text) => {
  if (text?.includes("You have a chat")) {
    return "Chat widget SMS notification";
  } else if (text?.includes("we have received a web form")) {
    return "Web form log SMS notification";
  }
};

export const removeAllNonNumbers = (string, keepPlusSign = false) => {
  if (keepPlusSign) {
    return `+${string.replace(/\D/g, "")}`;
  } else {
    return `${string.replace(/\D/g, "")}`;
  }
};

export const getCountryCode = (number, defaultCountryCode) => {
  try {
    return parsePhoneNumber(number).countryCallingCode;
  } catch {
    console.log("invalid number, using default country code");
    return defaultCountryCode || "1";
  }
};

export class DFPhoneNumber {
  constructor(value, countryCode) {
    this.value = value;
  }
  removeAllNonNumbers() {
    this.value = removeAllNonNumbers(this.value);
    return this;
  }

  formatNational() {
    try {
      this.value = parsePhoneNumber(this.value).formatNational();
    } catch {
      this.value = formatDisplayedNumber(this.value);
    }
    return this;
  }

  appendCountryCode(countryCode = "1") {
    return "+" + countryCode + removeAllNonNumbers(this.value);
  }
}

export const capitalizeWords = (string, separator) => {
  let finalString;
  split(string.replace(/\s+/g, " ").trim(), separator).map((item) => {
    finalString = finalString
      ? `${finalString} ${capitalize(item)}`
      : capitalize(item);
  });
  return finalString;
};
