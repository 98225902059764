import React, { useState, createContext, useEffect } from "react";
import BottomNavigation from "./BottomNavigation";
import SideNavigation from "./SideNavigation";
import Header from "./Header";
import useSnackBar from "hooks/useSnackBar";
import useInterval from "hooks/useInterval";
import useModal from "hooks/useModal";
import useSyncContacts from "hooks/useSyncContacts";
const ipcRenderer = window?.ipcRenderer;
export const MainContext = createContext();

function Main({ user, title, ...props }) {
  const [isOpen, closeModal, openModal] = useModal("side_navigation");

  const [backButtonCallback, setBackButtonCallback] = useState();

  const { renderSnackBar } = useSnackBar({ user, ...props });

  // states for local contact list feature
  const [revertContacts, setRevertContacts] = useState(false);
  const { isSyncing, checkSync } = useSyncContacts();

  const [notification] = useState("");

  const drawerToggle = () => {
    if (isOpen) closeModal();
    else openModal();
  };

  const [callLogsViewMode, setCallLogsViewMode] = useState("DEFAULT");
  const [webFormLogsViewMode, setWebFormLogsViewMode] = useState("DEFAULT");

  useEffect(() => {
    if (callLogsViewMode === "TABLE" || webFormLogsViewMode === "TABLE") {
      sendMessageToMainProcess("maximize-window");
    } else {
      sendMessageToMainProcess("unmaximize-window");
    }
  }, [callLogsViewMode, webFormLogsViewMode]);

  const sendMessageToMainProcess = (message) => {
    window.parent.postMessage(message, "*");
  };

  useInterval(
    () => {
      // Your custom logic here
      checkSync(user, isSyncing);
    },
    // Delay in milliseconds or null to stop it
    60000
    // 3000
  );
  return (
    <div style={{ display: "flex" }}>
      <SideNavigation
        user={user}
        {...props}
        drawerToggle={drawerToggle}
        drawerOpen={isOpen}
      />
      <main style={{ width: "100%" }}>
        {/* =========================================== Child Components ========================================= */}
        <MainContext.Provider
          value={{
            revertContacts,
            setRevertContacts,
            isSyncing,
            // mediumList: mediumList,
            openSideBar: isOpen,
            setBackButtonCallback: setBackButtonCallback,
            callLogsView: { set: setCallLogsViewMode, mode: callLogsViewMode },
            webFormLogsView: {
              set: setWebFormLogsViewMode,
              mode: webFormLogsViewMode,
            },
          }}
        >
          <Header
            title={title}
            drawerToggle={drawerToggle}
            revertContacts={revertContacts}
            setRevertContacts={setRevertContacts}
            isSyncing={isSyncing}
            user={user}
            {...props}
            backButtonCallback={backButtonCallback}
            notification={notification}
          />
          {/* 
          <Button
            onClick={async () => {
              navigator.serviceWorker.ready.then(async (registration) =>
                registration.pushManager.getSubscription().then((res) => {
                  if (!res) {
                    console.log(
                      "No push notification subscription found. Subscribing again..."
                    );
                  } else {
                    console.log(res);
                    fetch(`${config.BASE_URL}/api/v1/activate_notification`, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        user_id: "6336eb9e421aa928237cbeff",
                        subscription: res,
                      }),
                    })
                      .then((response) => {
                        console.log(
                          "api response =>",
                          response.body.getReader()
                        );
                      })
                      .catch((err) => {
                        console.error("api request failed:", err);
                      });
                  }
                })
              );
            }}
          >
            Activate Notification
          </Button> */}

          {props.children}
          {renderSnackBar()}
        </MainContext.Provider>
        {/* =========================================== Child Components ========================================= */}

        <BottomNavigation {...props} />
      </main>
    </div>
  );
}

export default Main;
