import { useEffect, useRef, useState } from "react";

function useDFMutation(mutationFn, { onCompleted, onError }) {
  const [loading, setLoading] = useState(false);

  const mutation = async (variables) => {
    try {
      if (!loading) setLoading(true);
      let { data: oneData } = await mutationFn(variables);
      setLoading(false);
      if (onCompleted) onCompleted(oneData);
    } catch (err) {
      if (onError) onError(err);
    }
  };

  return [mutation, { loading }];
}

export default useDFMutation;
