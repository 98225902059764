import React, { useState, useEffect } from "react";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import {
  AppBar,
  Button,
  Drawer,
  InputBase,
  Toolbar,
  Typography,
  Chip,
  Grid,
} from "@material-ui/core";
import moment from "moment";
import { DateRange } from "react-date-range";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { convertToKey } from "utils/commonUtils";

import {
  PATIENT_STATUS,
  SORT_PATIENT,
  SORT_CONVERSATION,
  PATIENT_LOG_TYPES,
  CALL_STATUS,
  SMS_STATUS,
  SORT_PATIENT_V2,
} from "utils/constants";
import useDebounce from "hooks/useDebounce";
import callsService from "services/callsService";
import { DFFilterIcon, DFSearchIcon, DFSortNameIcon } from "components/DFIcons";
import { searchFilterStyles } from "assets/materialStyles";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "assets/materialWithStyles";
import DFDivider from "./DFCustoms/DFDivider";
import DashboardNav from "pages/dashboard/DashboardNav";
import DFIconButton from "./DFCustoms/DFIconButton";
import useModal from "hooks/useModal";
let initialStateDates = {
  selectedLogType: "all_logs_status_breakdown",
  dateRange: {
    startDate: moment()._d,
    endDate: moment()._d,
    key: "today",
  },
};
const SearchFilterComponent = ({
  handleSearch,
  hasSearch = true,
  hasFilter,
  trigger,
  position,
  filters,
  applyFilter,
  initialState,
  savedFilters,
  filtersPresent,
  setFiltersPresent,
  localStorageKey,
  enableDatesAfterToday,
}) => {
  const classes = searchFilterStyles();
  const [search, setSearch] = useState("");
  const [isOpen, closeModal, openModal] = useModal("filters");

  const [allSource, setAllSource] = useState([]);

  const [allFilters, setAllFilters] = useState(savedFilters || initialState);
  const [state, setState] = useState(initialStateDates);

  useEffect(() => {
    getAllSource();
  }, []);

  const getAllSource = async () => {
    const { data: user } = JSON.parse(localStorage.getItem("user"));
    try {
      let { data } = await callsService.getSource(user);
      setAllSource(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleTypeSearch = (e) => {
    setSearch(e.target.value);
  };
  useDebounce(() => handleSearch(search), 1000, [search]);

  const handleApplyFilter = () => {
    closeModal();
    if (JSON.stringify(savedFilters) !== JSON.stringify(allFilters)) {
      applyFilter(allFilters);
    }
  };
  const handleResetFilter = () => {
    closeModal(false);
    applyFilter(initialState);
    setAllFilters(initialState);
  };

  useEffect(() => {
    const temp = { ...allFilters, dateRange: [{ ...state.dateRange }] };
    setAllFilters(temp);
  }, [state]);
  // const newDateRange = [
  //   {
  //     endDate: allFilters.dateRange[0].endDate
  //       ? moment(allFilters.dateRange[0].endDate)._d
  //       : null,
  //     startDate: allFilters.dateRange[0].startDate
  //       ? moment(allFilters.dateRange[0].startDate)._d
  //       : null,
  //     key: "selection",
  //   },
  // ];
  const newDateRange = [
    {
      endDate: state.dateRange.endDate
        ? moment(state.dateRange.endDate)._d
        : null,
      startDate: state.dateRange.startDate
        ? moment(state.dateRange.startDate)._d
        : null,
      key: "selection",
    },
  ];

  const sourceName = allSource.find((e) => e.id === allFilters.sourceFilter);

  const patientType = PATIENT_STATUS.find(
    (e) => e.value === allFilters.patientTypeFilter
  );

  const sortPatient = SORT_PATIENT.find((e) => e.id === allFilters.sortFilter);
  const sortConvo = SORT_CONVERSATION.find(
    (e) => e.id === allFilters.sortFilter
  );

  let { startDate, endDate } = allFilters.dateRange[0];

  startDate = startDate ? moment(startDate).format("MMM DD, YYYY") : "";
  endDate = endDate ? moment(endDate).format("MMM DD, YYYY") : "";

  const combinedDates = startDate ? startDate + " - " + endDate : endDate;
  const dateRangeFilter = startDate === endDate ? startDate : combinedDates;
  const handeChangeDateRangeComp = (e) => {
    setState({
      ...state,
      dateRange: e[0],
    });
  };
  useEffect(() => {
    let filtersSelected = false;
    filters?.map((filter) => {
      if (allFilters[convertToKey(filter)]) {
        filtersSelected = true;
      }
    });
    setFiltersPresent(filtersSelected);
  }, [allFilters]);
  return (
    <>
      <AppBar
        elevation={0}
        color={
          hasSearch === undefined || hasSearch === true
            ? "inherit"
            : "transparent"
        }
        position={position || "fixed"}
        className={classes.appbarRoot}
        style={{
          borderBottom:
            hasSearch === undefined || hasSearch === true
              ? "1px solid #E0E0E0"
              : "",
        }}
        // style={trigger ? { top: -26 } : { top: 56 }}
      >
        <Toolbar>
          <div style={{ flexGrow: 1 }}>
            {hasSearch === true && (
              <div
                className={classes.search}
                style={
                  hasFilter === undefined || hasFilter === true
                    ? { marginRight: 8 }
                    : { marginRight: 0 }
                }
              >
                <div className={classes.searchIcon}>
                  <DFSearchIcon />
                </div>
                <InputBase
                  placeholder={`Search`}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  fullWidth
                  inputProps={{ "aria-label": "search" }}
                  onChange={handleTypeSearch}
                />
              </div>
            )}
          </div>
          {hasFilter === undefined || hasFilter === true ? (
            <>
              <DFIconButton
                edge="end"
                color="inherit"
                onClick={() => openModal()}
                icon={<DFFilterIcon active={false} />}
              />
              {filters?.includes("Sort Patient") && (
                <DFIconButton
                  edge="end"
                  color="inherit"
                  onClick={() => {
                    const newFilters = {
                      ...allFilters,
                      sortFilter: SORT_PATIENT_V2[allFilters.sortFilter],
                    };
                    setAllFilters(newFilters);

                    applyFilter(newFilters);
                  }}
                  icon={<DFSortNameIcon currentSort={allFilters.sortFilter} />}
                />
              )}
            </>
          ) : null}
        </Toolbar>
        <div
          style={{
            padding: "0 1px",
            width: "100%",
          }}
        >
          {hasSearch === true && filtersPresent && (
            <div
              className={classes.selectedFilters}
              style={{
                fontSize: "12px",
                fontStyle: "italic",
              }}
            >
              <span style={{ fontWeight: "420" }}>Filters: </span>
              {filters?.map(
                (filter) =>
                  allFilters[convertToKey(filter)] && (
                    <span
                      key={convertToKey(filter) + "key"}
                      style={{
                        padding: "2px 2px 2px 1px",
                        color: "gray",
                      }}
                    >
                      {allFilters[convertToKey(filter)]}
                    </span>
                  )
              )}
              {allFilters["dateRange"][0]?.startDate &&
                allFilters["dateRange"][0]?.endDate &&
                localStorageKey !== "contacts" && (
                  <span
                    style={{
                      padding: "2px 2px 2px 1px",
                      color: "gray",
                    }}
                  >
                    ,{" "}
                    {new Date(
                      allFilters["dateRange"][0].startDate
                    ).toLocaleDateString("en-US")}
                    {allFilters["dateRange"][0].endDate &&
                      allFilters["dateRange"][0].startDate !==
                        allFilters["dateRange"][0].endDate &&
                      " - " +
                        new Date(
                          allFilters["dateRange"][0].endDate
                        ).toLocaleDateString("en-US")}
                  </span>
                )}
            </div>
          )}
        </div>
      </AppBar>
      <Drawer
        variant="temporary"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="bottom"
        open={isOpen}
        // onClose={() => setDrawer(false)}
        onClose={handleApplyFilter}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "16px 16px 5px 16px",
          }}
        >
          <Grid container>
            <Grid item xs={8}>
              <Typography
                variant="h6"
                style={{ flexGrow: 1, transform: "translateY(-4px)" }}
              >
                Filters
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Button
                size="small"
                style={{ textTransform: "none" }}
                onClick={handleResetFilter}
              >
                Reset
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                size="small"
                color="primary"
                style={{ textTransform: "none" }}
                onClick={handleApplyFilter}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </div>
        <DFDivider />
        {filters?.includes("Log Type") && (
          <Accordion square>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1d-content"
              id="panel1d-header"
              style={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <Typography>Log Type</Typography>
              {allFilters.logTypeFilter && (
                <Chip
                  size="small"
                  label={allFilters.logTypeFilter}
                  onDelete={(item) =>
                    setAllFilters({
                      ...allFilters,
                      logTypeFilter: initialState.logTypeFilter,
                      logTypeName: initialState.logTypeFilter,
                    })
                  }
                  color="default"
                />
              )}
            </AccordionSummary>
            <AccordionDetails>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: 10,
                  width: "100%",
                }}
              >
                {PATIENT_LOG_TYPES.map((val, idx) => (
                  <Button
                    key={idx}
                    onClick={() =>
                      setAllFilters({
                        ...allFilters,
                        logTypeFilter:
                          allFilters.logTypeFilter === val ? "" : val,
                        logTypeName: val,
                      })
                    }
                    variant="outlined"
                    size="small"
                    color={
                      allFilters.logTypeFilter === val ? "primary" : "default"
                    }
                    style={{ textTransform: "capitalize" }}
                  >
                    {val}
                  </Button>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        )}
        {filters?.includes("Patient Type") && (
          <Accordion square elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1d-content"
              id="panel1d-header"
              style={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <Typography>Patient Type</Typography>
              {patientType?.name && (
                <Chip
                  size="small"
                  label={patientType?.name}
                  onDelete={(item) =>
                    setAllFilters({
                      ...allFilters,
                      patientTypeFilter: initialState.patientTypeFilter,
                      patientTypeName: "",
                    })
                  }
                  color="default"
                />
              )}
            </AccordionSummary>
            <AccordionDetails>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: 10,
                  width: "100%",
                }}
              >
                {PATIENT_STATUS.map((val, idx) => (
                  <Button
                    key={idx}
                    onClick={() =>
                      setAllFilters({
                        ...allFilters,
                        patientTypeFilter:
                          allFilters.patientTypeFilter === val.value
                            ? ""
                            : val.value,
                        patientTypeName: val.name,
                      })
                    }
                    variant="outlined"
                    size="small"
                    color={
                      allFilters.patientTypeFilter === val.value
                        ? "primary"
                        : "default"
                    }
                    style={{ textTransform: "capitalize" }}
                  >
                    {val.name}
                  </Button>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        )}
        {/* {filters?.includes("Sort Patient") && (
          <Accordion square>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1d-content"
              id="panel1d-header"
              style={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <Typography>Sort Patient</Typography>
              {sortPatient?.name && (
                <Chip
                  size="small"
                  label={sortPatient?.name}
                  onDelete={(item) =>
                    setAllFilters({
                      ...allFilters,
                      sortFilter: "",
                      sortPatientName: "",
                    })
                  }
                  color="default"
                />
              )}
            </AccordionSummary>
            <AccordionDetails>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: 10,
                  width: "100%",
                }}
              >
                {SORT_PATIENT.map((val, idx) => (
                  <Button
                    key={idx}
                    onClick={() =>
                      setAllFilters({
                        ...allFilters,
                        sortFilter:
                          allFilters.sortFilter === val.id ? "" : val.id,
                        sortPatientName: val.name,
                      })
                    }
                    variant="outlined"
                    size="small"
                    color={
                      allFilters.sortFilter === val.id ? "primary" : "default"
                    }
                    style={{ textTransform: "capitalize" }}
                  >
                    {val.name}
                  </Button>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        )} */}
        {filters?.includes("Sort Conversation") && (
          <Accordion square>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1d-content"
              id="panel1d-header"
              style={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <Typography>Sort Conversation</Typography>
              {sortConvo?.name ? (
                <Chip
                  size="small"
                  label={sortConvo?.name}
                  onDelete={(item) =>
                    setAllFilters({
                      ...allFilters,
                      sortFilter: "",
                      sortConversationName: "",
                    })
                  }
                  color="default"
                />
              ) : null}
            </AccordionSummary>
            <AccordionDetails>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: 10,
                  width: "100%",
                }}
              >
                {SORT_CONVERSATION.map((val, idx) => (
                  <Button
                    key={idx}
                    onClick={() =>
                      setAllFilters({
                        ...allFilters,
                        sortFilter:
                          allFilters.sortFilter === val.id ? "" : val.id,
                        sortConversationName: val.name,
                      })
                    }
                    variant="outlined"
                    size="small"
                    color={
                      allFilters.sortFilter === val.id ? "primary" : "default"
                    }
                    style={{ textTransform: "capitalize" }}
                  >
                    {val.name}
                  </Button>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        )}
        {filters?.includes("Call Status") && (
          <Accordion square>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Typography>Status</Typography>
              {allFilters.callStatusFilter && (
                <Chip
                  size="small"
                  label={allFilters.callStatusFilter}
                  onDelete={(item) =>
                    setAllFilters({
                      ...allFilters,
                      callStatusFilter: initialState.callStatusFilter,
                      callStatusName: initialState.callStatusFilter,
                    })
                  }
                  color="default"
                />
              )}
            </AccordionSummary>
            <AccordionDetails>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: 10,
                  width: "100%",
                }}
              >
                {CALL_STATUS.map((val, idx) => (
                  <Button
                    key={idx}
                    onClick={() =>
                      setAllFilters({
                        ...allFilters,
                        callStatusFilter:
                          allFilters.callStatusFilter === val ? "" : val,
                        callStatusName: val,
                      })
                    }
                    variant="outlined"
                    size="small"
                    color={
                      allFilters.callStatusFilter === val
                        ? "primary"
                        : "default"
                    }
                    style={{ textTransform: "capitalize" }}
                  >
                    {val}
                  </Button>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        )}
        {filters?.includes("SMS Status") && (
          <Accordion square>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Typography>Status</Typography>
              {allFilters.smsStatusFilter ? (
                <Chip
                  size="small"
                  label={allFilters.smsStatusFilter}
                  onDelete={(item) =>
                    setAllFilters({
                      ...allFilters,
                      smsStatusFilter: initialState.smsStatusFilter,
                      sMSStatusName: initialState.smsStatusFilter,
                    })
                  }
                  color="default"
                />
              ) : null}
            </AccordionSummary>
            <AccordionDetails>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: 10,
                  width: "100%",
                }}
              >
                {SMS_STATUS.map((val, idx) => (
                  <Button
                    key={idx}
                    onClick={() =>
                      setAllFilters({
                        ...allFilters,
                        smsStatusFilter:
                          allFilters.smsStatusFilter === val ? "" : val,
                        sMSStatusName: val,
                      })
                    }
                    variant="outlined"
                    size="small"
                    color={
                      allFilters.smsStatusFilter === val ? "primary" : "default"
                    }
                    style={{ textTransform: "capitalize" }}
                  >
                    {val}
                  </Button>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        )}
        {filters?.includes("Source") && (
          <Accordion square>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography>Source</Typography>
              {sourceName?.name && (
                <Chip
                  size="small"
                  label={sourceName?.name}
                  onDelete={(item) =>
                    setAllFilters({
                      ...allFilters,
                      sourceFilter: initialState.sourceFilter,
                      sourceName: "",
                    })
                  }
                  color="default"
                />
              )}
            </AccordionSummary>
            <AccordionDetails>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: 10,
                  width: "100%",
                }}
              >
                {allSource.map((val, idx) => (
                  <Button
                    key={idx}
                    onClick={() =>
                      setAllFilters({
                        ...allFilters,
                        sourceFilter:
                          allFilters.sourceFilter === val.id ? "" : val.id,
                        sourceName: val.name,
                      })
                    }
                    variant="outlined"
                    size="small"
                    color={
                      allFilters.sourceFilter === val.id ? "primary" : "default"
                    }
                    style={{ textTransform: "capitalize" }}
                  >
                    {val.name}
                  </Button>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        )}
        {filters?.includes("Tagging") && (
          <Accordion square>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography>Tagging Status</Typography>
              {allFilters.taggingFilter && (
                <Chip
                  size="small"
                  label={allFilters.taggingFilter}
                  onDelete={(item) =>
                    setAllFilters({
                      ...allFilters,
                      taggingFilter: initialState.taggingFilter,
                      taggingName: initialState.taggingFilter,
                    })
                  }
                  color="default"
                />
              )}
            </AccordionSummary>
            <AccordionDetails>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: 10,
                  width: "100%",
                }}
              >
                {["Tagged Calls", "Untagged Calls"].map((val, idx) => (
                  <Button
                    key={idx}
                    onClick={() =>
                      setAllFilters({
                        ...allFilters,
                        taggingFilter:
                          allFilters.taggingFilter === val ? "" : val,
                        taggingName: val,
                      })
                    }
                    variant="outlined"
                    size="small"
                    color={
                      allFilters.taggingFilter === val ? "primary" : "default"
                    }
                    style={{ textTransform: "capitalize" }}
                  >
                    {val}
                  </Button>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        )}
        {filters?.includes("Date Range") && (
          <Accordion square>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography>Date Range</Typography>
              {dateRangeFilter ? (
                <Chip
                  size="small"
                  label={dateRangeFilter}
                  onDelete={(item) =>
                    setAllFilters({
                      ...allFilters,
                      dateRange: initialState.dateRange,
                    })
                  }
                  color="default"
                />
              ) : null}
            </AccordionSummary>
            <AccordionDetails style={{ width: "100%" }}>
              <div
                style={{
                  display: "grid",
                  width: "100%",
                }}
              >
                <DashboardNav
                  setState={setState}
                  state={state}
                  hideCustomOption
                />
                <DateRange
                  editableDateInputs={true}
                  onChange={(item) =>
                    handeChangeDateRangeComp([item.selection])
                  }
                  moveRangeOnFirstSelection={false}
                  ranges={newDateRange}
                  maxDate={
                    enableDatesAfterToday
                      ? new Date(
                          new Date().setFullYear(new Date().getFullYear() + 10)
                        )
                      : new Date()
                  }
                />
              </div>
            </AccordionDetails>
          </Accordion>
        )}
        {/* {filters?.includes("Date Range") && (
          <Accordion square>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography>Date Range</Typography>
              {dateRangeFilter ? (
                <Chip
                  size="small"
                  label={dateRangeFilter}
                  onDelete={(item) =>
                    setAllFilters({
                      ...allFilters,
                      dateRange: initialState.dateRange,
                    })
                  }
                  color="default"
                />
              ) : null}
            </AccordionSummary>
            <AccordionDetails>
              <div
                style={{
                  display: "grid",
                  width: "100%",
                }}
              >
                <DateRange
                  editableDateInputs={true}
                  onChange={(item) =>
                    setAllFilters({
                      ...allFilters,
                      dateRange: [item.selection],
                    })
                  }
                  moveRangeOnFirstSelection={false}
                  ranges={newDateRange}
                />
              </div>
            </AccordionDetails>
          </Accordion>
        )} */}
        {/* <div style={{ padding: "40px 10px" }}>
          <Button
            variant="contained"
            disableElevation={true}
            color="primary"
            fullWidth
            onClick={handleApplyFilter}
            size="large"
            style={{ marginBottom: 10 }}
          >
            Apply
          </Button>

          <Button
            variant="outlined"
            disableElevation={true}
            fullWidth
            onClick={handleResetFilter}
            size="large"
          >
            Reset
          </Button>
        </div> */}
      </Drawer>
    </>
  );
};

const SearchFilter = React.memo(SearchFilterComponent);
export default SearchFilter;
