import React from "react";

import { Checkbox, FormControlLabel } from "@material-ui/core";

export default function DFCheckbox({
  formik,
  name,
  gutterBottom,
  label,
  required,
  ...props
}) {
  return (
    <div style={gutterBottom ? { marginBottom: 10 } : {}}>
      <FormControlLabel
        style={{ marginLeft: 0 }}
        control={
          <Checkbox
            checked={formik.values[name]}
            onChange={formik.handleChange}
            name={name}
            {...props}
          />
        }
        label={label}
        labelPlacement="start"
      />
      {/* <TextField
        id={name}
        name={name}
        variant="outlined"
        {...props}
        value={formik.values[name]}
        onChange={formik.handleChange}
        error={formik.touched[name] && Boolean(formik.errors[name])}
        helperText={formik.touched[name] && formik.errors[name]}
      /> */}
    </div>
  );
}
