import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import * as React from "react";

export default function DFDialog({
  open,
  onClose,
  actions,
  title,
  content,
  text,
  children,
  style,
  fullWidth,
  maxWidth,
}: any) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <DialogTitle id="alert-dialog-title">
          {title}
          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            sds
            {title}
            <IconButton
              aria-label="close"
              style={{
                position: "absolute",
              }}
              onClick={onClose}
            >
              x
            </IconButton>
          </div> */}
        </DialogTitle>
        <DialogContent>
          {text && (
            <DialogContentText id="alert-dialog-description">
              {text}
            </DialogContentText>
          )}
          {content ? content : children}
        </DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    </div>
  );
}
