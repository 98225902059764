import React from "react";

import { useTheme } from "@material-ui/core/styles";

export default function DFDownArrowButton(active) {
  const theme = useTheme();
  return (
    // <svg
    //   width="21"
    //   height="20"
    //   viewBox="0 0 21 20"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M12.5357 11.8572H3.71428C2.59057 11.8572 1 12.2982 1 14.5715C1 16.8447 2.59057 17.2857 3.71428 17.2857H12.5357"
    //     fill="transparent"
    //   />
    //   <path
    //     d="M12.5357 11.8572H3.71428C2.59057 11.8572 1 12.2982 1 14.5715C1 16.8447 2.59057 17.2857 3.71428 17.2857H12.5357"
    //     stroke={
    //       active === true
    //         ? `${theme.palette.primary.main}`
    //         : `${theme.palette.text.primary}`
    //     }
    //     strokeWidth="1.75"
    //     strokeLinecap="round"
    //     strokeLinejoin="round"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M8.46429 2.35718H17.2857C18.4094 2.35718 20 2.79825 20 5.07146C20 7.34468 18.4094 7.78575 17.2857 7.78575H8.46429V2.35718ZM15.9286 18.6429C14.8488 18.6429 13.8132 18.2139 13.0496 17.4504C12.2861 16.6869 11.8571 15.6513 11.8571 14.5715C11.8571 13.4916 12.2861 12.4561 13.0496 11.6925C13.8132 10.929 14.8488 10.5 15.9286 10.5C17.0084 10.5 18.044 10.929 18.8075 11.6925C19.571 12.4561 20 13.4916 20 14.5715C20 15.6513 19.571 16.6869 18.8075 17.4504C18.044 18.2139 17.0084 18.6429 15.9286 18.6429Z"
    //     fill="transparent"
    //     stroke={
    //       active === true
    //         ? `${theme.palette.primary.main}`
    //         : `${theme.palette.text.primary}`
    //     }
    //     strokeWidth="1.75"
    //     strokeLinecap="round"
    //     strokeLinejoin="round"
    //   />
    //   <path
    //     d="M5.07143 9.14285C7.32001 9.14285 9.14285 7.32001 9.14285 5.07143C9.14285 2.82284 7.32001 1 5.07143 1C2.82284 1 1 2.82284 1 5.07143C1 7.32001 2.82284 9.14285 5.07143 9.14285Z"
    //     fill="transparent"
    //     stroke={
    //       active === true
    //         ? `${theme.palette.primary.main}`
    //         : `${theme.palette.text.primary}`
    //     }
    //     strokeWidth="1.75"
    //     strokeLinecap="round"
    //     strokeLinejoin="round"
    //   />
    // </svg>
    <svg
      width="13"
      height="8"
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.21429 0.5L6.5 4.875L10.7857 0.5L12.5 1.375L6.5 7.5L0.5 1.375L2.21429 0.5Z"
        fill="#0EA782"
      />
    </svg>
  );
}
