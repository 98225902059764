import axios from "axios";

const defaultConfig = {
  headers: {
    Accept: "application/json",
  },
};
export const GET = (url, config = {}) =>
  axios.get(url, {
    ...config,
    headers: { ...defaultConfig.headers, ...config.headers },
  });

export const POST = (url, data, config = {}) =>
  axios.post(url, data, {
    ...config,
    headers: { ...defaultConfig.headers, ...config.headers },
  });

export const DELETE = (url, config = {}) =>
  axios.delete(url, {
    ...config,
    headers: { ...defaultConfig.headers, ...config.headers },
  });

export const PATCH = (url, data, config = {}) =>
  axios.patch(url, data, {
    ...config,
    headers: { ...defaultConfig.headers, ...config.headers },
  });

export const PUT = (url, data, config = {}) =>
  axios.put(url, data, {
    ...config,
    headers: { ...defaultConfig.headers, ...config.headers },
  });
