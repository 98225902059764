import {
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import React from "react";
import { PATIENT_LOG_TYPES } from "utils/constants";

function LogTypeRadioGroup({ value, onChange, isLoading }) {
  return (
    <>
      <Divider style={{ marginBottom: 20 }} />
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Log Type:
      </Typography>
      <RadioGroup
        style={{ marginBottom: 10 }}
        name="booking"
        value={value}
        onChange={onChange}
      >
        {PATIENT_LOG_TYPES.map((option, index) => (
          <FormControlLabel
            disabled={isLoading}
            key={index}
            value={option}
            control={<Radio color="primary" />}
            label={option}
          />
        ))}
      </RadioGroup>
    </>
  );
}

export default LogTypeRadioGroup;
