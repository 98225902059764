import React, { useEffect, useState } from "react";

import { AsYouType } from "libphonenumber-js";
import Select from "@material-ui/core/Select";
import FlagIcon from "@material-ui/icons/Flag";
import MenuItem from "@material-ui/core/MenuItem";
import { OutlinedInput } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import countries from "utils/country_list";
import "react-phone-number-input/style.css";

import {
  countryToFlag,
  formatDisplayedNumber,
  removeAllNonNumbers,
} from "utils/ArrayUtils";

export default function DFPhoneNumberField({
  formik,
  name,
  gutterBottom,
  label,
  countryName,
  required,
  countryCode,
  setCountryCode,
  ...props
}) {
  const handleChange = (event) => {
    setCountryCode(event.target.value);
  };
  return (
    <div style={gutterBottom ? { marginBottom: 10 } : {}}>
      <Typography variant="caption" color="textSecondary">
        {label}
        {required ? "*" : ""}
      </Typography>
      <div style={{ display: "flex" }}>
        <Select
          displayEmpty
          value={countryCode}
          style={{ marginRight: 5 }}
          variant="outlined"
          input={<OutlinedInput margin="dense" />}
          onChange={handleChange}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <FlagIcon fontSize="inherit" />;
            }
            const newSelected = countries.find(
              (item) => item.phone === selected
            );
            return `${countryToFlag(newSelected.code)} +${selected}`;
          }}
        >
          {countries.map((val, idx) => (
            <MenuItem key={idx} value={val.phone}>
              {countryToFlag(val.code) + " " + val.name + " +" + val.phone}
            </MenuItem>
          ))}
        </Select>
        <TextField
          formik={formik}
          id={name}
          name={name}
          disabled={formik.values[name] === "" && true}
          variant="outlined"
          {...props}
          onFocus={() => {
            formik.setFieldValue(
              "phone1",
              removeAllNonNumbers(formik.values[name])
            );
          }}
          onBlur={() => {
            formik.setFieldValue(
              "phone1",
              formatDisplayedNumber(formik.values[name])
            );
          }}
          value={formik.values[name]}
          onChange={(e) => {
            if (e.target.value === "") setCountryCode("1");
            formik.setFieldValue("phone1", removeAllNonNumbers(e.target.value));
          }}
          error={formik.touched[name] && Boolean(formik.errors[name])}
          helperText={formik.touched[name] && formik.errors[name]}
          inputProps={{ maxLength: 15 }}
        />
      </div>
    </div>
  );
}
