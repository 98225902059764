import React from "react";

import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

export default function DFTextField({
  formik,
  name,
  gutterBottom,
  label,
  required,
  ...props
}) {
  return (
    <div style={gutterBottom ? { marginBottom: 10 } : {}}>
      <Typography variant="caption" color="textSecondary">
        {label}
        {required ? "*" : ""}
      </Typography>

      <TextField
        id={name}
        name={name}
        variant="outlined"
        {...props}
        value={formik.values[name]}
        onChange={formik.handleChange}
        error={formik.touched[name] && Boolean(formik.errors[name])}
        helperText={formik.touched[name] && formik.errors[name]}
      />
    </div>
  );
}
