import {
  Divider,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import DashboardChart from "./DashboardChart";
import DashboardNav from "./DashboardNav";
import DashboardGrid from "./DashboardGrid";
import dashboardService from "services/dashboardService";
import Communications from "pages/communications";
import { usePubNub } from "pubnub-react";
import { pubnubCleanUp } from "utils/commonUtils";
import { createContext } from "react";
export const DashboardContext = createContext();

const useStyles = makeStyles((theme) => ({
  patientBoxes: {
    height: "100%",
    padding: "15px 5px",
    display: "grid",
    cursor: "pointer",
    transition: "all ease-in-out .2s",
    "&:hover": {
      boxShadow: "rgba(68, 68, 68, 0.5) 0px 2px 20px",
      transform: "scale(1.02)",
    },
  },
  logsDisplayDateRange: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  rootLogList: {
    minHeight: "80vh",
    position: "relative",
  },
  emptyPlaceholder: {
    display: "grid",
    placeItems: "center",
    padding: "15px 0px",
    position: "absolute",
    top: 0,
    bottom: 0,
    zIndex: 2,
    background: "#fff",
    width: "100%",
  },
}));

let initialPieData = [
  {
    name: "Potential Patients",
    count: 0,
    key: "Potential Patient",
  },
  {
    name: "Existing Patients",
    count: 0,
    key: "Existing Patient",
  },
  {
    name: "Non Patient Related",
    count: 0,
    key: "Non Patient Related",
  },
  {
    name: "Untagged",
    count: 0,
    key: "Untagged",
  },
  {
    name: "Unknown",
    count: 0,
    key: "Unknown",
  },
];

let initialState = {
  selectedLogType: "all_logs_status_breakdown",
  dateRange: {
    startDate: moment()._d,
    endDate: moment()._d,
    key: "today",
  },
};

const dateFormatter = (e, format) => {
  return moment(e).format(format);
};

export default function Dashboard(props) {
  const classes = useStyles();
  const pubnub = usePubNub();
  const [channels] = useState([props.user?.id]);
  const [state, setState] = useState(initialState);
  // const [state, setState] = useState(
  //   JSON.parse(localStorage.getItem("dashboard")) || initialState
  // );
  const { startDate, endDate } = state.dateRange;

  // useEffect(() => {
  //   localStorage.setItem("dashboard", JSON.stringify(state));
  // }, [state]);

  const { data, refetch } = useQuery(
    // const { data: chartData = [], refetch } = useQuery(
    ["piechart", startDate, endDate, state.selectedLogType],
    async ({ queryKey }) => {
      const { data } = await dashboardService.getPieData(
        props?.user,
        queryKey[1],
        queryKey[2],
        queryKey[3],

        {
          headers: {
            "api-token": props?.user.token,
            current_office: props?.user.current_office?.id,
          },
        }
      );
      // let tempData = [...data.data];
      // console.log(data.data);
      // tempData[3].name = "Others";
      // tempData[3].count = tempData[3].count + tempData[4].count;
      // tempData.splice(4, 1);

      // tempData?.map((val) => {
      //   if (val.name == "Potential Patients") {
      //     val.key = "Potential Patient";
      //   } else if (val.name == "Existing Patients") {
      //     val.key = "Existing Patient";
      //   } else if (val.name == "Others") {
      //     val.key = "By Office";
      //   } else {
      //     val.key = val.name;
      //   }
      // });
      // console.log(tempData);

      // return tempData;

      return data;
    }
  );

  data?.data?.map((val) => {
    if (val.name == "Potential Patients") {
      val.key = "Potential Patient";
    } else if (val.name == "Existing Patients") {
      val.key = "Existing Patient";
    } else {
      val.key = val.name;
    }
  });

  const fresh = data?.data || [];
  const untagged = fresh.filter((e) => e.name === "Untagged");
  if (untagged.length === 0 && fresh.length > 0) {
    fresh.splice(3, 0, { name: "Untagged", count: 0, key: "Untagged" });
  }
  const chartData = fresh.length > 0 ? fresh : initialPieData;
  const sumCount = chartData.reduce((prev, current) => {
    return Number(prev) + Number(current.count);
  }, 0);

  const fetchData = () => {
    refetch();
  };

  useEffect(() => {
    const listeners = {
      message: fetchData,
    };
    pubnub.addListener(listeners);
    pubnub.subscribe({ channels });
    return () => {
      pubnubCleanUp(pubnub, listeners);
    };
  }, []);

  return (
    <>
      <div style={{ padding: "20px 16px 0px" }}>
        <FormControl
          style={{ marginBottom: 15 }}
          size="small"
          variant="outlined"
          fullWidth
        >
          <Select
            value={state.selectedLogType}
            onChange={(e) =>
              setState({ ...state, selectedLogType: e.target.value })
            }
          >
            <MenuItem value="all_logs_status_breakdown">All Logs</MenuItem>
            <MenuItem value="call_logs_status_breakdown">Call Logs</MenuItem>
            <MenuItem value="sms_logs_status_breakdown">SMS Logs</MenuItem>
            <MenuItem value="web_logs_status_breakdown">Web Form Logs</MenuItem>
          </Select>
        </FormControl>
        <DashboardNav setState={setState} state={state} />
        <Typography align="center" variant="h5">
          Practice Analytics
        </Typography>
        <div className={classes.logsDisplayDateRange}>
          <Typography variant="body2" paragraph>
            {dateFormatter(startDate, "MMM DD, YYYY") ===
            dateFormatter(endDate, "MMM DD, YYYY")
              ? dateFormatter(startDate, "MMM DD, YYYY")
              : dateFormatter(startDate, "MMM DD, YYYY") +
                " - " +
                dateFormatter(endDate, "MMM DD, YYYY")}
          </Typography>
        </div>

        <DashboardChart pieData={chartData} totalCount={sumCount} />

        <DashboardGrid gridData={chartData} />
        <Divider />
      </div>
      <DashboardContext.Provider value={{ fetchDashboardData: fetchData }}>
        <Communications filterPosition="sticky" {...props} />
      </DashboardContext.Provider>
    </>
  );
}
