import React, { useEffect, useState, useContext } from "react";

import {
  Paper,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
} from "@material-ui/core";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import userService from "services/userService";
import { SnackbarContext } from "App";
import { DFLogoFull } from "components/DFIcons";
import { DFInput, DFInputPassword } from "components/DFCustomFormFields";
import useUserContext from "hooks/context-hooks/useUserContext";
import { PushNotifications } from "@capacitor/push-notifications";
import { POST } from "utils/restUtils";
import { delay } from "utils/commonUtils";
import { appMode, modesMap } from "config/config";

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
const applicationServerPublicKey =
  "BCDLsU82zH5iJxzcDB-pcddvrBytQzwRbWLYLYi1dgxJDjhMWGvbJx-DoVUURDmDdbfl8BRAF3BNpAqbDWowXlg=";

const schema = Yup.object({
  username: Yup.string()
    .email("Email is invalid.")
    .required("This field is required."),
  password: Yup.string()
    .min(8, "Please enter at least 8 characters.")
    .required("This field is required."),
}).required();

const useStyles = makeStyles((theme) => ({
  root: {
    inset: 0,
    height: "100vh",
    backgroundColor: theme.palette.primary.main,
    display: "grid",
    placeContent: "center",
    padding: "10vh 8vw",
  },
  rootPaper: {
    padding: "60px 20px 20px",
    maxWidth: "300px",
    display: "grid",
    gap: 20,
    gridTemplateRows: "auto 2fr 40px",
  },
  loginActions: {
    display: "flex",
    alignItems: "center",
    marginTop: -10,
    marginBottom: 15,
  },
  keepMeLogedIn: { marginBottom: 0, marginRight: 0, flexGrow: 1 },
  forgotPassword: {
    opacity: 0.7,
    paddingTop: 4,
    cursor: "pointer",
    transition: "all .3s ease-in-out",
    "&:hover": {
      opacity: 1,
      textDecoration: "underline",
    },
  },
}));
export default function Login(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { setSnack } = useContext(SnackbarContext);
  const [checked, setChecked] = useState(false);
  const { checkRoles } = useUserContext();

  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
    resolver: yupResolver(schema),
  });

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    if (localStorage.getItem("user") === null) return;
    const { data } = JSON.parse(localStorage.getItem("user"));
    //debugger;
    if (data?.id) {
      if (data.checked) {
        props.history.push("/dashboard");
        return;
      }
      setValue("username", data?.email);
    }
  }, []);

  const activatePushNotification = (userId) => {
    const activate = (subscription) => {
      userService
        .activateNotification({ userId, subscription })
        .then((response) => {
          console.log("api response =>", response.body.getReader());
        })
        .catch((err) => {
          console.error("api request failed:", err);
        });
    };

    navigator.serviceWorker.ready.then(async (registration) =>
      registration.pushManager.getSubscription().then((subscription) => {
        if (!subscription) {
          console.log(
            "No push notification subscription found. Subscribing again..."
          );
          registration.pushManager
            .subscribe({
              userVisibleOnly: true,
              applicationServerKey: urlBase64ToUint8Array(
                applicationServerPublicKey
              ),
            })
            .then((newSubscriptiion) => {
              activate(newSubscriptiion);
            })
            .catch((err) => {
              console.error("push subscription failed:", err);
            });
        } else {
          activate(subscription);
        }
      })
    );
  };

  const activateMobileNotifications = async (id) => {
    console.log("Initializing HomePage");

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === "granted") {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener("registration", async (token) => {
      await delay(2000);
      await POST(`https://df-push-server.vercel.app/save_device_token`, {
        user_id: id,
        token: token.value,
      });
    });

    PushNotifications.addListener("registrationError", (error) => {
      // alert("Error on registration: " + JSON.stringify(error));
    });

    // Some issue with our setup and push will not work
  };
  const getUser = async (params) => {
    const res = await checkRoles(params);
    return res;
  };
  const onSubmit = async (data) => {
    //asd
    try {
      let { data: user } = await userService.loginUser(data);

      if (user.success === "true") {
        const newUser = {
          ...user.data,
          checked,
        };
        const storeUser = {
          ...user,
          data: newUser,
        };
        // localStorage.setItem("user", JSON.stringify(storeUser));
        if (await getUser(storeUser.data)) {
          props.history.replace("/dashboard");
        }
        activatePushNotification(storeUser.data.id);
        if (appMode === modesMap.ANDROID)
          await activateMobileNotifications(storeUser.data.id);
      } else {
        setSnack({
          open: true,
          severity: "error",
          message: `${user.message}`,
        });
        return;
      }
    } catch (err) {
      setSnack({
        open: true,
        severity: "error",
        message: "Oops! Something went wrong.",
      });
      console.log(err);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.rootPaper}>
        <DFLogoFull width="fullWidth" color={theme.palette.primary.main} />

        <form onSubmit={handleSubmit(onSubmit)}>
          <DFInput
            name="username"
            label="Username"
            control={control}
            rules={{ required: true }}
          />
          <DFInputPassword
            name="password"
            label="Password"
            control={control}
            rules={{ required: true }}
            gutterBottom
          />

          <div className={classes.loginActions}>
            <FormControlLabel
              className={classes.keepMeLogedIn}
              control={
                <Checkbox
                  size="small"
                  checked={checked}
                  onChange={handleChange}
                  name="checkedA"
                />
              }
              label={
                <Typography variant="caption">Keep me logged in.</Typography>
              }
            />
            <Typography
              align="right"
              variant="caption"
              className={classes.forgotPassword}
              onClick={() => {
                props.history.push(`/forgot_password`);
              }}
            >
              Forgot password?
            </Typography>
          </div>
          <Button
            variant="contained"
            size="large"
            type="submit"
            color="secondary"
            fullWidth
          >
            Login
          </Button>
        </form>
        <Typography
          align="center"
          color="textSecondary"
          variant="caption"
          style={{ alignSelf: "end" }}
        >
          © DentistFind. inc.
        </Typography>
      </Paper>
    </div>
  );
}
