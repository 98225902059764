import React, { useState, useRef, useEffect } from "react";

import { Toolbar, Typography } from "@material-ui/core";

import Button from "./Button";
import AudioSlider from "./AudioSlider";

function AudioPlayer({ playingAudioRef, src }) {
  const [percentage, setPercentage] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [invalidURL, setInvalidURL] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const audioRef = useRef();

  const handleError = () => {
    setPercentage(0);
    setIsPlaying(false);
    setDuration(0);
    setCurrentTime(0);
    setInvalidURL(true);
    setIsLoading(false);
  };
  const handleLoadStart = () => {
    if (isIOS) return;
    // setPercentage(0);
    // setIsPlaying(false);
    // setDuration(0);
    // setCurrentTime(0);
    setInvalidURL(false);
    setIsLoading(true);
  };
  const onChange = (event, newValue) => {
    const audio = audioRef.current;
    audio.currentTime = (audio.duration / 100) * newValue;
    setPercentage(newValue);
  };

  useEffect(() => {
    setIsPlaying(false);
  }, [audioRef.current?.ended]);

  const play = () => {
    const audio = audioRef.current;

    if (playingAudioRef) {
      if (playingAudioRef?.current) {
        playingAudioRef.current.pause();
      }
      if (audio?.src !== playingAudioRef?.current?.src) {
        audio.play();

        playingAudioRef.current = audioRef.current;
      } else {
        audio.pause();
        playingAudioRef.current = null;
      }
    } else {
      if (!isPlaying) {
        setIsPlaying(true);
        audio.play();
      }

      if (isPlaying) {
        setIsPlaying(false);
        audio.pause();
      }
    }
  };

  const getCurrDuration = (e) => {
    const percent = (
      (e.currentTarget.currentTime / e.currentTarget.duration) *
      100
    ).toFixed(2);
    const time = e.currentTarget.currentTime;

    setPercentage(+percent);
    setCurrentTime(time.toFixed(2));
  };

  function secondsToHms(seconds) {
    if (!seconds) return "00:00";

    let duration = seconds;
    let hours = duration / 3600;
    duration = duration % 3600;

    let min = parseInt(duration / 60);
    duration = duration % 60;

    let sec = parseInt(duration);

    if (sec < 10) {
      sec = `0${sec}`;
    }
    if (min < 10) {
      min = `0${min}`;
    }

    if (parseInt(hours, 10) > 0) {
      return `${parseInt(hours, 10)}:${min}:${sec}`;
    } else if (min === 0) {
      return `00:${sec}`;
    } else {
      return `${min}:${sec}`;
    }
  }
  const appContainer = {
    width: "100%",
    backgroundColor: "#fff",
    padding: "5px 15px 0",
    // border: "1px solid rgba(0,0,0,.12)",
    borderRadius: 10,
  };

  return (
    <Toolbar variant="dense" disableGutters style={{ gap: 20 }}>
      <Button
        isLoading={isLoading}
        onError={invalidURL}
        play={play}
        isPlaying={isPlaying}
      />
      <div style={{ width: "100%" }}>
        <AudioSlider
          isLoading={isLoading}
          onError={invalidURL}
          percentage={percentage}
          onChange={onChange}
        />
        <div style={{ display: "flex" }}>
          <Typography variant="caption">{secondsToHms(currentTime)}</Typography>
          <div style={{ flexGrow: 1 }} />
          <Typography variant="caption">{secondsToHms(duration)}</Typography>
        </div>
      </div>
      {/* <ControlPanel
        isLoading={isLoading}
        onError={invalidURL}
        play={play}
        isPlaying={isPlaying}
        duration={duration}
        currentTime={currentTime}
      /> */}
      <audio
        onEnded={() => setIsPlaying(false)}
        onPause={() => setIsPlaying(false)}
        onPlay={() => setIsPlaying(true)}
        onError={handleError}
        ref={audioRef}
        onLoadStart={handleLoadStart}
        onTimeUpdate={getCurrDuration}
        onLoadedData={(e) => {
          setInvalidURL(false);
          setIsLoading(false);
          setDuration(e.currentTarget.duration.toFixed(2));
        }}
        src={src}
      />
    </Toolbar>
  );
}

export default AudioPlayer;
const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
