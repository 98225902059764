import React from "react";

export default function DFSortNameIcon({ currentSort }) {
  const icon = {
    "%2Bfirst_name": (
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.707 11.707C17.8468 11.5671 17.942 11.389 17.9806 11.195C18.0192 11.0011 17.9993 10.8 17.9237 10.6173C17.848 10.4346 17.7199 10.2785 17.5555 10.1686C17.391 10.0587 17.1978 10 17 10H10V12H14.586L10.293 16.293C10.1532 16.4329 10.058 16.611 10.0194 16.805C9.98085 16.9989 10.0007 17.2 10.0763 17.3827C10.152 17.5654 10.2801 17.7215 10.4445 17.8314C10.609 17.9413 10.8022 18 11 18H18V16H13.414L17.707 11.707ZM4 0.99L0 4.99H3V16.99H5V4.99H8L4 0.99ZM15 0H13C12.583 0 12.21 0.259 12.063 0.649L9.313 7.982H11.45L12.193 6H15.806L16.549 7.981H18.686L15.936 0.648C15.8644 0.457613 15.7364 0.293588 15.5692 0.177808C15.402 0.062028 15.2034 0 15 0ZM12.943 4L13.693 2H14.306L15.056 4H12.943Z"
          fill="#373737"
        />
      </svg>
    ),
    "-first_name": (
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.707 11.707C17.8468 11.5671 17.942 11.389 17.9806 11.195C18.0191 11.0011 17.9993 10.8 17.9237 10.6173C17.848 10.4346 17.7199 10.2785 17.5554 10.1686C17.391 10.0587 17.1977 10 17 10H9.99999V12H14.586L10.293 16.293C10.1532 16.4329 10.058 16.611 10.0194 16.805C9.98084 16.9989 10.0006 17.2 10.0763 17.3827C10.152 17.5654 10.2801 17.7215 10.4445 17.8314C10.6089 17.9413 10.8022 18 11 18H18V16H13.414L17.707 11.707ZM15 0H13C12.583 0 12.21 0.259 12.063 0.649L9.31299 7.982H11.45L12.193 6H15.806L16.549 7.981H18.686L15.936 0.648C15.8644 0.457613 15.7364 0.293588 15.5692 0.177808C15.402 0.062028 15.2034 0 15 0ZM12.943 4L13.693 2H14.306L15.056 4H12.943Z"
          fill="#373737"
        />
        <path
          d="M4 16.9902L8 12.9902L5 12.9902L5 0.990234L3 0.990234L3 12.9902L3.49691e-07 12.9902L4 16.9902Z"
          fill="#373737"
        />
      </svg>
    ),
  };
  return icon[currentSort];
}
