import { CallContext } from "components/CallFeature";
import { useContext } from "react";

function useCallFeature() {
  const {
    patientList,
    contactTypes,
    makeACall,
    callerData,
    openCall,
    showReturnToCallButton,
    openCallPopup,
    timer,
  } = useContext(CallContext);
  return {
    patientList,
    contactTypes,
    makeACall,
    callerData,
    openCall,
    showReturnToCallButton,
    openCallPopup,
    timer,
  };
}

export default useCallFeature;
