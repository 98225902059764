import React from "react";
import { Typography, makeStyles } from "@material-ui/core";

import { LogStatusIcons } from "components/DFIcons";
import { logStatus } from "utils/ArrayUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "20px 0 30px",
    display: "grid",
    gridTemplateColumns: "repeat(6, 1fr)",
    gridTemplateRows: "repeat(2, 1fr)",
    gap: "6px",
  },
  patientBoxes: {
    height: "100%",
    padding: "15px 5px",
    display: "grid",
    cursor: "pointer",
    transition: "all ease-in-out .2s",
    "&:hover": {
      boxShadow: "rgba(68, 68, 68, 0.5) 0px 2px 20px",
      transform: "scale(1.02)",
    },
  },
}));

export default function DashboardGrid({ gridData }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {gridData.map((val, idx) => (
        <div
          key={val.name}
          className={classes.patientBoxes}
          style={{
            gridColumn: `${
              // idx === 0 || idx === 1 ? "auto / span 3" : "auto / span 3"
              idx === 0 || idx === 1 ? "auto / span 3" : "auto / span 2"
            }`,
            backgroundColor: `${logStatus(val.key, "name", "main_color")}`,
            color: val.name === "Untagged" ? "rgba(0,0,0,.87)" : "#fff",
            borderRadius: "10px",
          }}
          // onClick={() => handleClickStatus(val.name)}
        >
          <Typography component="div" align="center" variant="h5">
            <LogStatusIcons status={logStatus(val.key, "name", "name")} />{" "}
            <b>{val.count}</b>
          </Typography>
          <Typography
            align="center"
            style={{ fontSize: ".73rem" }}
            variant="body2"
          >
            {val.name}
          </Typography>
        </div>
      ))}
    </div>
  );
}
