import { SnackbarContext } from "App";
import config, { ENVIRONMENT } from "config/config";
import useUserContext from "hooks/context-hooks/useUserContext";
import { useContext, useState } from "react";
import { PUT } from "utils/restUtils";
interface UpdateOneCallLogPayload {
  log_id: string;
  current_office: string;
  call_type: number;
  is_appointment_booked: boolean;
  booking_change: boolean;
  booking_date?: string;
  treatment_name?: string;
  showed_up: boolean;
  showed_up_reason?: string;
  follow_up_1: boolean;
  follow_up_2: boolean;
  follow_up_3: boolean;
  follow_up_4: boolean;
  note?: string;
}

export const useUpdateOneCallLog = () => {
  const { user } = useUserContext();
  const { setSnack } = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const updateLog = async ({
    log_id,
    call_type,
    is_appointment_booked,
    note,
    booking_change,
    booking_date,
    treatment_name,
    showed_up,
    showed_up_reason,
    follow_up_1,
    follow_up_2,
    follow_up_3,
    follow_up_4,
  }: UpdateOneCallLogPayload) => {
    if (ENVIRONMENT === "STAGING") console.log("updateOneCallLog");

    const baseUrl = `${config.BASE_URL}/api/v1/call_logs/${log_id}`;
    let params: UpdateOneCallLogPayload = {
      api_token: user?.token,
      current_office: user.current_office.id,
      call_type,
      is_appointment_booked,
      booking_change,
      booking_date,
      treatment_name,
      showed_up,
      showed_up_reason,
      follow_up_1,
      follow_up_2,
      follow_up_3,
      follow_up_4,
    } as any;

    if (note !== "none") {
      params.note = note;
    }
    const queryString = new URLSearchParams(params as any).toString();
    return PUT(`${baseUrl}?${queryString}`);
  };

  const handleUpdate = async (
    params: UpdateOneCallLogPayload,
    skipSnack?: boolean
  ) => {
    setLoading(true);

    try {
      await updateLog(params);
      !skipSnack &&
        setSnack({
          open: true,
          severity: "success",
          message: "Log updated successfully!",
        });
    } catch (err) {
      !skipSnack &&
        setSnack({
          open: true,
          severity: "error",
          message: `Error! ${err}`,
        });
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return [handleUpdate, loading];
};
