import React from "react";

import { useTheme } from "@material-ui/core/styles";

export default function LogStatusIcons({ status, color }) {
  const theme = useTheme();
  let icon = "";
  switch (status) {
    case "Untagged":
      icon = (
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.088 4.87649C11.4877 5.27487 12.1379 5.27487 12.5389 4.87649C12.9386 4.47945 12.9386 3.83458 12.5389 3.4362C12.3458 3.24467 12.0848 3.13721 11.8128 3.13721C11.5408 3.13721 11.2798 3.24467 11.0866 3.4362C10.6869 3.83458 10.6869 4.47945 11.0866 4.87649H11.088Z"
            // fill={theme.palette.contrastThreshold(1)}
            fill="black"
          />
          <path
            d="M0.785986 7.10159C0.53695 7.34836 0.339271 7.64201 0.204365 7.96561C0.0694589 8.2892 0 8.63631 0 8.9869C0 9.33748 0.0694589 9.6846 0.204365 10.0082C0.339271 10.3318 0.53695 10.6254 0.785986 10.8722L5.19613 15.2544C6.24337 16.2949 7.94081 16.2949 8.98938 15.2544L15.2382 9.04552C15.7352 8.54988 16.0176 7.8797 16.023 7.17887L16.0549 2.68479C16.0564 2.33116 15.9876 1.98075 15.8527 1.65386C15.7178 1.32697 15.5194 1.03009 15.2689 0.780407C15.0185 0.53072 14.721 0.333186 14.3937 0.199239C14.0665 0.0652932 13.7158 -0.00240446 13.3622 6.52148e-05L9.01337 0.0187183C8.30635 0.0210635 7.62865 0.301525 7.12673 0.799487L0.785986 7.10159ZM1.73463 9.92888C1.61012 9.8055 1.51128 9.65867 1.44382 9.49688C1.37637 9.33508 1.34164 9.16152 1.34164 8.98623C1.34164 8.81094 1.37637 8.63738 1.44382 8.47558C1.51128 8.31379 1.61012 8.16696 1.73463 8.04358L8.07671 1.74147C8.32734 1.49281 8.66565 1.35261 9.0187 1.35109L13.3676 1.33243C14.1137 1.32977 14.7199 1.93333 14.7132 2.67546L14.6813 7.16955C14.6785 7.52012 14.5372 7.85537 14.2882 8.1022L8.04074 14.311C7.51712 14.832 6.6684 14.832 6.14478 14.311L1.73463 9.92755V9.92888Z"
            // fill={theme.palette.contrastThreshold(1)}
            fill="black"
          />
        </svg>
      );
      break;
    case "Potential Patients":
    case "Potential Patient":
      icon = (
        <svg
          width="19"
          height="18"
          viewBox="0 0 19 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.3859 6.33831L12.6901 5.51709L10.1439 0.396152C10.0743 0.255944 9.95993 0.142442 9.8186 0.0734507C9.46415 -0.100141 9.03343 0.0445188 8.85621 0.396152L6.31002 5.51709L0.614191 6.33831C0.457157 6.36057 0.313583 6.43401 0.20366 6.54529C0.0707685 6.68079 -0.00246073 6.86309 6.31408e-05 7.05213C0.00258702 7.24117 0.0806575 7.42149 0.21712 7.55345L4.33813 11.5394L3.36452 17.1677C3.34169 17.2987 3.35629 17.4333 3.40668 17.5564C3.45706 17.6795 3.54121 17.7862 3.64958 17.8643C3.75795 17.9424 3.8862 17.9887 4.0198 17.9982C4.1534 18.0077 4.28699 17.9798 4.40543 17.9177L9.50004 15.2605L14.5947 17.9177C14.7337 17.9912 14.8953 18.0157 15.0501 17.989C15.4404 17.9222 15.7029 17.555 15.6356 17.1677L14.662 11.5394L18.783 7.55345C18.8951 7.4444 18.9692 7.30197 18.9916 7.14618C19.0522 6.75671 18.7785 6.39618 18.3859 6.33831ZM12.9279 10.9785L13.7377 15.6588L9.50004 13.4511L5.26238 15.661L6.07223 10.9808L2.64441 7.66473L7.38234 6.98149L9.50004 2.72405L11.6178 6.98149L16.3557 7.66473L12.9279 10.9785Z"
            fill="currentColor"
          />
        </svg>
      );
      break;
    case "Existing Patient":
    case "Existing Patients":
      icon = (
        <svg
          width="18"
          height="15"
          viewBox="0 0 18 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.28 2.61585H9.2025L6.56325 0.0503048C6.52967 0.0183561 6.4855 0.000400831 6.4395 0H0.72C0.32175 0 0 0.326982 0 0.731707V14.2683C0 14.673 0.32175 15 0.72 15H17.28C17.6783 15 18 14.673 18 14.2683V3.34756C18 2.94284 17.6783 2.61585 17.28 2.61585ZM16.38 13.3537H1.62V1.64634H5.86125L8.55225 4.2622H16.38V13.3537Z"
            fill="currentColor"
          />
        </svg>
      );
      break;
    case "Unknown":
      icon = (
        <svg
          width="18"
          height="14"
          viewBox="0 0 18 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.2 12.25H1.8V3.5H16.2V12.25ZM16.2 1.75H9L7.2 0H1.8C0.81 0 0 0.7875 0 1.75V12.25C0 13.2125 0.81 14 1.8 14H16.2C17.19 14 18 13.2125 18 12.25V3.5C18 2.5375 17.19 1.75 16.2 1.75ZM15.3 6.7375C15.3 8.225 13.5 8.3125 13.5 9.625H12.33C12.33 7.7 14.13 7.875 14.13 6.7375C14.13 6.125 13.59 5.6 12.96 5.6C12.33 5.6 11.79 6.125 11.79 6.7375H10.62C10.62 5.425 11.7 4.375 13.05 4.375C14.4 4.375 15.3 5.425 15.3 6.7375ZM13.5 10.2375V11.375H12.33V10.2375H13.5Z"
            fill="currentColor"
          />
        </svg>
      );
      break;
    case "By Office":
      icon = (
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.83333 16H10.1667M14.3333 16V2.66667C14.3333 2.22464 14.1577 1.80072 13.8452 1.48816C13.5326 1.17559 13.1087 1 12.6667 1H4.33333C3.89131 1 3.46738 1.17559 3.15482 1.48816C2.84226 1.80072 2.66667 2.22464 2.66667 2.66667V16H14.3333ZM14.3333 16H16H14.3333ZM14.3333 16H10.1667H14.3333ZM2.66667 16H1H2.66667ZM2.66667 16H6.83333H2.66667ZM6 4.33333H6.83333H6ZM6 7.66667H6.83333H6ZM10.1667 4.33333H11H10.1667ZM10.1667 7.66667H11H10.1667ZM6.83333 16V11.8333C6.83333 11.6123 6.92113 11.4004 7.07741 11.2441C7.23369 11.0878 7.44565 11 7.66667 11H9.33333C9.55435 11 9.76631 11.0878 9.92259 11.2441C10.0789 11.4004 10.1667 11.6123 10.1667 11.8333V16H6.83333Z"
            stroke="currentColor"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
      break;
    case "Spam":
      icon = (
        <svg
          width="20"
          height="18"
          viewBox="0 0 20 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 0L20 8.63636L15 17.2727H5L0 8.63636L5 0H15ZM13.9518 1.81818H6.04818L2.10091 8.63636L6.04818 15.4545H13.9518L17.8991 8.63636L13.9518 1.81818ZM9.09091 11.3636H10.9091V13.1818H9.09091V11.3636ZM9.09091 4.09091H10.9091V9.54545H9.09091V4.09091Z"
            fill="currentColor"
          />
        </svg>
      );
      break;
    case "Wrong Number":
      icon = (
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.85677 9.05216C2.70849 7.28148 1.99156 5.26614 1.76359 3.16807C1.74655 2.97953 1.76896 2.7895 1.82939 2.61009C1.88981 2.43068 1.98693 2.26581 2.11457 2.12599C2.2422 1.98617 2.39755 1.87446 2.57072 1.79797C2.74389 1.72148 2.9311 1.68189 3.12041 1.68171H5.16586C5.49675 1.67845 5.81754 1.79562 6.06843 2.01139C6.31932 2.22715 6.48319 2.52678 6.5295 2.85443C6.61583 3.50902 6.77594 4.15175 7.00677 4.77034C7.09851 5.01438 7.11836 5.2796 7.06398 5.53458C7.0096 5.78956 6.88327 6.0236 6.69995 6.20898L5.83405 7.07489M7.59995 9.39307C8.2919 10.0856 9.07392 10.6819 9.92495 11.1658L10.7909 10.2999C10.9762 10.1166 11.2103 9.99024 11.4653 9.93586C11.7202 9.88148 11.9855 9.90134 12.2295 9.99307C12.8481 10.2239 13.4908 10.384 14.1454 10.4703C14.4731 10.5167 14.7727 10.6805 14.9885 10.9314C15.2042 11.1823 15.3214 11.5031 15.3181 11.834V13.8794C15.3189 14.0693 15.28 14.2573 15.2039 14.4313C15.1279 14.6052 15.0163 14.7614 14.8764 14.8898C14.7364 15.0182 14.5713 15.1159 14.3914 15.1767C14.2115 15.2376 14.0209 15.2602 13.8318 15.2431C11.7337 15.0151 9.71836 14.2982 7.94768 13.1499C7.12627 12.6283 6.36486 12.0178 5.67723 11.3294L7.59995 9.39307Z"
            stroke="currentColor"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 1L1 16"
            stroke="currentColor"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
      break;
    case "Non Patient Related":
      icon = (
        <svg
          width="19"
          height="17"
          viewBox="0 0 19 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.50006 7.99999C8.19229 7.99999 8.86898 7.79472 9.44455 7.41014C10.0201 7.02555 10.4687 6.47893 10.7336 5.83939C10.9985 5.19985 11.0678 4.49611 10.9328 3.81718C10.7978 3.13825 10.4644 2.51461 9.97493 2.02513C9.48544 1.53564 8.8618 1.2023 8.18287 1.06725C7.50394 0.932205 6.80021 1.00152 6.16067 1.26642C5.52113 1.53133 4.9745 1.97993 4.58992 2.5555C4.20533 3.13107 4.00006 3.80776 4.00006 4.5C4.00006 5.42825 4.36881 6.31849 5.02519 6.97487C5.68156 7.63124 6.5718 7.99999 7.50006 7.99999ZM7.50006 2C7.99451 2 8.47786 2.14662 8.88898 2.42133C9.3001 2.69603 9.62053 3.08647 9.80975 3.54329C9.99897 4.0001 10.0485 4.50277 9.95202 4.98772C9.85555 5.47267 9.61745 5.91813 9.26782 6.26776C8.91819 6.61739 8.47273 6.85549 7.98778 6.95196C7.50283 7.04842 7.00016 6.99891 6.54335 6.80969C6.08653 6.62047 5.69609 6.30004 5.42139 5.88892C5.14668 5.4778 5.00006 4.99445 5.00006 4.5C5.00006 3.83696 5.26345 3.20107 5.73229 2.73223C6.20113 2.26339 6.83702 2 7.50006 2Z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="0.25"
          />
          <path
            d="M13.735 11.6851C12.9331 10.8376 11.9669 10.1626 10.8953 9.70125C9.82364 9.2399 8.6692 9.00195 7.50249 9.00195C6.33579 9.00195 5.18134 9.2399 4.10973 9.70125C3.03811 10.1626 2.07185 10.8376 1.27 11.6851C1.09616 11.8708 0.999607 12.1158 1 12.3701V15.0001C1 15.2654 1.10536 15.5197 1.29289 15.7072C1.48043 15.8948 1.73478 16.0001 2 16.0001H13C13.2652 16.0001 13.5196 15.8948 13.7071 15.7072C13.8946 15.5197 14 15.2654 14 15.0001V12.3701C14.0017 12.1165 13.907 11.8716 13.735 11.6851ZM13 15.0001H2V12.3651C2.70861 11.619 3.56164 11.0248 4.50717 10.6188C5.45271 10.2128 6.47097 10.0034 7.49999 10.0034C8.52901 10.0034 9.54727 10.2128 10.4928 10.6188C11.4383 11.0248 12.2914 11.619 13 12.3651V15.0001Z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="0.25"
          />
          <path
            d="M14 3L18 7"
            stroke="currentColor"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18 3L14 7"
            stroke="currentColor"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
      break;
    default:
      break;
  }
  return icon;
}
