import {
  CircularProgress,
  InputBase,
  Tooltip,
  useTheme,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { SnackbarContext } from "App";
import useCommunication from "hooks/context-hooks/useCommunication";
import moment from "moment";
import { useContext, useState } from "react";
import { useQueryClient } from "react-query";
import messageService from "services/messageService";
import { delay } from "utils/commonUtils";
import useSendMessage from "utils/hooks/useSendMessage";
import DFIconButton from "./DFCustoms/DFIconButton";
import { DFSendChatIcon, DFSendSmsIcon } from "./DFIcons";
import useUserContext from "hooks/context-hooks/useUserContext";

function MessageBox({ logHistoryProps, patient }) {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { setSnack } = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const [textMessage, setTextMessage] = useState("");
  const { refetch: refetchCommunications } = useCommunication();
  const { officeId, token } = useUserContext();

  const sendMessageFunctions = {
    ChatWidget: async () => {
      await messageService.sendToWiget({
        lead_id: patient.patientID,
        office: officeId,
        message: textMessage,
      });
    },
    sms_logs: async () => {
      await messageService.sendSms(
        {
          id: patient.patientID,
          office: officeId,
          token: token,
        },
        {
          "api-token": token,
        },
        { message: textMessage }
      );
    },
    sms_log: async () => {
      await messageService.sendSms(
        {
          id: patient.patientID,
          office: officeId,
          token: token,
        },
        {
          "api-token": token,
        },
        { message: textMessage }
      );
    },
  };

  const sendTextMessage = async (mode) => {
    const defaultModeNew = getDefaultMode();
    await queryClient.setQueryData(["convo", patient?.patientID], (oldData) => {
      // const keys = getRelatedCacheKeys(queryClient, "convo");
      // await queryClient.setQueryData([keys[0][0], keys[0][1]], (oldData) => {

      const newData = {
        body: textMessage,
        created_at: moment(),
        id: "",
        sms_status: "sent",
        type: mode || defaultModeNew,
        type_name: "Office Sms",
      };
      const oldPagesData = oldData?.pages?.[0]?.data;

      oldPagesData.unshift(newData);
      return {
        pageParams: oldData?.pageParams,
        pages: [...oldData?.pages, oldPagesData],
      };
    });
    try {
      if (!isLoading) setIsLoading(true);
      if (mode) {
        await sendMessageFunctions[mode]();
      } else {
        await sendMessageFunctions[defaultModeNew]();
      }

      setTextMessage("");
      setIsLoading(false);
      await delay(4000);
      if (refetchCommunications) await refetchCommunications();
      await logHistoryProps.refetch();
    } catch (err) {
      setTextMessage("");
      console.log(err);
      setIsLoading(false);
      if (err.response) {
        console.log(
          "client received an error response (5xx, 4xx)",
          err.response
        );
        setSnack({
          open: true,
          severity: "error",
          message: `Error! ${err.response.data.message}`,
        });
      } else if (err.request) {
        console.log("client never received a response, or request never left");
        // client never received a response, or request never left
      } else {
        console.log("Other errors");
        // anything else
      }
    }
  };

  const { RenderPopperMenu, handleClick: handleOpenPopover } =
    useSendMessage(sendTextMessage);

  const handleChangeMessage = (event) => {
    setTextMessage(event.target.value);
  };
  const getDefaultMode = () => {
    const val = logHistoryProps?.data?.pages[0]?.data?.[0]?.type;
    const defaultModeNew =
      val && ["ChatWidget", "sms_logs", "sms_log"].includes(val)
        ? val
        : "sms_log";
    return defaultModeNew;
  };
  return (
    <>
      <InputBase
        style={{ paddingLeft: 10 }}
        placeholder="Enter message"
        multiline
        fullWidth
        inputProps={{ "aria-label": "naked" }}
        disabled={isLoading ? true : false}
        value={textMessage}
        onChange={handleChangeMessage}
      />
      <Tooltip title="Send Message">
        <span>
          <DFIconButton
            disabled={isLoading ? true : false}
            onClick={() => sendTextMessage(null)}
            color="primary"
            style={{ padding: 10 }}
            size="small"
            icon={
              isLoading ? (
                <CircularProgress
                  size={24}
                  thickness={5.6}
                  style={{
                    color: theme.palette.action.disabled,
                  }}
                />
              ) : (
                <div style={{ transform: "translateY(5px)" }}>
                  {getDefaultMode() === "ChatWidget" ? (
                    <DFSendChatIcon />
                  ) : (
                    <DFSendSmsIcon />
                  )}
                </div>
              )
            }
          />
        </span>
      </Tooltip>
      <span>
        <DFIconButton
          size="small"
          onClick={handleOpenPopover}
          disabled={isLoading}
          icon={<MoreVertIcon />}
        />
        <RenderPopperMenu />
      </span>
    </>
  );
}

export default MessageBox;
