import React from "react";

import { useTheme } from "@material-ui/core/styles";

export default function DFEndCall(active) {
  const theme = useTheme();
  return (
    // style={{ position: "absolute" }}

    <svg
      style={{ position: "absolute" }}
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="35" cy="35" r="35" fill="#C4161C" />
      <path
        d="M26.306 34.6134L26.306 38.6452C26.3027 39.1848 26.1427 39.7119 25.8456 40.1624C25.5484 40.6129 25.1269 40.9675 24.6321 41.183L21.1943 42.6589C20.6897 42.8722 20.1333 42.9313 19.5952 42.8288C19.0571 42.7263 18.5613 42.4668 18.1704 42.083L13.6707 37.5832C13.4093 37.3176 13.2043 37.0019 13.0682 36.655C12.932 36.3082 12.8674 35.9373 12.8784 35.5648C12.8893 35.1924 12.9755 34.8259 13.1318 34.4877C13.288 34.1494 13.5112 33.8462 13.7877 33.5964C32.0747 17.4512 50.1278 28.5567 56.3645 33.4794C56.669 33.7229 56.9185 34.0281 57.0966 34.375C57.2746 34.7218 57.3772 35.1025 57.3976 35.4918C57.418 35.8812 57.3558 36.2705 57.2149 36.634C57.0741 36.9976 56.8579 37.3272 56.5805 37.6012L52.2337 41.948C51.8447 42.3347 51.3488 42.5962 50.81 42.6989C50.2711 42.8015 49.7138 42.7406 49.2099 42.524L45.772 41.048C45.2741 40.8385 44.8489 40.4869 44.5496 40.0371C44.2503 39.5874 44.0901 39.0594 44.0891 38.5192L44.0891 34.4874C44.0891 34.4874 35.0716 27.7917 26.306 34.6134Z"
        fill="white"
      />
    </svg>
  );
}
