import React, { useState } from "react";

import { ErrorMessage, Field } from "formik";
import Skeleton from "@material-ui/lab/Skeleton";
import TextField from "@material-ui/core/TextField";
import { Typography, makeStyles } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import DFIconButton from "./DFCustoms/DFIconButton";

const useStyles = makeStyles((theme) => ({
  inputPassword: {
    "& div": {
      "& input": {
        paddingRight: 50,
      },
    },
  },
}));

export default function FormikField(props) {
  const classes = useStyles();
  const [see, setSee] = useState(false);
  const handleClickSee = () => {
    setSee(!see);
    props.seePassword(false);
  };
  const CustomInputComponent = ({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
  }) => (
    <div style={{ marginBottom: 10, position: "relative" }}>
      <Typography variant="caption" color="textSecondary">
        {props.inputlabel}
      </Typography>
      {props.isloading ? (
        <Skeleton
          animation="wave"
          height={45}
          style={{ borderRadius: 10 }}
          variant="rect"
        />
      ) : (
        <TextField
          className={props.hasShowPassword ? classes.inputPassword : ""}
          {...field}
          {...props}
          error={
            (touched[field.name] && errors[field.name] && true) ||
            (props.error && true)
          }
        />
      )}
      {props.hasShowPassword ? (
        <DFIconButton
          onClick={handleClickSee}
          style={{ position: "absolute", right: 4, top: 24, bottom: 7 }}
          icon={see ? <VisibilityIcon /> : <VisibilityOffIcon />}
        />
      ) : null}
    </div>
  );

  return (
    <Field
      autoComplete="off"
      component={CustomInputComponent}
      name={props.name}
      fullWidth
      type={props.type}
      variant="outlined"
      multiline={props.multiline}
      {...props}
      helperText={<ErrorMessage name={props.name} />}
    />
  );
}
