import React from "react";

import { useTheme } from "@material-ui/core/styles";

export default function DFSearchIcon() {
  const theme = useTheme();
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.7"
        d="M12.7118 10.1525L9.84159 7.28207C10.289 6.54761 10.525 5.70387 10.5236 4.84383C10.5236 2.24835 8.27473 6.14204e-08 5.6795 6.14204e-08C5.06248 -9.97165e-05 4.45149 0.121369 3.88142 0.357468C3.31135 0.593566 2.79338 0.93967 2.35708 1.37601C1.92078 1.81235 1.57471 2.33037 1.33863 2.9005C1.10256 3.47062 0.981101 4.08167 0.981201 4.69875C0.981201 7.29346 3.2301 9.54257 5.82456 9.54257C6.65619 9.54332 7.47293 9.322 8.19042 8.90149L11.0758 11.7886C11.2115 11.924 11.3953 12 11.5869 12C11.7786 12 11.9624 11.924 12.0981 11.7886L12.8143 11.0724C13.0961 10.7906 12.9935 10.4343 12.7118 10.1525ZM2.4273 4.69875C2.4272 4.27156 2.51125 3.84853 2.67464 3.45383C2.83803 3.05913 3.07757 2.70049 3.37958 2.39838C3.68158 2.09628 4.04014 1.85663 4.43476 1.69313C4.82938 1.52963 5.25235 1.44548 5.6795 1.44548C7.47649 1.44548 9.07752 3.04591 9.07752 4.84383C9.07732 5.70658 8.73454 6.53395 8.12453 7.14401C7.51453 7.75408 6.68724 8.0969 5.82456 8.0971C4.02758 8.09634 2.4273 6.49515 2.4273 4.69875Z"
        fill={theme.palette.text.hint}
      />
    </svg>
  );
}
