import { useContext } from "react";
import { UserContext } from "context-providers/UserManager";

function useUserContext() {
  const { user, setUser, checkRoles, logoutUser, switchClinic, treatments } =
    useContext(UserContext);
  return {
    user,
    officeId: user?.current_office?.id,
    userId: user?.id,
    token: user?.token,
    setUser,
    checkRoles,
    logoutUser,
    switchClinic,
    treatments,
  };
}

export default useUserContext;
