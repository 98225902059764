import React from "react";

import { useTheme } from "@material-ui/core/styles";

export default function DFTagCall(active) {
  const theme = useTheme();
  return (
    // style={{ position: "absolute" }}
    <svg
      style={{ position: "absolute" }}
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.0984 9.29091C21.8589 10.0499 23.0962 10.0499 23.8593 9.29091C24.6199 8.53445 24.6199 7.30583 23.8593 6.54683C23.4918 6.18192 22.9951 5.97717 22.4776 5.97717C21.96 5.97717 21.4633 6.18192 21.0958 6.54683C20.3352 7.30583 20.3352 8.53445 21.0958 9.29091H21.0984Z"
        fill="#373737"
      />
      <path
        d="M1.49561 13.5302C1.02173 14.0003 0.645577 14.5598 0.388873 15.1763C0.132169 15.7928 0 16.4542 0 17.1221C0 17.7901 0.132169 18.4514 0.388873 19.0679C0.645577 19.6844 1.02173 20.2439 1.49561 20.7141L9.88739 29.0631C11.8801 31.0456 15.1101 31.0456 17.1053 29.0631L28.9958 17.2338C29.9415 16.2895 30.479 15.0126 30.4891 13.6774L30.55 5.11514C30.5527 4.44139 30.4219 3.77379 30.1651 3.15099C29.9084 2.52819 29.5308 1.96257 29.0543 1.48686C28.5778 1.01114 28.0117 0.634795 27.389 0.379597C26.7662 0.124399 26.099 -0.00458104 25.4261 0.000124249L17.151 0.0356627C15.8056 0.0401307 14.5161 0.574475 13.561 1.52321L1.49561 13.5302ZM3.30073 18.9168C3.06379 18.6817 2.87571 18.402 2.74736 18.0937C2.61901 17.7855 2.55292 17.4548 2.55292 17.1208C2.55292 16.7869 2.61901 16.4562 2.74736 16.1479C2.87571 15.8397 3.06379 15.5599 3.30073 15.3249L15.3687 3.31791C15.8456 2.84416 16.4893 2.57703 17.1611 2.57413L25.4363 2.5386C26.856 2.53352 28.0096 3.68345 27.9969 5.09737L27.9361 13.6596C27.9307 14.3276 27.6619 14.9663 27.1882 15.4366L15.3002 27.2658C14.3039 28.2584 12.6889 28.2584 11.6925 27.2658L3.30073 18.9143V18.9168Z"
        fill="#373737"
      />
    </svg>
  );
}
