import React, { useState } from "react";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import moment from "moment";
import {
  Button,
  Typography,
  Popover,
  ListItemText,
  ListItem,
  List,
  Divider,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  makeStyles,
} from "@material-ui/core";
import {
  lastDayOfMonth,
  lastDayOfWeek,
  startOfMonth,
  startOfWeek,
  subMonths,
  subDays,
  setMonth,
} from "date-fns";
import { DateRange } from "react-date-range";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const useStyles = makeStyles((theme) => ({
  root: { marginBottom: 30 },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-between",
    gap: 5,
  },
  popoverPaper: {
    minWidth: "80vw",
  },
  hoverAccord: {
    boxShadow: "none",
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    "&:before": {
      backgroundColor: "rgba(255,255,255, 0)",
    },
  },
}));

const initialRange = [
  {
    startDate: moment()._d,
    endDate: moment()._d,
    key: "selection",
  },
];

export default function DashboardNav({ setState, state, hideCustomOption }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [dateRange, setDateRange] = useState(initialRange);
  const [openRange, setOpenRange] = useState(false);

  const handleClickDateRange = (e) => {
    setDateRange(initialRange);
    const { id } = e.currentTarget;
    switch (id) {
      case "today":
        setState({
          ...state,
          dateRange: {
            startDate: moment()._d,
            endDate: moment()._d,
            key: id,
          },
        });
        break;
      case "week":
        setState({
          ...state,
          dateRange: {
            startDate: subDays(moment()._d, 6),
            endDate: moment()._d,
            key: id,
          },
        });
        break;
      case "month":
        setState({
          ...state,
          dateRange: {
            startDate: subMonths(moment()._d, 1),
            endDate: moment()._d,
            key: id,
          },
        });
        break;
      case "last3Months":
        setState({
          ...state,
          dateRange: {
            startDate: subMonths(moment()._d, 3),
            endDate: moment()._d,
            key: id,
          },
        });
        break;
      case "last6Months":
        setState({
          ...state,
          dateRange: {
            startDate: subMonths(moment()._d, 6),
            endDate: moment()._d,
            key: id,
          },
        });
        break;
      case "lastYear":
        setState({
          ...state,
          dateRange: {
            startDate: subMonths(moment()._d, 12),
            endDate: moment()._d,
            key: id,
          },
        });
        break;
      case "more": {
        setAnchorEl(e.currentTarget);
        return;
      }
      default:
        break;
    }
  };

  const handeChangeDateRangeComp = (e) => {
    setState({
      ...state,
      dateRange: e[0],
    });
    setDateRange(e);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const newDateRange = [
    {
      endDate: state.dateRange.endDate
        ? moment(state.dateRange.endDate)._d
        : null,
      startDate: state.dateRange.startDate
        ? moment(state.dateRange.startDate)._d
        : null,
      key: "selection",
    },
  ];

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const { key } = state.dateRange;
  return (
    <div className={classes.root}>
      <div className={classes.buttonGroup}>
        <Button
          onClick={handleClickDateRange}
          id="today"
          variant="outlined"
          color={key === "today" ? "primary" : "default"}
          fullWidth
        >
          TODAY
        </Button>
        <Button
          onClick={handleClickDateRange}
          id="week"
          variant="outlined"
          color={key === "week" ? "primary" : "default"}
          fullWidth
        >
          WEEK
        </Button>
        <Button
          onClick={handleClickDateRange}
          id="month"
          variant="outlined"
          color={key === "month" ? "primary" : "default"}
          fullWidth
        >
          MONTH
        </Button>
        <Button
          onClick={handleClickDateRange}
          id="more"
          variant="outlined"
          color={
            key === "last3Months" ||
            key === "last6Months" ||
            key === "lastYear" ||
            key === "selection"
              ? "primary"
              : "default"
          }
          endIcon={<ArrowDropDownIcon />}
          aria-controls="simple-menu"
          aria-haspopup="true"
          aria-describedby={id}
          fullWidth
        >
          MORE
        </Button>
      </div>
      <Popover
        classes={{ paper: classes.popoverPaper }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div style={{ display: "grid" }}>
          <List component="nav" style={{ padding: 0 }}>
            <ListItem
              selected={key === "last3Months"}
              onClick={handleClickDateRange}
              id="last3Months"
              button
            >
              <ListItemText
                disableTypography={true}
                primary={
                  <Typography
                    style={
                      key === "last3Months" ? { opacity: 1 } : { opacity: 0.7 }
                    }
                    variant="button"
                  >
                    LAST 3 MONTHS
                  </Typography>
                }
              />
            </ListItem>
            <Divider />
            <ListItem
              selected={key === "last6Months"}
              onClick={handleClickDateRange}
              id="last6Months"
              button
            >
              <ListItemText
                disableTypography={true}
                primary={
                  <Typography
                    style={
                      key === "last6Months" ? { opacity: 1 } : { opacity: 0.7 }
                    }
                    variant="button"
                  >
                    LAST 6 MONTHS
                  </Typography>
                }
              />
            </ListItem>
            <Divider />
            <ListItem
              selected={key === "lastYear"}
              onClick={handleClickDateRange}
              id="lastYear"
              button
            >
              <ListItemText
                disableTypography={true}
                primary={
                  <Typography
                    style={
                      key === "lastYear" ? { opacity: 1 } : { opacity: 0.7 }
                    }
                    variant="button"
                  >
                    LAST YEAR
                  </Typography>
                }
              />
            </ListItem>
            <Divider />
          </List>
          {!hideCustomOption && (
            <Accordion
              expanded={openRange}
              onChange={() => setOpenRange(!openRange)}
              className={classes.hoverAccord}
            >
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography style={{ opacity: 0.7 }} variant="button">
                  CUSTOM
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: 0, display: "grid" }}>
                <DateRange
                  editableDateInputs={true}
                  onChange={(item) =>
                    handeChangeDateRangeComp([item.selection])
                  }
                  moveRangeOnFirstSelection={false}
                  ranges={newDateRange}
                  maxDate={new Date()}
                />
              </AccordionDetails>
            </Accordion>
          )}
        </div>
      </Popover>
    </div>
  );
}
