import { Typography } from "@material-ui/core";
import React from "react";
import DFCardSkeleton from "./DFCardSkeleton";

function LoaderNiMichaelGwapo({
  number_of_items,
  type,
  isLoading,
  isFetchingNextPage,
  showEmptyMessage,
  ...props
}) {
  return (
    <>
      {isLoading && (
        <DFCardSkeleton number_of_items={number_of_items} type={type} />
      )}
      {props.children}
      {isFetchingNextPage && <DFCardSkeleton number_of_items={1} type="log" />}
      {showEmptyMessage && (
        <div
          style={{
            display: "grid",
            placeItems: "center",
            position: "absolute",
            inset: 0,
          }}
        >
          <center>
            <svg
              enableBackground="new 0 0 48 48"
              id="Layer_1"
              version="1.1"
              viewBox="0 0 48 48"
              style={{ width: 100, height: 100, fill: "rgba(0, 0, 0, 0.54)" }}
            >
              <path
                clipRule="evenodd"
                d="M37,47H11c-2.209,0-4-1.791-4-4V8c0-2.209,1.791-4,4-4h3l0,0c0.553,0,1,0.448,1,1  s-0.447,1-1,1l0,0h-3C9.896,6,9,6.896,9,8v35c0,1.104,0.896,2,2,2h26c1.104,0,2-0.896,2-2V8c0-1.104-0.896-2-2-2h-3l0,0  c-0.553,0-1-0.448-1-1s0.447-1,1-1c0,0,0,0,0.001,0H37c2.209,0,4,1.791,4,4v35C41,45.209,39.209,47,37,47z M35,9  c0,0.552-0.447,1-1,1H14c-0.553,0-1-0.448-1-1s0.447-1,1-1c0,0,1.125-0.125,2-1l2-2c0,0,0.781-1,2-1h1c0-1.657,1.344-3,3-3  c1.657,0,3,1.343,3,3h1c1.312,0,2,1,2,1l2,2c0.875,0.875,2,1,2,1C34.553,8,35,8.448,35,9z M24,3c-0.553,0-1,0.448-1,1h2  C25,3.448,24.553,3,24,3z M29.363,7c0,0-0.679-1-1.817-1h-7.091c-1.14,0-1.818,1-1.818,1l-0.909,1h12.545L29.363,7z"
                fillRule="evenodd"
              />
            </svg>
            <Typography variant="h6" align="center" color="textSecondary">
              Empty List!
            </Typography>
            <Typography variant="caption" align="center" color="textSecondary">
              Sorry, we couldn't find what you're looking for.
            </Typography>
          </center>
        </div>
      )}
    </>
  );
}

export default LoaderNiMichaelGwapo;
