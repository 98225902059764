import React from "react";

import { useTheme } from "@material-ui/core/styles";

export default function DFMute(active) {
  const theme = useTheme();
  return (
    // style={{ position: "absolute" }}

    <svg
      style={{ position: "absolute" }}
      width="31"
      height="38"
      viewBox="0 0 31 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.3055 17.3164V11.5287L21.2509 14.5834V17.3164C21.2469 19.3542 19.5628 21.0182 17.4728 21.0141C16.7051 21.0182 15.9937 20.7971 15.3828 20.4113L13.2124 22.6218C14.382 23.5222 15.8611 24.0688 17.4728 24.0688C21.2429 24.0688 24.3055 21.0503 24.3055 17.3164Z"
        fill="#373737"
      />
      <path
        d="M30.3745 16.9547H27.9629C27.7861 16.9547 27.6414 17.0994 27.6414 17.2762C27.6414 22.9152 23.0715 27.4851 17.4325 27.4851C14.9004 27.4851 12.5812 26.5607 10.8007 25.0334L8.63033 27.2038C10.5957 28.9401 13.0837 30.1097 15.8248 30.4594V34.559H9.99687C9.4382 34.559 8.99608 35.1337 8.99206 35.8452V37.2921C8.99608 37.4689 9.10862 37.6136 9.23321 37.6136H25.6317C25.7604 37.6136 25.8729 37.4689 25.8729 37.2921V35.8452C25.8729 35.1337 25.4308 34.559 24.8681 34.559H18.8794V30.4594C25.5232 29.7399 30.696 24.113 30.696 17.2762C30.696 17.0994 30.5513 16.9547 30.3745 16.9547ZM30.901 1.774L29.1526 0.0899368C29.028 -0.0306406 28.827 -0.0306406 28.7024 0.0939561L23.5176 5.27878C22.3882 3.09633 20.0852 1.60117 17.4325 1.60117C13.6584 1.60117 10.5998 4.62767 10.5998 8.35351V17.3566C10.5998 17.6259 10.6158 17.8912 10.648 18.1524L7.9149 20.8855C7.49288 19.7641 7.25976 18.5463 7.26378 17.2762C7.25575 17.0994 7.11105 16.9547 6.94224 16.9547H4.5307C4.35385 16.9547 4.20916 17.0994 4.20916 17.2762C4.20916 19.4064 4.71156 21.4161 5.59982 23.2006L0.0934475 28.707C-0.0311492 28.8316 -0.0311492 29.0366 0.0934475 29.1611L1.80967 30.8774C1.93426 31.002 2.13924 31.002 2.26384 30.8774L30.905 2.23621L30.909 2.2322C31.0336 2.10358 31.0296 1.8986 30.901 1.774ZM13.6544 15.146V8.35351C13.6544 6.31977 15.3385 4.6558 17.4325 4.6558C19.2813 4.6558 20.8127 5.95402 21.1422 7.65818L13.6544 15.146Z"
        fill="#373737"
      />
    </svg>
  );
}
