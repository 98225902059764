import config from "config/config";
import useUserContext from "hooks/context-hooks/useUserContext";
import { GET, PUT } from "utils/restUtils";

export default function useNotificationService() {
  const { token, officeId } = useUserContext();

  const viewNotificationList = (page) => {
    return GET(
      `${config.BASE_URL}/api/v1/notifications/all_activities?api_token=${token}&current_office=${officeId}&page=${page}`
    );
  };

  const updateNotificationList = (notif_id) => {
    if (notif_id) {
      return PUT(
        `${config.BASE_URL}/api/v1/notifications/activities?api_token=${token}&current_office=${officeId}&update_seen_at=single&notification_id=${notif_id}`
      );
    }
  };

  return {
    viewNotificationList,
    updateNotificationList,
  };
}
