import React, { Fragment, useEffect, useState } from "react";

import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CallEndOutlinedIcon from "@material-ui/icons/CallEndOutlined";
import Skeleton from "@material-ui/lab/Skeleton";
import moment from "moment";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { MessageStatusIcon } from "components/DFIcons";
import { PATIENT_STATUS } from "utils/constants";

const useStyles = makeStyles((theme) => ({
  listRoot: {
    position: "relative",
    padding: "25px 32px",
    cursor: "pointer",
    "&:hover": {
      filter: "brightness(0.8)",
    },
  },
  noWrapTypo: {
    whiteSpace: "unset",
    textOverflow: "unset",
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
}));

const CommunicationCard = React.forwardRef(
  ({ loadPatientID, isLoading, params, showConvo }, ref) => {
    const classes = useStyles();
    const [data, setData] = useState(params);

    useEffect(() => {
      setData(params);
    }, [params]);

    const fname = data?.first_name ? data?.first_name : "";
    const lname = data?.last_name ? data?.last_name : "";
    const fullName = `${fname} ${lname}`;
    return (
      <Fragment>
        <Grid
          ref={ref}
          className={classes.listRoot}
          onClick={() => showConvo(data)}
          container
          style={{
            backgroundColor:
              PATIENT_STATUS[data.patient_status - 1]?.light_color ||
              "rgba(42,51,70,.25)",
            borderBottom: `1px solid ${
              PATIENT_STATUS[data.patient_status - 1]?.light_color ||
              "rgba(42,51,70,.25)"
            }`,
          }}
        >
          <Grid xs item>
            <Typography
              style={{
                textTransform: "capitalize",
                fontWeight: 700,
                color:
                  PATIENT_STATUS[data.patient_status - 1]?.main_color ||
                  "#2A3346",
              }}
              component="div"
              variant="subtitle1"
            >
              {isLoading && loadPatientID === data.id ? (
                <Skeleton animation="wave" width="55vw" />
              ) : (
                fullName
              )}
            </Typography>
          </Grid>
          <Grid xs="auto" item>
            <Typography variant="caption">
              {moment(data.created_at).fromNow()}
            </Typography>
          </Grid>
          <Grid xs={12} item>
            {data.message_type !== "call_log" &&
            data.message !== "first_message" ? (
              <Typography variant="caption" component="div">
                {isLoading && loadPatientID === data.id ? (
                  <Skeleton animation="wave" width="45vw" />
                ) : (
                  <div style={{ display: "flex", gap: 10 }}>
                    <MessageStatusIcon status={data.sms_status} height="20px" />
                    <Typography
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        lineClamp: "2",
                        WebkitLineClamp: "2",
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {data.message}
                    </Typography>
                  </div>
                )}
              </Typography>
            ) : (
              ""
            )}
            {data.message_type === "call_log" && (
              <Typography variant="caption" component="div">
                {isLoading && loadPatientID === data.id ? (
                  <Skeleton animation="wave" width="45vw" />
                ) : (
                  <div style={{ display: "flex", gap: 10, align: "center" }}>
                    <CallEndOutlinedIcon fontSize="small" />
                    <span>Audio Recording</span>
                  </div>
                )}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
);
export default CommunicationCard;
