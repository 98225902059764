import { useState } from "react";
import { DFSnackbarNotif } from "components/DFCustoms";
import useLogHistory from "./useLogHistory";

function useSnackBar(props) {
  const [openSnack, setOpenSnack] = useState();
  const [messageInfo, setMessageInfo] = useState();
  const [renderLogHistory, { openLogHistory }] = useLogHistory(props.user);
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const func = async () => {
    await delay(3000);
    setOpenSnack(false);
  };

  const showSnack = (message, isCall) => {
    setMessageInfo(message);
    setOpenSnack(true);

    if (isCall) {
      func();
    }
  };

  const handleSetOpenReply = () => {
    setOpenSnack(false);
    const { patientID, office, patient } = messageInfo?.FinalData;
    const patientData = {
      patientID: patientID,
      officeID: office?.id,
      patientName: patient?.patientName,
      patientStatus: patient?.patientStatus,
    };
    openLogHistory(patientData);
    // setNotification(sms_log_id || call_log_id || web_form_id);
  };

  const renderSnackBar = () => {
    return (
      <>
        {renderLogHistory()}
        <DFSnackbarNotif
          messageInfo={messageInfo}
          setMessageInfo={setMessageInfo}
          openSnack={openSnack}
          setOpenSnack={setOpenSnack}
          handleSetOpenReply={handleSetOpenReply}
        />
      </>
    );
  };

  return { renderSnackBar, showSnack };
}

export default useSnackBar;
