import Dexie from "dexie";
import { toLower } from "lodash";
import { removeAllNonNumbers } from "./ArrayUtils";
import {
  getPatientInfo,
  getRecentlyUpdatedContacts,
} from "hooks/api-hooks/useContactService";

export const db = new Dexie("dentistfind_db");
db.version(3).stores({
  patient_leads: "id, value, full_name, patient_status, office_id, phone",
  recent_communications: "id, value, office_id, created_at",
});

db.open()
  .then(function (db) {
    console.log(" Database opened successfully");
    localStorage.setItem("dexieError", JSON.stringify(false));
  })
  .catch(function (err) {
    console.log("something went wrong with dexie js");
    localStorage.setItem("dexieError", JSON.stringify(true));
    // Error occurred
  });

// -filter by patient status:
// 1 => 'new_contact'
// 2 => 'patient_lead'
// 3 => 'booked_appointment'
// 4 => 'non_patient_related'
// 5 => 'not_appropiate'
// 6 => 'others'
export const reverseObj = {
  "%2Bfirst_name": false,
  "-first_name": true,
};

export const getContacts = (
  search,
  patient_leads_original,
  patientStatus,
  sort
) => {
  let loweredSearch = toLower(search);
  let isReverse = reverseObj[sort];
  let arr;
  arr = patient_leads_original?.filter((item) => {
    return (
      item.full_name.includes(loweredSearch) &&
      (patientStatus
        ? item?.patient_status?.toString()?.includes(patientStatus)
        : true)
    );
  });

  if (isReverse) {
    arr = arr.reverse();
  }

  let prevChar = "";
  arr?.map((val, i) => {
    let currentChar = val?.full_name.charAt(0).toUpperCase();
    if (!isNaN(currentChar)) currentChar = "#";
    if (prevChar !== currentChar) {
      arr.splice(i, 0, {
        id: i + 5,
        full_name: currentChar,
        value: null,
      });
    }
    prevChar = currentChar;
    return null;
  });
  document.documentElement.scrollTop = 0;
  return arr;
};

export const getOneContactById = async (id) => {
  const data = await db.patient_leads.where("id").equals(id).toArray();
  return data[0];
};

export const isContactExists = async (id, officeId, token, isSyncing) => {
  console.log("isContactExists called");
  if (!id || !officeId) return false;
  try {
    let count = await db.patient_leads
      .where("office_id".toString())
      .equals(officeId.toString())
      .count();
    // console.log("count=>", count);

    if (count === 0 && isSyncing === false) {
      console.log(
        "database is empty and no sync in progress. Syncing all contacts..."
      );
      return true;
    }

    if (isSyncing === false) {
      const data = await getOneContactById(id);
      if (data) {
        console.log(
          "database is not empty but no matching contact is found, syncing new contact..."
        );
        syncOneContact(id, officeId, token);
      } else {
        // console.log("contact already exists=>", data[0]);
      }

      // console.log("data=>", data);
    }
  } catch {}
};

export const syncOneContact = async (id, officeId, token) => {
  try {
    let { data: response } = await getPatientInfo(
      {
        id,
        current_office: officeId,
      },
      {
        headers: {
          "api-token": token,
        },
      }
    );
    // console.log("updated Contact=>", response.data);
    saveContactToDb(response.data);
  } catch (err) {
    console.log(err);
  }
};

export const updateLatestSync = async (officeId, isFullSync = false) => {
  let latest_sync = JSON.parse(localStorage.getItem("latest_sync"));

  localStorage.setItem(
    "latest_sync",
    JSON.stringify({
      ...latest_sync,
      [officeId]: {
        // ...latest_sync[officeId],
        hourlySync: JSON.stringify(Date.now()),
        ...(isFullSync
          ? { fortyEightHourSync: JSON.stringify(Date.now()) }
          : {
              fortyEightHourSync:
                latest_sync[officeId]?.fortyEightHourSync || null,
            }),
      },
    })
  );
};

export const fetchRecentlyUpdatedContacts = async (user) => {
  var arr = [];

  try {
    const { data } = await getRecentlyUpdatedContacts(
      {
        current_office: {
          id: user.current_office?.id,
        },
      },
      {
        search: "",
        filter: "",
        page: 1,
      },
      {
        headers: {
          "api-token": user.token,
          current_office: user.current_office?.id,
        },
      }
    );
    arr = arr.concat(data.data);
  } catch (e) {
    console.log(e);
  }
  arr.map((item) => {
    saveContactToDb(item);
    return null;
  });

  await updateLatestSync(user.current_office?.id, false);
};

export function saveContactToDb(item) {
  db.patient_leads.put({
    value: item,
    id: item.id,
    office_id: item.office.id,
    full_name: toLower(item.first_name) + " " + toLower(item.last_name),
    patient_status: item.patient_status,
    phone: removeAllNonNumbers(item.phone1),
  });
}
