import useCommunicationsService from "hooks/api-hooks/useCommunicationsService";
import useModal from "hooks/useModal";
import { usePubNub } from "pubnub-react";
import { useEffect, useState } from "react";
import { useInfiniteQuery } from "react-query";
import AllLogHistoryNew from "components/AllLogHistoryNew";
import { delay, pubnubCleanUp } from "utils/commonUtils";
import useCommunication from "./context-hooks/useCommunication";

function useLogHistory(user) {
  const pubnub = usePubNub();

  const [channels] = useState([user?.id]);
  const [isOpen, close, openModal] = useModal("log_history");
  const [patient, setPatient] = useState({});
  const [latestLogId, setLatestLogId] = useState(null);

  const { getAllCommunicationLogs } = useCommunicationsService();
  const { refetch: refetchCommunications } = useCommunication();
  const {
    data: logHistory,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    refetch,
  } = useInfiniteQuery(
    ["convo", patient?.patientID],
    async ({ pageParam = 1, queryKey }) => {
      const { data } = await getAllCommunicationLogs(queryKey[1], pageParam);

      return data;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages?.length + 1;
        return lastPage?.data?.length > 0 ? nextPage : undefined;
      },
    },
    { keepPreviousData: true },
    {
      select: (data) => ({
        pages: [...data.pages].reverse(),
        pageParams: [...data.pageParams].reverse(),
      }),
    }
  );

  const openLogHistory = async (data, latestLogId) => {
    setPatient(data);
    openModal();
    setLatestLogId(latestLogId);
  };
  useEffect(() => {
    try {
      const queryPatientId = logHistory?.pages[0].data[0].patient_leads[0].id;
      const queryLatestLogId = logHistory?.pages[0]?.data[0]?.id;

      if (queryPatientId === patient?.patientID) {
        if (queryLatestLogId === latestLogId) {
        } else {
          if (queryLatestLogId && latestLogId) {
            refetch();
          }
        }
      }
    } catch {}
    // console.log(logHistory);
  }, [logHistory, patient?.patientID, isOpen]);

  const upsertContactCallBack = async (data) => {
    const patientData = {
      ...patient,
      patientName: `${data.first_name} ${data.last_name}`,
      patientStatus: data.patient_status,
      phone1: data.phone1,
    };
    setPatient({ ...patientData });
    if (refetchCommunications) await refetchCommunications();
  };

  useEffect(() => {
    const listeners = {
      message: async (event) => {
        const message = event.message;
        const text = message.text || message;
        const parsedText = JSON.parse(text);
        if (
          parsedText?.event_type === "call-ended" ||
          parsedText?.outgoing_call_initiated === "call_disconnected"
        ) {
          console.log("delaying refetch by 3 seconds");
          await delay(3000);
          refetch();
        } else {
          refetch();
        }
      },
    };
    pubnub.addListener(listeners);
    pubnub.subscribe({ channels });
    return () => {
      pubnubCleanUp(pubnub, listeners);
    };
  }, []);
  const renderLogHistory = () => {
    return (
      <AllLogHistoryNew
        logHistoryProps={{
          data: logHistory,
          fetchNextPage,
          isFetchingNextPage,
          isLoading,
          refetch,
        }}
        user={user}
        upsertContactCallBack={upsertContactCallBack}
        patient={patient}
        isOpen={isOpen}
        close={close}
      />
    );
  };
  return [renderLogHistory, { openLogHistory, isOpen }];
}

export default useLogHistory;
