import React from "react";

import { useTheme } from "@material-ui/core/styles";

export default function DFBookAppointment(active) {
  const theme = useTheme();
  return (
    <svg
      style={{ position: "absolute" }}
      width="34"
      height="35"
      viewBox="0 0 34 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.9772 5.75202H33.1817V34.909H0.0908203V5.75202H6.29537V3.66938C6.29537 1.94078 7.68105 0.54541 9.39764 0.54541C11.1142 0.54541 12.4999 1.94078 12.4999 3.66938V5.75202H20.7726V3.66938C20.7726 1.94078 22.1583 0.54541 23.8749 0.54541C25.5915 0.54541 26.9772 1.94078 26.9772 3.66938V5.75202ZM8.36355 3.66938V8.87599C8.36355 9.45913 8.81855 9.91731 9.39764 9.91731C9.97673 9.91731 10.4317 9.45913 10.4317 8.87599V3.66938C10.4317 3.08624 9.97673 2.62806 9.39764 2.62806C8.81855 2.62806 8.36355 3.08624 8.36355 3.66938ZM22.8408 3.66938V8.87599C22.8408 9.45913 23.2958 9.91731 23.8749 9.91731C24.454 9.91731 24.909 9.45913 24.909 8.87599V3.66938C24.909 3.08624 24.454 2.62806 23.8749 2.62806C23.2958 2.62806 22.8408 3.08624 22.8408 3.66938ZM31.1135 32.8264V14.0826H2.159V32.8264H31.1135ZM10.4317 30.7438V16.1652H6.29537V30.7438H10.4317ZM18.7045 30.7438V16.1652H14.5681V30.7438H18.7045ZM26.9772 30.7438V16.1652H22.8408V30.7438H26.9772Z"
        fill="#373737"
      />
    </svg>
  );
}
