import {
  Checkbox,
  Divider,
  FormControlLabel,
  Typography,
} from "@material-ui/core";

function FollowupDetails({ payload, handleChange }) {
  const options = [
    {
      label: "1st Follow Up (Same Day)",
      name: "follow_up_1",

      onChange: handleChange("follow_up_1"),
    },
    {
      label: "2nd Follow Up (After 3 Days)",
      name: "follow_up_2",

      onChange: handleChange("follow_up_2"),
    },
    {
      label: "3rd Follow Up (After One Week)",
      name: "follow_up_3",

      onChange: handleChange("follow_up_3"),
    },
    {
      label: "4th Follow Up (After One Month)",
      name: "follow_up_4",

      onChange: handleChange("follow_up_4"),
    },
  ];
  return (
    <div style={{ marginBottom: 20 }}>
      <Divider style={{ marginBottom: 20 }} />
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Follow up Details
      </Typography>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {options.map((item) => (
          <FormControlLabel
            style={{ marginLeft: 0 }}
            control={
              <Checkbox
                checked={payload[item.name]}
                onChange={(e) => {
                  item.onChange(e.target.checked);
                }}
                name={item.name}
              />
            }
            label={item.label}
          />
        ))}
      </div>
    </div>
  );
}

export default FollowupDetails;
