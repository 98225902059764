import CallIcon from "@material-ui/icons/Call";
import TextsmsOutlinedIcon from "@material-ui/icons/TextsmsOutlined";
import LanguageOutlinedIcon from "@material-ui/icons/LanguageOutlined";

export const PATIENT_LOG_TYPES = [
  "Potential Patient",
  "Existing Patient",
  "Non Patient Related",
  "Unknown",
  "By Office",
  "Spam",
  "Wrong Number",
  "Untagged",
];

export const LOG_TYPE_NAME = {
  call_log: "View Call History",
  sms_log: "View Messages",
  web_form_log: "View Web Form History",
  ChatWidget: "View Messages",
};

export const LOG_TYPE_ICON = {
  10: <CallIcon fontSize="small" />,
  11: <TextsmsOutlinedIcon fontSize="small" />,
  46: <TextsmsOutlinedIcon fontSize="small" />,
  12: <LanguageOutlinedIcon fontSize="small" />,
};

export const LOG_TYPE_NAME_ICON = {
  call_log: <CallIcon fontSize="small" />,
  sms_log: <TextsmsOutlinedIcon fontSize="small" />,
  web_form_log: <LanguageOutlinedIcon fontSize="small" />,
};

export const LOG_TYPE_ID = {
  10: "call_log",
  11: "sms_log",
  12: "web_form_log",
  5: "df_call_logs",
  46: "chat_widget",
};

export const PATIENT_STATUS = [
  {
    name: "New Contact",
    value: 1,
    main_color: "#2A3346",
    light_color: "rgba(42,51,70,.25)",
    text_color: "#2A3346",
  },
  {
    name: "Patient Lead",
    value: 2,
    main_color: "#F17820",
    light_color: "rgba(241, 120, 32, .12)",
    text_color: "#F17820",
  },
  {
    name: "Booked Appointment",
    value: 3,
    main_color: "#4584DB",
    light_color: "rgba(69, 132, 219, .15)",
    text_color: "#0086D8",
  },
  {
    name: "Non Patient Related",
    value: 4,
    main_color: "#24497C",
    light_color: "rgba(36, 73, 124, .35)",
    text_color: "#24497C",
  },
  {
    name: "Not Appropriate",
    value: 5,
    main_color: "#7B1313",
    light_color: "rgba(249,87,87,.3)",
    text_color: "#7B1313",
  },
  {
    name: "Others",
    value: 6,
    main_color: "#861388",
    light_color: "rgba(134,19,136,.2)",
    text_color: "#861388",
  },
];

export const LOG_STATUS = [
  {
    name: "Potential Patient",
    value: 1,
    main_color: "#F17820",
    light_color: "rgba(241, 120, 32, .12)",
  },
  {
    name: "Existing Patient",
    value: 2,
    main_color: "#4584DB",
    light_color: "rgba(69, 132, 219, .15)",
  },
  {
    name: "Non Patient Related",
    value: 3,
    main_color: "#24497C",
    light_color: "rgba(36, 73, 124, .35)",
  },
  {
    name: "Unknown",
    value: 4,
    main_color: "#2A3346",
    light_color: "rgba(42,51,70,.25)",
  },
  {
    name: "By Office",
    value: 5,
    main_color: "#861388",
    light_color: "rgba(134,19,136,.2)",
  },
  {
    name: "Spam",
    value: 6,
    main_color: "#7B1313",
    light_color: "rgba(249,87,87,.3)",
  },
  {
    name: "Wrong Number",
    value: 7,
    main_color: "#4F191A",
    light_color: "rgba(79,25,26,.25)",
  },
  {
    name: "Untagged",
    value: 8,
    main_color: "#DFDFDF",
    light_color: "rgba(223,223,223,.3)",
  },
];

export const SORT_PATIENT = [
  { name: "Ascending A-Z", id: "%2Bfirst_name" },
  { name: "Descending Z-A", id: "-first_name" },
  { name: "Recent", id: "-created_at" },
];

export const SORT_PATIENT_V2 = {
  "%2Bfirst_name": "-first_name",
  "-first_name": "%2Bfirst_name",
};

export const SORT_CONVERSATION = [
  { name: "Newest", id: "-1" },
  { name: "Oldest", id: "%2B1" },
];
export const CALL_STATUS = [
  "queued",
  "ringing",
  "in-progress",
  "completed",
  "busy",
  "failed",
  "no-answer",
  "canceled",
];
export const SMS_STATUS = [
  "queued",
  "sending",
  "sent",
  "failed",
  "received",
  "delivered",
];
export const QUESTIONS = [
  {
    question:
      "Are you experiencing any of the following symptoms with unknown cause?",
    id: "question1",
  },
  {
    question:
      "Have you had contact with any person with, or under investigation for, COVID-19 in the last 14 days?",
    id: "question2",
  },
  {
    question:
      "Have you or anyone from your immediate household travelled outside of Canada in the past 14 days and are required to quarantine?",
    id: "question3",
  },
  {
    question:
      "In the past 10 -14 days, have you been required to quarantine or isolate by your local public health authority?",
    id: "question4",
  },
  {
    question:
      "If the person is 70 years of age or older, are they experiencing any of the following symptoms?",
    id: "question5",
  },
];
export const QUESTION1 = [
  { id: "chills", question: "Chills" },
  { id: "cough", question: "New or worsening cough" },
  {
    id: "difficulty_breathing",
    question: "Shortness of breath or difficulty breathing",
  },
  { id: "difficulty_swallowing", question: "Difficulty swallowing" },
  { id: "fatigue_weakness", question: "Fatigue or weakness" },
  { id: "feeling_feverish", question: "Feeling feverish" },
  { id: "feeling_unwell", question: "Feeling very unwell" },
  {
    id: "gastrointestinal_symptoms",
    question: "Gastrointestinal symptoms (abdominal pain, diarrhea, vomiting)",
  },
  { id: "headache", question: "Headache" },
  { id: "loss_smell", question: "Loss of smell or taste" },
  { id: "muscle_body_aches", question: "Muscle or body aches" },
  { id: "pink_eye", question: "Pink eye (conjunctivitis)" },
  {
    id: "runny_nose",
    question: "Runny nose or nasal congestion without other known cause",
  },
  { id: "sore_throat", question: "Sore throat" },
  { id: "temperature", question: "Temperature equal to or over 38 °C" },
];
export const QUESTION5 = [
  { id: "chronic_conditions", question: "Worsening of chronic conditions" },
  { id: "delirium", question: "Delirium" },
  { id: "functional_decline", question: "Acute functional decline" },
  {
    id: "number_of_falls",
    question: "Unexplained or increased number of falls",
  },
];
export const LANGUAGES = [
  { name: "French", value: "fr" },
  { name: "English", value: "en" },
  { name: "Mandarin", value: "man" },
  { name: "Cantonese", value: "zh_yue" },
  { name: "Punjabi", value: "pa" },
  { name: "Spanish", value: "es" },
  { name: "Tagalog", value: "tl" },
  { name: "Arabic", value: "ar" },
  { name: "German", value: "de" },
  { name: "Italian", value: "it" },
  { name: "Dutch", value: "nl" },
];

//dial_call_status
export const OUTGOING_CALL_LOGS_STATUS = {
  disconnect: "disconnect",
  recordingAvailable: "recordingAvailable",
  answer: "answer",
};
//call_status
export const INCOMING_CALL_LOGS_STATUS = {
  null: null,
  completed: "completed",
};

export const yesOrNo = [
  { name: "Yes", value: true },
  { name: "No", value: false },
];
