import { Paper, Typography } from "@material-ui/core";
import moment from "moment";

import { formatDisplayedNumber } from "utils/ArrayUtils";
import { LOG_TYPE_NAME_ICON } from "utils/constants";

function BubbleWebFormLog({ handleOpenDialog, val, classes }) {
  return (
    <Paper
      onClick={() => handleOpenDialog(val)}
      elevation={0}
      className={classes.paperLeft}
      style={{
        padding: "15px 15px",
        maxWidth: "85%",
        display: "flex",
        gap: 10,
        flexDirection: "row",
        justifySelf: "start",
        backgroundColor: "#fff",
      }}
    >
      <div style={{ marginTop: 3 }}>{LOG_TYPE_NAME_ICON[val?.type]}</div>
      <div>
        <Typography
          gutterBottom
          style={{
            whiteSpace: "pre-wrap",
            display: "inline-block",
            wordBreak: "break-word",
          }}
        >
          {val.comment === "" || !val.comment ? (
            <p>
              Appointment Request
              <br />
              <br />
              Name:{" "}
              {`${val.patient_leads[0]?.first_name || ""}` +
                " " +
                `${val.patient_leads[0]?.last_name || ""}`}
              <br />
              Email: {val.email}
              <br />
              Number: {formatDisplayedNumber(val.from)}
              <br />
              URL: {val.site_url}
            </p>
          ) : (
            val.comment
          )}
        </Typography>
        <Typography
          component="div"
          variant="caption"
          style={{ fontSize: 10, width: "100%" }}
          color="textSecondary"
          align="right"
        >
          {moment(val.created_at).format("hh:mm A")}
        </Typography>
      </div>
    </Paper>
  );
}

export default BubbleWebFormLog;
