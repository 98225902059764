import { GET, PUT } from "utils/restUtils";
import config from "config/config";
import { logLogType } from "utils/ArrayUtils";

const getPieData = (params, startDate, endDate, logType, customHeader) => {
  if (logType) {
    return GET(
      `${config.BASE_URL}/api/v1/performance/call_log_performance/${logType}?current_office=${params.current_office.id}&start_date=${startDate}&end_date=${endDate}`,
      customHeader
    );
  }
};

// For New Dashboard UI -------------
//  const getPieData = (params, startDate, endDate, customHeader) => {
//   return GET(
//     `${config.BASE_URL}/api/v1/patient_lead_statics/office_patient_statics?current_office=${params.current_office.id}&start_date=${startDate}&end_date=${endDate}`,
//     customHeader
//   );
// };

const getOneLog = ({ type, user, id, current_office }) => {
  const params = `?api_token=${user.token}&current_office=${current_office}`;
  if (type === 10) {
    //call_logs
    return GET(`${config.BASE_URL}/api/v1/call_logs/${id}` + params);
  }

  if (type === 11) {
    //sms_logs
    return GET(`${config.BASE_URL}/api/v1/sms_logs/${id}` + params);
  }

  if (type === 12) {
    //web_logs
    return GET(`${config.BASE_URL}/api/v1/web_logs/${id}` + params);
  }

  if (type === 5) {
    //df_call_logs
    return GET(`${config.BASE_URL}/api/v1/df_call_logs/${id}` + params);
  }

  if (type === 46) {
    ///chat_widgets
    return GET(`${config.BASE_URL}/api/v1/chat_widgets/${id.$oid}` + params);
  }
};

const updateOneLog = ({
  user,
  patientID,
  callType,
  booked,
  note,
  logType,
  current_office,
}) => {
  const getLogType = (logType) => {
    if (logType === "ChatWidget") return "chat_widget"; //not working, maybe there's no api for it yet
    if (logType === "web_form_log") return "web_logs";
    if (logType === "sms_log") return "sms_logs";
    if (logType === "call_log") return "call_logs";
    if (logType === "df_call_logs") return "df_call_logs";
  };

  const params = `?api_token=${
    user?.token
  }&current_office=${current_office}&${logLogType(
    logType,
    0
  )}=${callType}&is_appointment_booked=${booked}&note=${note}`;

  return PUT(
    `${config.BASE_URL}/api/v1/${getLogType(logType)}/${patientID}` + params
  );
};

export default {
  getPieData,
  updateOneLog,
  getOneLog,
};
