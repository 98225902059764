import React from "react";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function DFSnackbar({ actions: { snack, setSnack } }) {
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnack({
      open: false,
      severity: snack.severity,
      message: "",
    });
  };
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={snack.open}
      autoHideDuration={3000}
      onClose={handleCloseAlert}
    >
      <Alert onClose={handleCloseAlert} severity={snack.severity}>
        {snack.message}
      </Alert>
    </Snackbar>
  );
}
