import { Paper, Typography } from "@material-ui/core";
import moment from "moment";

import PhoneMissedIcon from "@material-ui/icons/PhoneMissed";
import AudioPlayer from "./audioPlayer/AudioPlayer";

import useCallFeature from "hooks/context-hooks/useCallFeature";
import { LOG_TYPE_NAME_ICON, OUTGOING_CALL_LOGS_STATUS } from "utils/constants";

function BubbleOutgoingCall({
  handleOpenDialog,
  val,
  classes,
  theme,
  playingAudioRef,
}) {
  const { callerData } = useCallFeature();

  const displayText = () => {
    if (val.id === callerData?.call_log_id && callerData?.is_call_log_popup) {
      return "Call in progress. Recording will be available soon.";
    } else if (val.id === callerData?.call_log_id) {
      return "Recording will be available soon. ";
    } else {
      return "Missed Call";
    }
  };
  return (
    <Paper
      onClick={() => handleOpenDialog(val)}
      elevation={0}
      className={classes.paperRight}
      style={{
        padding: "15px 15px",
        width: "85%",
        display: "flex",
        gap: 10,
        backgroundColor: "#cbf5ee",
        justifySelf: "end",
        alignItems: `${val.recording_sid ? "flex-start" : "center"}`,
      }}
    >
      {val.recording_sid ||
      val.id === callerData?.call_log_id ||
      val.call_status === OUTGOING_CALL_LOGS_STATUS.answer ? (
        <div style={{ marginTop: 10 }}>{LOG_TYPE_NAME_ICON[val?.type]}</div>
      ) : (
        <PhoneMissedIcon
          fontSize="small"
          style={{ color: theme.palette.secondary.main }}
        />
      )}
      <div style={{ width: "100%" }}>
        {val.recording_sid ? (
          <>
            <AudioPlayer
              src={val.recording_url}
              playingAudioRef={playingAudioRef}
            />

            {val.note && (
              <>
                <br />
                <Typography variant="body2">Note:</Typography>
                <Typography
                  gutterBottom
                  style={{
                    whiteSpace: "pre-wrap",
                    display: "inline-block",
                    wordBreak: "break-word",
                  }}
                >
                  {val.note}
                </Typography>
              </>
            )}
            <Typography
              variant="caption"
              component="div"
              style={{ fontSize: 10, width: "100%" }}
              color="textSecondary"
              align={"right"}
            >
              {moment(val.created_at).format("hh:mm A")}
            </Typography>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {displayText()}
            <Typography
              variant="caption"
              component="div"
              style={{ fontSize: 10 }}
              color="textSecondary"
              align={"right"}
            >
              {moment(val.created_at).format("hh:mm A")}
            </Typography>
          </div>
        )}
      </div>
    </Paper>
  );
}
export default BubbleOutgoingCall;
