export const modesMap = {
  PWA: "PWA",
  DESKTOP_APP: "DESKTOP_APP",
  ANDROID: "ANDROID",
};

const environmentMap = {
  STAGING: "STAGING",
  PRODUCTION: "PRODUCTION",
};
export const ENVIRONMENT = environmentMap.PRODUCTION; //Change this value to point api to 'STAGING' or 'PRODUCTION'
export const appMode = modesMap.PWA;
export const domains = {
  STAGING: "https://pwa.dentistfind.com",
  PRODUCTION: "https://mobile.dentistfind.com",
};

const configObj = {
  STAGING: {
    BASE_URL: "https://test.dentistfind.com",
  },
  PRODUCTION: {
    BASE_URL: "https://dentistfind.com",
  },
};

const config = configObj[ENVIRONMENT];

export default config;
