import { GET, PUT, POST } from "utils/restUtils";
import config from "config/config";
import useUserContext from "hooks/context-hooks/useUserContext";

export default function useCommunicationsService() {
  const { token, officeId } = useUserContext();

  const customHeader = {
    headers: {
      "api-token": token,
    },
  };

  const getAllCommunications = async (params) => {
    return GET(
      `${config.BASE_URL}/api/v1/patient_leads/patient_lead_recent_communication?office_id=${officeId}&sort=${params?.sort}&search=${params?.search}&patient_status=${params?.filter}&page=${params?.page}&start_date=${params?.startDate}&end_date=${params?.endDate}`,
      customHeader
    );
  };

  const getAllCommunicationLogs = async (patientId, page) => {
    return GET(
      `${config.BASE_URL}/api/v1/patient_leads/patient_lead_logs?current_office=${officeId}&id=${patientId}&api_token=${token}&page=${page}`,
      customHeader
    );
  };

  return {
    getAllCommunicationLogs,
    getAllCommunications,
  };
}
