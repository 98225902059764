import React, { useState } from "react";
import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Drawer,
  FormControl,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { includes } from "lodash";
import ContactSupport from "components/ContactSupport";
import DFIconButton from "components/DFCustoms/DFIconButton";
import useUserContext from "hooks/context-hooks/useUserContext";
import useModal from "hooks/useModal";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: "70vw",
  },
  selectedMenu: {
    color: theme.palette.primary.dark,
  },
  nested: {
    paddingLeft: theme.spacing(6),
  },
  listGutter: {
    paddingLeft: 30,
    paddingRight: 30,
  },
}));

export default function SideNavigation({ drawerToggle, drawerOpen, ...props }) {
  const { user, logoutUser, switchClinic } = useUserContext();
  const [isOpenLogout, closeLogout, openLogout] = useModal("logout");
  const [isOpenChangeClinic, closeChangeClinic, openChangeClinic] =
    useModal("switch_clinic");

  const initialState = {
    openDropDown: false,
    selectedClinic: user?.current_office?.id,
  };

  const classes = useStyles();
  const [state, setState] = useState(initialState);

  const handleSubmitSelectedClinic = () => {
    switchClinic(state, props.history, props.location);
  };

  const navItems = [
    {
      selected: props.match.path === "/dashboard" ? true : false,
      path: "/dashboard",
      label: "Dashboard",
    },
    {
      selected: props.match.path === "/patients" ? true : false,
      path: "/patients",
      label: "Contacts",
    },
    {
      selected: props.match.path === "/call_logs" ? true : false,
      path: "/call_logs",
      label: "Call Logs",
    },
    {
      selected: props.match.path === "/sms_logs" ? true : false,
      path: "/sms_logs",
      label: "SMS Logs",
    },
    {
      selected: props.match.path === "/web_form_logs" ? true : false,
      path: "/web_form_logs",
      label: "Web Form Logs",
    },
    {
      selected: includes(
        [
          "/appointments",
          "/add_appointment/:id",
          "/add_appointment",
          "/view_appointment/:id",
        ],
        props.match.path
      ),
      path: "/appointments",
      label: "Appointments",
    },
    {
      selected: props.match.path === "/communications" ? true : false,
      path: "/communications",
      label: "Communication",
    },
    {
      selected: props.match.path === "/patient-tracking-form" ? true : false,
      path: "/patient-tracking-form",
      label: "New Patient Tracking Form",
    },
  ];
  const sideDrawer = (
    <div style={{ height: "100%" }}>
      <div
        style={{
          display: "grid",
          justifyItems: "end",
          padding: "10px 5px",
        }}
      >
        <DFIconButton onClick={drawerToggle} icon={<CloseRoundedIcon />} />
      </div>
      <List>
        <ListItem
          button
          onClick={() => openChangeClinic()}
          classes={{
            selected: classes.selectedMenu,
            gutters: classes.listGutter,
          }}
        >
          <ListItemText
            primary={
              <h3 style={{ margin: 0 }}>{user?.current_office?.name}</h3>
            }
            secondary="Current Office"
          />
        </ListItem>
        <Divider />

        {navItems.map((item, idx) => {
          return (
            <ListItem
              key={idx}
              selected={item.selected}
              button
              onClick={() => {
                props.history.push(item.path);
              }}
              classes={{
                selected: classes.selectedMenu,
                gutters: classes.listGutter,
              }}
            >
              <ListItemText primary={item.label} />
            </ListItem>
          );
        })}
        {/* <Divider /> */}

        <div
          onMouseEnter={() => setState({ ...state, openDropDown: true })}
          onMouseLeave={() => setState({ ...state, openDropDown: false })}
        >
          <ListItem
            classes={{
              selected: classes.selectedMenu,
              gutters: classes.listGutter,
            }}
            selected={
              props.match.path === "/profile" || props.match.path === "/clinic"
                ? true
                : false
            }
            button
          >
            <ListItemText
              primary={
                <>
                  Account Settings
                  {state.openDropDown ? (
                    <ExpandLessIcon
                      color="primary"
                      style={{ transform: "translateX(5px) translateY(6px)" }}
                    />
                  ) : (
                    <ExpandMoreIcon
                      style={{ transform: "translateX(5px) translateY(6px)" }}
                    />
                  )}
                </>
              }
            />
          </ListItem>
          <Collapse in={state.openDropDown} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                classes={{
                  selected: classes.selectedMenu,
                }}
                selected={props.match.path === "/profile" ? true : false}
                button
                className={classes.nested}
                onClick={() => {
                  props.history.push("/profile");
                }}
              >
                <ListItemText primary="Personal Information" />
              </ListItem>
              <ListItem
                classes={{
                  selected: classes.selectedMenu,
                }}
                selected={props.match.path === "/clinic" ? true : false}
                button
                className={classes.nested}
                onClick={() => {
                  props.history.push("/clinic");
                }}
              >
                <ListItemText primary="Clinic Information" />
              </ListItem>
            </List>
          </Collapse>
        </div>

        <ContactSupport classes={classes} user={user} />
        <ListItem
          button
          onClick={() => openLogout()}
          classes={{
            selected: classes.selectedMenu,
            gutters: classes.listGutter,
          }}
        >
          <ListItemText primary="Log Out" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <>
      <Drawer
        variant="temporary"
        anchor="right"
        open={drawerOpen}
        onClose={drawerToggle}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {sideDrawer}
        <div
          style={{
            fontSize: 12,
            marginRight: 10,
            marginBottom: 10,
            color: "#949699",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          Version 2.3.1
        </div>
      </Drawer>
      <Dialog
        open={isOpenChangeClinic}
        maxWidth="xs"
        fullWidth={true}
        onClose={() => closeChangeClinic()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Change Clinic</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Changing clinic will reload the page and redirect to dashboard. Any
            unsaved changes will be lost.
          </DialogContentText>
          <FormControl variant="outlined" fullWidth>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={state.selectedClinic}
              onChange={(e) =>
                setState({ ...state, selectedClinic: e.target.value })
              }
            >
              {user?.offices?.map((val, idx) => (
                <MenuItem key={idx} value={val.id}>
                  {val.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeChangeClinic()} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSubmitSelectedClinic}
            color="primary"
            autoFocus
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isOpenLogout}
        maxWidth="xs"
        fullWidth={true}
        onClose={() => closeLogout()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Logging out</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeLogout()} color="primary">
            Cancel
          </Button>
          <Button onClick={logoutUser} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
