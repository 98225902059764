import { Switch } from "@material-ui/core";
import React, { useContext } from "react";
import { MainContext } from "./Main";

interface ViewToggleProps {
  title: "CALL LOGS" | "WEB FORM LOGS";
}

interface LogsView {
  mode: "TABLE" | "DEFAULT";
  set: (value: "TABLE" | "DEFAULT") => void;
}

interface MainContextProps {
  callLogsView: LogsView;
  webFormLogsView: LogsView;
}

export default function ViewToggle({ title }: ViewToggleProps) {
  if (title === "CALL LOGS") return <CallLogsSwitch />;
  if (title === "WEB FORM LOGS") return <WebFormLogsSwitch />;
  return <></>;
}

function CallLogsSwitch() {
  const { callLogsView } = useContext(MainContext) as MainContextProps;

  return (
    <span>
      <Switch
        color="default"
        checked={callLogsView.mode === "TABLE"}
        onChange={() => {
          callLogsView.set(map[callLogsView.mode]);
        }}
      />{" "}
      <span style={{ fontSize: 14 }}> {callLogsView.mode} View</span>
    </span>
  );
}

function WebFormLogsSwitch() {
  const { webFormLogsView } = useContext(MainContext) as MainContextProps;

  return (
    <span>
      <Switch
        color="default"
        checked={webFormLogsView.mode === "TABLE"}
        onChange={() => {
          webFormLogsView.set(map[webFormLogsView.mode]);
        }}
      />{" "}
      <span style={{ fontSize: 14 }}> {webFormLogsView.mode} View</span>
    </span>
  );
}

const map: Record<"TABLE" | "DEFAULT", "TABLE" | "DEFAULT"> = {
  DEFAULT: "TABLE",
  TABLE: "DEFAULT",
};
