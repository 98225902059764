import React from "react";
import { Button, Snackbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

import { formatDisplayedNumber } from "utils/ArrayUtils";
import DFIconButton from "./DFIconButton";

const useStyles = makeStyles((theme) => ({
  close: {
    padding: theme.spacing(0.5),
  },
}));

export default function DFSnackbarNotif({
  messageInfo,
  setMessageInfo,
  openSnack,
  setOpenSnack,
  handleSetOpenReply,
}) {
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const displayCaller = () => {
    let atleastOneAlpha;
    let answeredBy = "";
    answeredBy = messageInfo?.answeredBy.toString();
    atleastOneAlpha = answeredBy.match(".*[a-zA-Z]+.*");
    // console.log(answeredBy);

    if (!atleastOneAlpha) {
      answeredBy = formatDisplayedNumber(answeredBy, false);
    }
    return `Answered by: ${answeredBy}`;
  };
  return (
    <Snackbar
      key={messageInfo ? messageInfo.key : undefined}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      ContentProps={{
        "aria-describedby": "message-id",
      }}
      open={openSnack}
      // autoHideDuration={6000}
      onClose={handleClose}
      TransitionProps={{ onExited: () => setMessageInfo(undefined) }}
      message={
        <div id="message-id">
          {messageInfo?.answeredBy ? (
            <div style={{ margin: "auto", width: "200px" }}>
              <h3 style={{ margin: 0 }}>Call in progress..</h3>
              <p>{displayCaller()}</p>
            </div>
          ) : (
            <>
              <h3 style={{ margin: 0 }}>
                {messageInfo?.FinalData?.fullName
                  ? messageInfo?.FinalData?.fullName
                  : formatDisplayedNumber(messageInfo?.FinalData?.from, false)}
              </h3>
              <p>
                {messageInfo?.FinalData?.body ||
                  messageInfo?.FinalData?.comment}
              </p>
            </>
          )}
        </div>
      }
      action={
        <React.Fragment>
          {!messageInfo?.answeredBy && (
            <Button color="secondary" size="small" onClick={handleSetOpenReply}>
              REPLY
            </Button>
          )}
          <DFIconButton
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={handleClose}
            icon={<CloseIcon />}
          />
        </React.Fragment>
      }
    />
  );
}
