import React, { useState, useEffect } from "react";

import { Popper } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { countryToFlag } from "utils/ArrayUtils";

export default function DFAutoCompleteLocation({
  formik,
  name,
  gutterBottom,
  label,
  type,
  options,
  required,
  ...props
}) {
  const [code, setCode] = useState("");

  useEffect(() => {
    if (options) {
      options.map((vals, idx) => {
        if (type === "country") {
          if (formik.initialValues[name] === vals.code) {
            setCode(vals);
          }
          return vals;
        } else {
          if (formik.initialValues[name] === vals.state_code) {
            setCode(vals);
          }
          return vals;
        }
      });
    }
  }, [formik.initialValues[name], options]);

  const handleChange = (event, newVal) => {
    setCode(newVal);
    if (type === "country") {
      formik.setFieldValue(name, newVal ? newVal.code : "");
    } else {
      formik.setFieldValue(name, newVal ? newVal.state_code : "");
    }
  };
  return (
    <div style={gutterBottom ? { marginBottom: 10 } : {}}>
      <Typography variant="caption" color="textSecondary">
        {label}
      </Typography>

      <Autocomplete
        value={code}
        options={options}
        onChange={handleChange}
        getOptionLabel={(option) => (option ? option.name : "")}
        {...props}
        PopperComponent={(params) => (
          <Popper {...params} style={{ width: "80vw" }} />
        )}
        renderOption={(option) =>
          type === "country" ? (
            <React.Fragment>
              {countryToFlag(option.code)} {" " + option.name}
            </React.Fragment>
          ) : (
            option.name
          )
        }
        renderInput={(params) => (
          <TextField {...params} {...props} variant="outlined" />
        )}
      />
    </div>
  );
}
