import React, { useState, useEffect } from "react";

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import { useInView } from "react-intersection-observer";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  Badge,
  DialogTitle,
  IconButton,
  Typography,
  useScrollTrigger,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { LOG_TYPE_ICON } from "utils/constants";
import SearchFilter from "./SearchFilter";
import { DFCardSkeleton } from "./DFCustoms";
import { patientStatus } from "utils/ArrayUtils";
import LogDetails from "./LogDetails";
import useModal from "hooks/useModal";

const initialState = {
  patientTypeFilter: "",
  sortFilter: "-1",
  logTypeFilter: "",
  taggingFilter: "",
  callStatusFilter: "",
  sourceFilter: "",
  dateRange: [
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ],
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "fixed",
    top: 0,
    left: "auto",
    right: 0,
    minHeight: 64,
    width: "100%",
  },
  toolbar: theme.mixins.toolbar,
  secondaryStyle: {
    display: "grid",
  },
  clinicName: {
    textTransform: "capitalize",
    display: "flex",
    alignItems: "inherit",
  },
  onHoverStrong: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  noWrapTypo: {
    whiteSpace: "unset",
    textOverflow: "unset",
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
  notifHover: {
    display: "flex",
    minWidth: "80vw",
    gap: 5,
    borderBottom: `${theme.palette.divider} 1px solid`,
    padding: "10px 16px",
    cursor: "pointer",
    "&:hover": {
      filter: "brightness(0.85)",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Notifications({
  user,
  notifications,
  open,
  close,
  fetchNextPage,
  isFetchingNextPage,
  handleUpdateNotif,
}) {
  const classes = useStyles();

  const handleClose = () => {
    close();
  };

  const { ref, inView } = useInView();
  const trigger = useScrollTrigger();
  let filterPosition = "sticky";
  const [filters, setFilters] = useState(initialState);
  const [search, setSearch] = useState("");
  const listFilters = ["Patient Type", "Sort Conversation", "Date Range"];
  const [filtersPresent, setFiltersPresent] = useState(false);
  // const [openDialog, setOpenDialog] = useState(false);
  const [isOpen, closeModal, openModal] = useModal("log_details_notif_modal");

  const [singleData, setSingleData] = useState({});

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  const openLogDetails = (val) => {
    handleUpdateNotif(val?.id);
    setSingleData(val);
    openModal();
  };
  const handleSearch = (e) => {
    setSearch(e);
  };
  const handleApplyFilter = (e) => {
    setFilters(e);
  };
  return (
    <
      // style={
      //   filterPosition
      //     ? { position: "relative", minHeight: "100vh" }
      //     : {
      //         position: "relative",
      //         paddingTop: 56,
      //         minHeight: "100vh",
      //       }
      // }
    >
      <Dialog
        fullScreen
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          style={{
            position: "fixed",
            width: "100%",
            // textTransform: "capitalize",
            backgroundColor: "rgb(0, 172, 140)",
            color: "white",
            zIndex: 100,
            boxShadow: "0px 2px 4px -1px rgba(0, 0, 0, 0.3)",
            padding: "0",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0.2rem 0.2rem 0.2rem 1rem",
            }}
          >
            <Typography>NOTIFICATIONS</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon style={{ color: "white" }} />
            </IconButton>
            {/* <IconButton
              onClick={handleClose}
              style={{ backgroundColor: "rgb(55, 55, 55)", padding: "2px" }}
            >
              <CloseIcon
                style={{ fontSize: "1.2rem", color: "rgb(155, 155, 155)" }}
              />
            </IconButton> */}
          </div>
          <div style={{ color: "gray" }}>
            <SearchFilter
              position={filterPosition}
              trigger={filterPosition ? false : trigger}
              searchBy={"name"}
              filters={listFilters}
              initialState={initialState}
              handleSearch={handleSearch}
              applyFilter={handleApplyFilter}
              filtersPresent={filtersPresent}
              setFiltersPresent={setFiltersPresent}
            />
          </div>
        </DialogTitle>
        <div style={{ paddingTop: filtersPresent ? 132 : 112 }}>
          {notifications?.map((page) =>
            page?.data?.map((val, idx) => (
              <div
                onClick={() => openLogDetails(val)}
                key={val.id}
                className={classes.notifHover}
                style={{
                  backgroundColor: `${patientStatus(
                    val.patient_status,
                    "name",
                    "light_color"
                  )}`,
                  borderBottom: `1px solid ${patientStatus(
                    val.patient_status,
                    "name",
                    "light_color"
                  )}`,
                }}
                ref={page?.data?.length === idx + 1 ? ref : null}
              >
                <div style={{ padding: "4px 8px 0 0" }}>
                  <Badge
                    overlap="rectangular"
                    variant="dot"
                    anchorOrigin={{ vertical: "top", horizontal: "left" }}
                    invisible={val?.seen_at === null ? false : true}
                    color="secondary"
                  >
                    {LOG_TYPE_ICON[val?.type]}
                  </Badge>
                </div>
                <div style={{ flexGrow: 1 }}>
                  <Typography variant="subtitle1">
                    <b>{val.patient_name}</b>
                  </Typography>
                  <Typography variant="subtitle2">
                    {val.others.log_status}
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    component="span"
                  >
                    {val.created_at + " ago"}
                  </Typography>
                </div>
              </div>
            ))
          )}
          {isFetchingNextPage && (
            <DFCardSkeleton number_of_items={1} type="notification" />
          )}
        </div>
      </Dialog>
      <LogDetails
        user={user}
        open={isOpen}
        singleData={singleData}
        close={closeModal}
        isIndependent
      />
    </>
  );
}

export default Notifications;
