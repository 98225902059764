import { TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useRef } from "react";

export default function DFTimePicker({
  beginLimit,
  endLimit,
  step,
  formik,
  name,
  gutterBottom,
  label,
  placeholder,
  required,
}) {
  function tConv24(time24) {
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h = H % 12 || 12;
    h = h < 10 ? "0" + h : h; // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
  }
  const ref = useRef();

  const convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(" ");

    let [hours, minutes] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }
    if (hours.length === 1) hours = "0" + hours;

    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  };

  const handleChange = (e) => {
    formik.setFieldValue(name, tConv24(e.target.value));
  };

  return (
    <div style={gutterBottom ? { marginBottom: 10 } : {}}>
      <Typography variant="caption" color="textSecondary">
        {label}
        {required ? "*" : ""}
      </Typography>

      <TextField
        name={name}
        type="time"
        defaultValue={
          formik.values[name] && formik.values[name] !== ""
            ? convertTime12to24(formik.values[name])
            : "07:30"
        }
        ref={ref}
        size="small"
        fullWidth
        variant="outlined"
        formik={formik}
        onChange={handleChange}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          onClick: (e) => console.log(e.currentTarget.showPicker()),
        }}
      />
    </div>
  );
}
