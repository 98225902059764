import { Fab } from "@material-ui/core";
import React from "react";

function CallPopupFab({ children, onClick, ...props }) {
  return (
    <Fab
      {...props}
      style={{
        boxShadow: "none",
        padding: 35,
        backgroundColor: "#fff",
        border: "1px solid #373737",
      }}
      onClick={onClick}
    >
      {children}
    </Fab>
  );
}

export default CallPopupFab;
