import {
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";

function ShowedUpDetails({ handleChange, payload }) {
  const onChangeShowedUp = handleChange("showed_up");
  const onChangeShowedUpReason = handleChange("showed_up_reason");

  return (
    <div style={{ marginBottom: 20 }}>
      <Divider style={{ marginBottom: 20 }} />
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Showed Up
      </Typography>

      <RadioGroup
        style={{ marginBottom: 10 }}
        row
        name="showed_up"
        onChange={(e) => {
          onChangeShowedUp(e.currentTarget.value === "Yes" ? true : false);
        }}
        value={payload.showed_up === true ? "Yes" : "No"}
      >
        {[
          { value: "Yes", name: "Yes" },
          { value: "No", name: "No" },
        ].map((option, index) => (
          <FormControlLabel
            //   disabled={isLoading}
            key={index}
            value={option.value}
            control={<Radio color="primary" />}
            label={option.name}
          />
        ))}
      </RadioGroup>

      {(payload.showed_up === false || payload.showed_up === "No") && (
        <Typography variant="body2" color="textSecondary" gutterBottom>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Reason for not showing up:
          </Typography>
          <TextField
            name="note"
            multiline
            minRows={4}
            fullWidth
            placeholder="Add notes"
            onChange={(e) => {
              // console.log(e.target.value);
              onChangeShowedUpReason(e.target.value);
            }}
            value={payload.showed_up_reason}
            variant="outlined"
            size="small"
            // disabled={isLoading}
          />
        </Typography>
      )}
    </div>
  );
}

export default ShowedUpDetails;
