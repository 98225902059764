import {
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import React from "react";

function BookingStatusRadioGroup({ value, onChange, isLoading }) {
  return (
    <>
      <Divider style={{ marginBottom: 20 }} />
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Booking Status:
      </Typography>
      <RadioGroup
        row
        style={{ marginBottom: 10 }}
        name="booking"
        value={value}
        onChange={onChange}
      >
        <FormControlLabel
          disabled={isLoading}
          value="true"
          control={<Radio color="primary" />}
          label="Booked"
        />
        <FormControlLabel
          disabled={isLoading}
          value="false"
          control={<Radio color="primary" />}
          label="Not Booked"
        />
        <FormControlLabel
          disabled={isLoading}
          value={"NA"}
          control={<Radio color="primary" />}
          label={"NA"}
        />
      </RadioGroup>
    </>
  );
}

export default BookingStatusRadioGroup;
