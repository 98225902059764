import {
  Button,
  DialogContent,
  DialogTitle,
  Drawer,
  makeStyles,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useFormik } from "formik";
import useCallFeature from "hooks/context-hooks/useCallFeature";
import { useContext } from "react";
import * as Yup from "yup";
import "yup-phone-lite";
import { SnackbarContext } from "App";
import DFAutoCompleteLocation from "components/DFCustomFormFields/DFAutoCompleteLocation";
import DFCheckbox from "components/DFCustomFormFields/DFCheckbox";
import DFPhoneNumberField from "components/DFCustomFormFields/DFPhoneNumberField";
import DFSelectField from "components/DFCustomFormFields/DFSelectField";
import DFTextField from "components/DFCustomFormFields/DFTextField";
import DFDivider from "components/DFCustoms/DFDivider";
import DFIconButton from "components/DFCustoms/DFIconButton";
import { DFDownArrowButton } from "components/DFIcons";
import { DFPhoneNumber } from "utils/ArrayUtils";
import { PATIENT_STATUS } from "utils/constants";
import { syncOneContact } from "utils/db";
import countries from "utils/country_list.json";
import states from "utils/states";
import useContactService from "hooks/api-hooks/useContactService";
import useCommunication from "hooks/context-hooks/useCommunication";

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: 1000,
  },
  noWrapTypo: {
    whiteSpace: "unset",
    textOverflow: "unset",
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
  drawerPaper: {
    width: "100%",
    height: "90vh",
    borderRadius: "15px 15px 0px 0px",
  },
}));
const validationSchema = Yup.object({
  first_name: Yup.string().required("First name is required."),
  email: Yup.string("Enter your email").email("Enter a valid email"),
  phone1: Yup.string().required(),
});
const initialVal = {
  first_name: "",
  last_name: "",
  phone1: "1",
  phone1_is_textable: "",
  email: "",
  country_id: "",
  state_id: "",
  postal_code: "",
  city: "",
  patient_status: null,
  source_id: "",
  medium_id: "",
  contact_type: "",
};

export default function UpsertContact({
  isOpen,
  callBackAfterUpsert,
  close,
  data,
  user,
  isLoading,
  setIsLoading,
  countryCode,
  setCountryCode,
  setUpsertContactData,
}) {
  const classes = useStyles();
  const { addPatient, updatePatientInfo } = useContactService();
  const { contactTypes } = useCallFeature();
  const { refetch: refetchCommunications } = useCommunication();

  const { setSnack } = useContext(SnackbarContext);

  const formik = useFormik({
    initialValues: data || initialVal,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const newData = {
        first_name: values.first_name,
        last_name: values.last_name,
        phone1: new DFPhoneNumber(values.phone1).appendCountryCode(countryCode),
        email: values.email,
        address_attributes: {
          country_id: values.country_id,
          state_id: values.state_id,
          postal_code: values.postal_code,
          city: values.city,
        },
        patient_status: values.patient_status,
        phone1_is_textable: values.phone1_is_textable,
        medium_id: values.medium_id,
        source_id: values.source_id,
        contact_type: values.contact_type,
      };
      if (data.id) {
        console.log("update");
        handleUpdate(newData);
      } else {
        console.log("add");
        handleAddPatient(newData);
      }
    },
  });
  const handleClose = () => {
    formik.resetForm();
    setUpsertContactData(null);
    close();
  };

  const handleAddPatient = async (values) => {
    try {
      if (!isLoading) setIsLoading(true);
      const response = await addPatient(values);
      syncOneContact(
        response.data.data.id,
        response.data.data.office.id,
        user.token
      );

      setIsLoading(false);
      setSnack({
        open: true,
        severity: "success",
        message: "Patient created successfully!",
      });
      refetchCommunications();
      handleClose();
    } catch (err) {
      setIsLoading(false);
      setSnack({
        open: true,
        severity: "error",
        message: `Error! ${err}`,
      });
      console.log(err);
    }
  };

  const handleUpdate = async (values) => {
    try {
      if (!isLoading) setIsLoading(true);

      await updatePatientInfo(data.id, values);
      setIsLoading(false);
      setSnack({
        open: true,
        severity: "success",
        message: "Patient updated successfully!",
      });
      syncOneContact(data.id, data.officeID, user.token);
      refetchCommunications();
      handleClose();
      if (callBackAfterUpsert) {
        callBackAfterUpsert(values);
      }
    } catch (err) {
      setIsLoading(false);
      setSnack({
        open: true,
        severity: "error",
        message: `Error! ${err}`,
      });
      console.log(err);
    }
  };

  const newState = states?.filter((val) => {
    if (val.country_code === formik.values.country_id) {
      return val;
    }
  });

  const formProps = {
    formik: formik,
    fullWidth: true,
    gutterBottom: true,
    disabled: isLoading,
    size: "small",
  };

  return (
    <Drawer
      variant="temporary"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="bottom"
      open={isOpen}
      onClose={handleClose}
    >
      <DialogTitle
        style={{
          position: "relative",
          textTransform: "capitalize",
        }}
      >
        <div style={{ display: "flex" }}>
          <svg
            width="20"
            height="23"
            viewBox="0 0 20 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.1 10.8C11.0691 10.8 12.0164 10.5126 12.8222 9.97421C13.6281 9.43579 14.2561 8.67051 14.627 7.77515C14.9978 6.8798 15.0949 5.89457 14.9058 4.94406C14.7167 3.99355 14.2501 3.12046 13.5648 2.43518C12.8795 1.7499 12.0064 1.28322 11.0559 1.09415C10.1054 0.905087 9.12016 1.00212 8.2248 1.37299C7.32945 1.74386 6.56417 2.37191 6.02575 3.17771C5.48733 3.98351 5.19995 4.93088 5.19995 5.9C5.19995 7.19957 5.7162 8.4459 6.63513 9.36483C7.55406 10.2838 8.80039 10.8 10.1 10.8ZM10.1 2.4C10.7922 2.4 11.4689 2.60527 12.0445 2.98986C12.62 3.37444 13.0686 3.92107 13.3335 4.56061C13.5984 5.20015 13.6678 5.90389 13.5327 6.58282C13.3977 7.26175 13.0643 7.8854 12.5748 8.37488C12.0853 8.86436 11.4617 9.19771 10.7828 9.33276C10.1038 9.4678 9.4001 9.39849 8.76056 9.13358C8.12102 8.86868 7.57439 8.42007 7.18981 7.8445C6.80522 7.26893 6.59995 6.59224 6.59995 5.9C6.59995 4.97175 6.9687 4.08151 7.62508 3.42513C8.28146 2.76875 9.1717 2.4 10.1 2.4Z"
              fill="#0EA782"
              stroke="#0EA782"
              stroke-width="0.5"
            />
            <path
              d="M18.829 15.9591C17.7064 14.7726 16.3537 13.8275 14.8534 13.1817C13.3531 12.5358 11.7369 12.2026 10.1035 12.2026C8.47012 12.2026 6.85389 12.5358 5.35363 13.1817C3.85336 13.8275 2.50059 14.7726 1.378 15.9591C1.13462 16.2191 0.999449 16.562 1 16.9181V20.6001C1 20.9714 1.1475 21.3275 1.41005 21.5901C1.6726 21.8526 2.0287 22.0001 2.4 22.0001H17.8C18.1713 22.0001 18.5274 21.8526 18.79 21.5901C19.0525 21.3275 19.2 20.9714 19.2 20.6001V16.9181C19.2025 16.563 19.0698 16.2201 18.829 15.9591V15.9591ZM17.8 20.6001H2.4V16.9111C3.39206 15.8665 4.58631 15.0346 5.91005 14.4662C7.2338 13.8978 8.65937 13.6046 10.1 13.6046C11.5406 13.6046 12.9662 13.8978 14.29 14.4662C15.6137 15.0346 16.808 15.8665 17.8 16.9111V20.6001Z"
              fill="#0EA782"
              stroke="#0EA782"
              stroke-width="0.5"
            />
          </svg>
          <p
            style={{
              marginTop: 3,
              marginLeft: 5,
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "19px",
              lineHeight: "22px",
              color: "#373737",
            }}
          >
            Contact Information
          </p>
        </div>

        <DFIconButton
          style={{ position: "absolute", top: 8, bottom: 8, right: 20 }}
          onClick={handleClose}
          edge="end"
          icon={<DFDownArrowButton />}
        />
      </DialogTitle>
      <DFDivider />
      <DialogContent>
        <form onSubmit={formik.handleSubmit} style={{ paddingBottom: 60 }}>
          <DFTextField
            label="First Name"
            name="first_name"
            placeholder="Enter first name"
            required
            {...formProps}
          />
          <DFTextField
            label="Last Name"
            name="last_name"
            placeholder="Enter last name"
            {...formProps}
          />
          <DFTextField
            label="Email"
            name="email"
            placeholder="Enter email address"
            {...formProps}
          />
          <DFPhoneNumberField
            countryCode={countryCode}
            setCountryCode={setCountryCode}
            label="Phone Number"
            name="phone1"
            placeholder="Enter phone number"
            required
            {...formProps}
          />
          <DFCheckbox
            label="Is phone number textable?"
            name="phone1_is_textable"
            formik={formik}
            gutterBottom
            disabled={isLoading}
          />

          {/* <DFSelectField
            label="Medium"
            name="medium_id"
            options={mediumList}
            placeholder="Select medium"
            {...formProps}
          /> */}

          {/* <DFSelectField
            label="Source"
            name="source_id"
            options={sourceList}
            placeholder="Select source"
            {...formProps}
          /> */}

          <DFSelectField
            label="Contact Type"
            name="contact_type"
            options={contactTypes}
            placeholder="Select contact type"
            {...formProps}
          />

          <DFAutoCompleteLocation
            label="Country"
            type="country"
            options={countries || []}
            name="country_id"
            placeholder="Select country"
            {...formProps}
          />

          <DFAutoCompleteLocation
            label="Povince"
            type="state"
            options={newState || []}
            name="state_id"
            placeholder="Select province"
            {...formProps}
          />
          <DFTextField
            label="City"
            name="city"
            placeholder="Enter city"
            {...formProps}
          />
          <DFSelectField
            label="Patient Status"
            name="patient_status"
            options={PATIENT_STATUS}
            placeholder="Select status"
            {...formProps}
          />

          <div
            style={{
              padding: "0px 24px 24px 24px",
              position: "absolute",
              bottom: 0,
              right: 0,
              left: 0,
              background: "#fff",
            }}
          >
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disableElevation={true}
              disabled={isLoading ? true : false}
              fullWidth
            >
              <span>SAVE</span>
              {isLoading && (
                <CircularProgress
                  style={{ marginLeft: 5 }}
                  color="inherit"
                  size={18}
                />
              )}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Drawer>

    // </Dialog>
  );
}
