import React from "react";

function DFDivider() {
  return (
    <hr
      style={{
        borderBottom: "1px  #373737",
        opacity: 0.3,
        width: "calc(100vw - 32px)",
        transform: "translateX(16px)",
      }}
    ></hr>
  );
}

export default DFDivider;
