import { useState } from "react";
import SendCovidForm from "components/SendCovidForm";
import useModal from "./useModal";

function useSendCovidForm(user) {
  const [isOpen, close, openModal] = useModal("send_covid_form");

  const [covidFormData, setCovidFormData] = useState({});

  const openCovidForm = (data) => {
    setCovidFormData(data);
    openModal();
  };

  const renderCovidForm = () => {
    return (
      <SendCovidForm //
        user={user}
        patientData={covidFormData}
        open={isOpen}
        close={close}
      />
    );
  };
  return [renderCovidForm, { openCovidForm }];
}

export default useSendCovidForm;
