import {
  Avatar,
  Button,
  Container,
  Fab,
  Slide,
  TextField,
  Typography,
} from "@material-ui/core";
import CallEndOutlinedIcon from "@material-ui/icons/CallEndOutlined";
import CallOutlinedIcon from "@material-ui/icons/CallOutlined";
import MessageOutlinedIcon from "@material-ui/icons/MessageOutlined";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import useTheme from "@material-ui/core/styles/useTheme";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { SnackbarContext } from "App";
import { callPopUpStyles } from "assets/materialStyles";
import PatientStatusIcons from "components/DFIcons/PatientStatusIcons";
import useLogHistory from "hooks/useLogHistory";
import useSendCovidForm from "hooks/useSendCovidForm";
import useUpsertAppointment from "hooks/useUpsertAppointment";
import useUpsertContact from "hooks/useUpsertContact";
import callingService from "services/callingService";
import messageService from "services/messageService";
import {
  capitalizeWords,
  formatDisplayedNumber,
  patientStatus,
} from "utils/ArrayUtils";
import {
  DFBookAppointment,
  DFChatHistory,
  DFEndCall,
  DFMute,
  DFSendCovidForm,
} from "./DFIcons";
import DFTagCall from "./DFIcons/CallingFeatureIcons/DFTagCall";
import DFUnmute from "./DFIcons/CallingFeatureIcons/DFUnmute";
import LogDetails from "./LogDetails";
import CallPopupFab from "./DFCustoms/CallPopupFab";
import DFIconButton from "./DFCustoms/DFIconButton";
import useModal from "hooks/useModal";
const textStyle = {
  transform: "translateY(45px)",
  fontSize: 12,
  whiteSpace: "nowrap",
  textTransform: "none",
  color: "black",
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const customFab = {
  boxShadow: "none",
  padding: 35,
};

export default function CallPopup({
  openCall,
  closeModal,
  setReturnCall,
  callData,
  setCallData,
  bandwidthRtc,
  ring,
  remoteStream,
  callOnGoing,
  user,
}) {
  const classes = callPopUpStyles();
  const theme = useTheme();
  // const [callData, setCallData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [muteMic, setMuteMic] = useState(false);
  const [textMessage, setTextMessage] = useState("");
  const [messageError] = useState({
    error: false,
    message: "",
  });
  const [rejectMessage, setRejectMessage] = useState(false);
  //
  const [timer, setTimer] = useState("");
  const increment = useRef(null);
  const [isOpen, closeLogDetails, openLogDetails] =
    useModal("call_log_details");

  const [singleData, setSingleData] = useState({});
  const { setSnack } = useContext(SnackbarContext);

  const [renderLogHistory, { openLogHistory }] = useLogHistory(user);
  const [renderCovidForm, { openCovidForm }] = useSendCovidForm(user);
  const [renderAppointmentForm, { openAppointmentForm }] =
    useUpsertAppointment(user);

  const [renderContactForm, { upsertContact }] = useUpsertContact(
    user,
    (value) => {
      const patientData = {
        ...callData?.patient,
        patient_status: value.patient_status,
        first_name: value.first_name,
        last_name: value.last_name,
      };

      setCallData({
        ...callData,
        fullName: `${value.first_name} ${value.last_name}`,
        patient: patientData,
      });
    }
  );

  // const audioTrack = remoteStream?.mediaStream
  //   ?.getTracks()
  //   .find((track) => track.kind === "audio");

  const endCall = () => {
    closeModal();
    setReturnCall(false);
    ring.stop();
    bandwidthRtc.unpublish();
  };
  const handleRejectCall = async () => {
    ring.stop();
    await callingService
      .hangUpCall(user, callData?.call_sid)
      .then((res) => {
        endCall();
        // console.log("then", res);
      })
      .catch((err) => {
        endCall();
        console.log(err);
      });

    await callingService
      .hangUpCallOutgoing(callData?.call_sid)
      .then((res) => {
        endCall();
        // console.log("outgoing", res);
      })
      .catch((err) => {
        endCall();
        // console.log(err);
      });
  };
  const handleBackIfMissed = () => {
    endCall();
    closeModal();
    setReturnCall(false);
    ring.stop();
    bandwidthRtc.unpublish();
  };

  const handleMute = async () => {
    await bandwidthRtc.setMicEnabled(muteMic);
    setMuteMic(!muteMic);
  };

  // useEffect(() => {
  //   if (data?.missed_call) {
  //     ring.stop();
  //     bandwidthRtc.unpublish();
  //   }
  //   setCallData(data);
  // }, [data]);

  useEffect(() => {
    if (!!remoteStream) {
      var startTimestamp = moment().startOf("day");
      increment.current = setInterval(function () {
        startTimestamp.add(1, "seconds");
        setTimer(startTimestamp.format("mm:ss"));
      }, 1000);
    } else {
      setTimer("");
      clearInterval(increment.current);
    }
    return () => {
      setTimer("");
      clearInterval(increment.current);
    };
  }, [remoteStream]);
  //
  const connectToSession = () => {
    ring.stop();
    console.log("answering call with call sid: ", callData?.call_sid);
    callingService.answerCall(user, callData?.call_sid).then((resp) => {
      console.log(resp);
      if (resp?.data?.token) {
        console.log("this is the token", resp?.data?.token);
        // Connect to Bandwidth WebRTC
        bandwidthRtc
          .connect({ deviceToken: resp?.data?.token })
          .then(async () => {
            console.log("connected to bandwidth call webrtc!");
            bandwidthRtc.setMicEnabled({ enabled: false });
            // Publish the browser's microphone
          });
      } else {
        //not token is returned upon calling the api
        console.log("no token returned");
        // handleRejectCall()
        // setReturnCall(false);
        // bandwidthRtc.unpublish();
        // setCallData({ ...callData, destination_number_issue: true, call_in_progress: true });
      }
    });
  };

  const sendTextMessage = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      await messageService.sendSms(
        {
          id: callData?.patientID,
          office: user.current_office?.id,
          token: user.token,
        },
        {
          "api-token": user?.token,
        },
        { message: textMessage }
      );
      setIsLoading(false);
      setTextMessage("");
      setRejectMessage(false);
    } catch (err) {
      setTextMessage("");
      // console.log(err);
      setIsLoading(false);
      setRejectMessage(false);
      if (err.response) {
        // client received an error response (5xx, 4xx)
        console.log(
          "client received an error response (5xx, 4xx)",
          err.response
        );
        setSnack({
          open: true,
          severity: "error",
          message: `Error! ${err.response.data.message}`,
        });
      } else if (err.request) {
        console.log("client never received a response, or request never left");
        // client never received a response, or request never left
      } else {
        console.log("Other errors");
        // anything else
      }
    }
  };

  const viewChatHistory = () => {
    openLogHistory(callData?.patient);
  };

  const bookAppointment = async (e) => {
    const appointmentData = {
      id: callData?.patient?.patientID,
      appID: "",
      date: "",
      note: "",
    };
    openAppointmentForm(appointmentData);
  };

  const sendCovidForm = () => {
    openCovidForm({
      id: callData?.patient?.patientID,
    });
  };

  const editPatient = () => {
    upsertContact({
      officeID: callData?.office.id,
      id: callData?.patient?.patientID,
    });
  };

  const handleOpenDialog = async () => {
    // console.log(callData);
    const singleCall = {
      office_id: callData.office.id,
      others: {
        call_log_id: callData.call_log_id,
      },
      type: callData?.callType === "outgoing" ? 5 : 10,
      ongoing_call: true,
    };
    openLogDetails();
    setSingleData(singleCall);
  };

  const displayCaller = () => {
    if (callData?.call_in_progress === true) {
      let atleastOneAlpha;
      let answeredBy = "";
      answeredBy = callData?.answered_by?.toString();
      atleastOneAlpha = answeredBy.match(".*[a-zA-Z]+.*");
      // console.log(answeredBy);

      if (!atleastOneAlpha) {
        answeredBy = formatDisplayedNumber(callData?.answered_by, false);
      }
      return `Answered by: ${answeredBy}`;
    } else {
      if (callData?.fullName) {
        return capitalizeWords(callData?.fullName, " ");
      } else {
        return formatDisplayedNumber(callData?.from, false);
      }
    }
  };

  const callPopupDialogTitle = () => {
    if (callOnGoing) {
      return "Ongoing Call";
    } else {
      if (callData?.missed_call == null) {
        if (callData?.callType === "outgoing") {
          return "Outgoing Call";
        } else {
          return "Incoming Call";
        }
      } else if (callOnGoing === null || callOnGoing === false) {
        if (callData?.missed_call === false) {
          return " Calling...";
        } else {
          if (callData?.missed_call === true) {
            return "Missed Call";
          } else {
            if (callData?.call_in_progress === true) {
              return "Call In Progress";
            } else {
              return "Call Ended";
            }
          }
        }
      }
    }
  };

  const callPopupDescription = () => {
    if (!!remoteStream) {
      return "Connected";
    } else {
      if (callData?.missed_call) {
        return "Missed Call";
      } else {
        if (callOnGoing === false) {
          return "Call Ended";
        } else {
          if (callData?.call_in_progress === true) {
            return "Call In Progress";
          } else {
            if (callData?.callType === "outgoing") {
              return "Outgoing Call";
            } else {
              return "Incoming Call";
            }
          }
        }
      }
    }
  };

  const ringingOrTimer = () => {
    if (remoteStream) {
      return timer;
    } else {
      if (callData?.missed_call) {
        return callData?.error_message;
      } else {
        if (callOnGoing === false) {
          return "";
        } else {
          return "Ringing...";
        }
      }
    }
  };

  return (
    <>
      <Dialog
        fullScreen
        open={openCall}
        // open={true}
        disableEscapeKeyDown={true}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          disableTypography
          className={classes.callTitle}
          style={{
            backgroundColor: `${
              patientStatus(
                callData?.patient?.patient_status,
                "value",
                "main_color"
              ) || "#2A3346"
            }`,
          }}
        >
          <DFIconButton
            color="inherit"
            onClick={() =>
              callData?.missed_call ? handleBackIfMissed() : closeModal()
            }
            edge="end"
            icon={<ArrowBackIosIcon />}
          />
          {/* <PatientStatusIcons status={patientStatus(1, "value", "name")} /> */}
          <Typography noWrap variant="h6">
            {callPopupDialogTitle()}
          </Typography>
        </DialogTitle>
        <Container
          style={{
            height: "100%",
            display: "grid",
            gridGap: 20,
            placeItems: "center",
          }}
        >
          <div style={{ display: "grid", placeItems: "center", gridGap: 20 }}>
            <div>
              <Typography align="center" variant="h5">
                {callPopupDescription()}
              </Typography>
              {!callData?.call_in_progress && (
                <Typography
                  align="center"
                  paragraph
                  style={{ maxWidth: "80vw", overflowWrap: "break-word" }}
                >
                  {ringingOrTimer()}
                </Typography>
              )}
            </div>
            <div
              style={{
                position: "relative",
                display: "grid",
                placeItems: "center",
              }}
            >
              <Avatar
                src={user}
                className={
                  remoteStream
                    ? `${classes.largeAvatar} heartbeat`
                    : classes.largeAvatar
                }
              />
              {callOnGoing == null ? (
                <div
                  style={{
                    position: "absolute",
                    backgroundColor: "black",
                    width: theme.spacing(18),
                    height: theme.spacing(18),
                    borderRadius: "50%",
                    zIndex: 1,
                  }}
                  className="ping"
                />
              ) : null}
            </div>
            <div>
              <>
                <Typography
                  variant="subtitle1"
                  style={{ fontSize: 21 }}
                  className={classes.hoverName}
                  align="center"
                  onClick={() => editPatient()}
                >
                  <b>
                    {displayCaller()}
                    <PatientStatusIcons
                      status={patientStatus(
                        callData?.patient?.patient_status,
                        "value",
                        "name"
                      )}
                    />
                  </b>
                </Typography>
                <Typography variant="body2" align="center" gutterBottom>
                  {formatDisplayedNumber(callData?.from, false)}
                </Typography>
              </>
              {callData?.city && (
                <Typography
                  variant="caption"
                  component="p"
                  color="textSecondary"
                  align="center"
                  gutterBottom
                >
                  {`City: ${callData?.city}`}
                </Typography>
              )}
            </div>
          </div>

          {(() => {
            if (!callData?.call_in_progress) {
              if (!!remoteStream || callData?.callType === "outgoing") {
                return (
                  <div
                    style={{
                      display: "grid",
                      gap: 40,
                      gridTemplateColumns: "1fr 1fr 1fr",
                    }}
                  >
                    <CallPopupFab
                      onClick={() => bookAppointment(callData?.patient?.id)}
                      disabled={callData?.notExistingContact}
                    >
                      <DFBookAppointment />
                      <Typography style={textStyle}>
                        Book Appointment
                      </Typography>
                    </CallPopupFab>
                    <CallPopupFab
                      onClick={() => viewChatHistory()}
                      disabled={callData?.notExistingContact}
                    >
                      <DFChatHistory />
                      <Typography style={textStyle}>Chat History</Typography>
                    </CallPopupFab>
                    <CallPopupFab
                      onClick={() => sendCovidForm()}
                      disabled={callData?.notExistingContact}
                    >
                      <DFSendCovidForm fontSize="large" />
                      <Typography style={textStyle}>
                        Send Covid-19 Form
                      </Typography>
                    </CallPopupFab>
                    <div
                      style={{
                        gridColumn: "1 / span 3",
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <CallPopupFab
                        onClick={() => handleOpenDialog(callData)}
                        disabled={!remoteStream}
                      >
                        <DFTagCall fontSize="large" />
                        <Typography style={textStyle}>Tag Call</Typography>
                      </CallPopupFab>

                      <CallPopupFab
                        // onClick={() => setMuteAudio(!muteMic)}
                        onClick={() => handleMute()}
                      >
                        {/* {audioTrack?.enabled ? ( */}
                        {muteMic ? (
                          <DFMute fontSize="large" />
                        ) : (
                          <DFUnmute fontSize="large" />
                        )}
                        <Typography style={textStyle}>
                          {muteMic ? "Unmute" : "Mute"}
                        </Typography>
                      </CallPopupFab>
                    </div>
                    <Fab
                      style={{ ...customFab, gridColumn: 2 }}
                      onClick={handleRejectCall}
                      color="secondary"
                    >
                      <DFEndCall fontSize="large" />
                      <Typography style={textStyle}>End Call</Typography>
                    </Fab>
                  </div>
                );
              } else {
                return (
                  <div style={{ display: "flex", gap: 30 }}>
                    {(() => {
                      if (callData?.missed_call || callOnGoing === false) {
                        return (
                          <CallPopupFab onClick={() => setRejectMessage(true)}>
                            <MessageOutlinedIcon fontSize="large" />
                          </CallPopupFab>
                        );
                      } else {
                        return (
                          <>
                            <Fab
                              onClick={handleRejectCall}
                              style={customFab}
                              color="secondary"
                            >
                              <CallEndOutlinedIcon fontSize="large" />
                            </Fab>
                            <CallPopupFab
                              onClick={() => setRejectMessage(true)}
                            >
                              <MessageOutlinedIcon fontSize="large" />
                            </CallPopupFab>
                            <Fab
                              onClick={connectToSession}
                              style={customFab}
                              color="primary"
                            >
                              <CallOutlinedIcon fontSize="large" />
                            </Fab>
                          </>
                        );
                      }
                    })()}
                  </div>
                );
              }
            }
          })()}
        </Container>
      </Dialog>
      <Dialog
        TransitionComponent={Transition}
        fullWidth
        open={rejectMessage}
        onClose={() => setRejectMessage(false)}
        // disableEscapeKeyDown={true}
        aria-labelledby="form-dialog-title"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px 16px",
          }}
        >
          <Typography variant="subtitle1" style={{ flexGrow: 1, fontSize: 18 }}>
            Send Message
          </Typography>
          <DFIconButton
            edge="end"
            onClick={() => setRejectMessage(false)}
            icon={<CloseRoundedIcon />}
          />
        </div>
        <Container
          style={{
            height: "100%",
            display: "grid",
            gridGap: 20,
            placeItems: "center",
          }}
        >
          <TextField
            classes={{ root: classes.textFieldRoot }}
            placeholder="Enter message"
            multiline
            variant="outlined"
            fullWidth
            size="small"
            minRows={5}
            value={textMessage}
            onChange={(e) => setTextMessage(e.target.value)}
            autoFocus
            helperText={messageError.message}
            error={messageError.error}
            // disabled={isSendLoading ? true : false}
          />
          <Button
            fullWidth
            variant="contained"
            disableElevation
            disabled={!!isLoading}
            className={classes.SendMessageButton}
            color="primary"
            size="large"
            onClick={sendTextMessage}
          >
            Send
          </Button>
        </Container>
      </Dialog>
      {renderLogHistory()}
      {renderAppointmentForm()}
      {renderCovidForm()}
      {renderContactForm()}

      <LogDetails
        user={user}
        open={isOpen}
        singleData={singleData}
        setSingleData={setSingleData}
        // updateLogs={updateLogs}
        close={closeLogDetails}
        isIndependent
      />
    </>
  );
}
