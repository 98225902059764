import config, { ENVIRONMENT } from "config/config";
import Pubnub from "pubnub";
import { GET } from "utils/restUtils";
import useInterval from "./useInterval";
import { useState } from "react";

export default function usePubnubFeature(user) {
  const [state, setState] = useState(
    new Pubnub({
      publishKey:
        JSON.parse(localStorage.getItem("keys"))?.[ENVIRONMENT]?.publishKey ||
        "pub-c-d58459fa-e13a-49de-a6ec-c7bf52a996a1",
      subscribeKey:
        JSON.parse(localStorage.getItem("keys"))?.[ENVIRONMENT]?.subscribeKey ||
        "sub-c-026bdfbb-9a16-4891-8e82-cbed0a5ae261",
      uuid: user?.id,
    })
  );
  // const pubnub = new Pubnub({
  //   publishKey:
  //     JSON.parse(localStorage.getItem("keys"))?.[ENVIRONMENT]?.publishKey ||
  //     "pub-c-d58459fa-e13a-49de-a6ec-c7bf52a996a1",
  //   subscribeKey:
  //     JSON.parse(localStorage.getItem("keys"))?.[ENVIRONMENT]?.subscribeKey ||
  //     "sub-c-026bdfbb-9a16-4891-8e82-cbed0a5ae261",
  //   uuid: user?.id,
  // });

  const fetchKeys = () => {
    return GET(`${config.BASE_URL}/api/v1/pubnub_keys/show`, {
      headers: {
        "api-token": user?.token,
      },
    });
  };

  const checkKeys = async () => {
    try {
      const { data } = await fetchKeys();
      const newKeys = {
        publishKey: data?.data?.publish_key,
        subscribeKey: data?.data?.subscribe_key,
      };
      const currentKeys = JSON.parse(localStorage.getItem("keys")) || {};
      if (
        newKeys.publishKey !== currentKeys[ENVIRONMENT]?.publishKey &&
        newKeys.subscribeKey !== currentKeys[ENVIRONMENT]?.subscribeKey
      ) {
        localStorage.setItem(
          "keys",
          JSON.stringify({ ...currentKeys, [ENVIRONMENT]: newKeys })
        );
        console.log("pubnub keys updated with the following keys =>", newKeys);

        window.location.reload();
      }
    } catch (e) {}
  };
  useInterval(
    () => {
      checkKeys();
    },
    600000 //checks keys every 10 mins
  );

  return { pubnub: state, checkKeys };
}
