import { Paper, Typography } from "@material-ui/core";
import moment from "moment";

import PhoneMissedIcon from "@material-ui/icons/PhoneMissed";
import AudioPlayer from "./audioPlayer/AudioPlayer";

import useCallFeature from "hooks/context-hooks/useCallFeature";
import { INCOMING_CALL_LOGS_STATUS, LOG_TYPE_NAME_ICON } from "utils/constants";
function BubbleIncomingCall({
  handleOpenDialog,
  val,
  classes,
  theme,
  playingAudioRef,
}) {
  const { callerData } = useCallFeature();

  const displayText = () => {
    if (
      val.id === callerData?.call_log_id &&
      val?.dial_call_status === "in-progress"
    ) {
      return "Call in progress. Recording will be available soon.";
    } else {
      return "Missed Call";
    }
  };
  // console.log(val);
  return (
    <Paper
      onClick={() => handleOpenDialog(val)}
      elevation={0}
      className={classes.paperLeft}
      style={{
        padding: "15px 15px",
        width: "85%",
        display: "flex",
        gap: 10,
        backgroundColor: "#fff",
        alignItems: `${val.recording_sid ? "flex-start" : "center"}`,
      }}
    >
      {val.recording_sid || val?.call_status === "recordingAvailable" ? (
        <div style={{ marginTop: 10 }}>{LOG_TYPE_NAME_ICON[val?.type]}</div>
      ) : (
        <PhoneMissedIcon
          fontSize="small"
          style={{ color: theme.palette.secondary.main }}
        />
      )}
      <div style={{ width: "100%" }}>
        {val.recording_sid &&
        (val.dial_call_status === INCOMING_CALL_LOGS_STATUS.completed ||
          val?.call_status === "recordingAvailable") ? (
          <>
            <AudioPlayer
              src={val.recording_url}
              playingAudioRef={playingAudioRef}
            />

            <Typography
              variant="caption"
              component="div"
              style={{ fontSize: 10, width: "100%" }}
              color="textSecondary"
              align={"right"}
            >
              {moment(val.created_at).format("hh:mm A")}
            </Typography>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {displayText()}
            <Typography
              variant="caption"
              component="div"
              style={{ fontSize: 10 }}
              color="textSecondary"
              align={"right"}
            >
              {moment(val.created_at).format("hh:mm A")}
            </Typography>
          </div>
        )}
        {val.note && (
          <>
            <br />
            <Typography style={{ fontSize: "13px" }}>Note:</Typography>
            <Typography
              gutterBottom
              style={{
                fontSize: "13px",
                whiteSpace: "pre-wrap",
                display: "inline-block",
                wordBreak: "break-word",
              }}
            >
              {val.note}
            </Typography>
          </>
        )}
      </div>
    </Paper>
  );
}

export default BubbleIncomingCall;
