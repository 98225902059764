export function convertToKey(string) {
  return (string.charAt(0).toLowerCase() + string.slice(1) + "Name").replace(
    /\s+/g,
    ""
  );
}

export function camelize(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
}

export const pubnubCleanUp = (pubnub, listeners) => {
  // console.log("cleanup function called");
  pubnub.removeListener(listeners);
  pubnub.unsubscribeAll();
};

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export function convertMsToTime(milliseconds) {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  seconds = seconds % 60;
  minutes = minutes % 60;

  // 👇️ If you don't want to roll hours over, e.g. 24 to 00
  // 👇️ comment (or remove) the line below
  // commenting next line gets you `24:00:00` instead of `00:00:00`
  // or `36:15:31` instead of `12:15:31`, etc.
  hours = hours % 24;

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
    seconds
  )}`;
}

export function parseUrlParameters() {
  var hashString = window.location.hash?.substr(1);
  var params = {};

  if (hashString) {
    var paramPairs = hashString?.split("?")[1]?.split("&");

    for (var i = 0; i < paramPairs?.length; i++) {
      var paramPair = paramPairs[i]?.split("=");
      var paramName = decodeURIComponent(paramPair[0]);
      var paramValue = decodeURIComponent(paramPair[1] || "");

      if (params[paramName]) {
        if (Array.isArray(params[paramName])) {
          params[paramName]?.push(paramValue);
        } else {
          params[paramName] = [params[paramName], paramValue];
        }
      } else {
        params[paramName] = paramValue;
      }
    }
  }

  return params;
}
