import { useContext, useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Switch,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Skeleton from "@material-ui/lab/Skeleton";

import useCallFeature from "hooks/context-hooks/useCallFeature";
import { SnackbarContext } from "App";
import appointmentService from "services/appointmentService";
import { LANGUAGES } from "utils/constants";
import DFIconButton from "./DFCustoms/DFIconButton";

export default function SendCovidForm({ open, close, patientData, user }) {
  // const [patientList, setPatientList] = useState([]);
  const { patientList } = useCallFeature();

  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(null);
  const [lang, setLang] = useState("");
  const { setSnack } = useContext(SnackbarContext);
  const [state, setState] = useState({
    sms: false,
    email: false,
  });

  useEffect(() => {
    setOpenDialog(open);
    if (patientList?.length !== 0) {
      let patient = patientList?.filter((val) => patientData?.id === val.id);
      setValue(patient[0]);
    }
  }, [open]);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChangeLang = (event) => {
    setLang(event.target.value);
  };

  const handleClose = () => {
    setOpenDialog(false);
    close();
    setLang("");
    setState({ email: false, sms: false });
  };

  const handleSendCovidForm = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      await appointmentService.SendCovidForm(
        {
          token: user.token,
        },
        value.id,
        state,
        lang
      );
      setIsLoading(false);
      setSnack({
        open: true,
        severity: "success",
        message: "COVID-19 sent successfully!",
      });
      setState({ email: false, sms: false });
      setLang("");
      handleClose();
    } catch (err) {
      setIsLoading(false);
      setSnack({
        open: true,
        severity: "error",
        message: `Error! ${err}`,
      });
      console.log(err);
    }
  };

  return (
    <Dialog
      fullWidth
      scroll="paper"
      open={openDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        style={{ position: "relative", textTransform: "capitalize" }}
      >
        Send COVID-19 Form
        <DFIconButton
          style={{ position: "absolute", top: 8, bottom: 8, right: 20 }}
          onClick={handleClose}
          edge="end"
          icon={<CloseIcon />}
        />
      </DialogTitle>
      <DialogContent dividers={true}>
        <Typography variant="caption" color="textSecondary">
          Patient *
        </Typography>
        {isLoading ? (
          <Skeleton
            animation="wave"
            height={45}
            style={{ borderRadius: 10 }}
            variant="rect"
          />
        ) : (
          <Autocomplete
            value={value}
            disabled={patientData?.id ? true : false}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            id="controllable-states-demo"
            options={patientList}
            getOptionLabel={(option) => option.full_name}
            renderOption={(option) => (
              <ListItem dense={true}>
                <ListItemText
                  primary={option.full_name}
                  secondary={option.phone}
                />
              </ListItem>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Search patient's name"
                fullWidth
                variant="outlined"
              />
            )}
          />
        )}

        <FormControl
          fullWidth
          size="small"
          variant="outlined"
          style={{ marginTop: 20 }}
        >
          <Typography variant="caption">COVID Form Language</Typography>
          <Select displayEmpty value={lang} onChange={handleChangeLang}>
            <MenuItem value="">
              <em>Select a language</em>
            </MenuItem>
            {LANGUAGES.map((val, idx) => (
              <MenuItem key={val.value} value={val.value}>
                {val.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl component="fieldset" style={{ marginTop: 20 }}>
          <Typography variant="caption">Send COVID-19 Form via:</Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={state.email}
                  onChange={handleChange}
                  name="email"
                />
              }
              label="Email"
            />
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={state.sms}
                  onChange={handleChange}
                  name="sms"
                />
              }
              label="SMS"
            />
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions style={{ padding: "16px 24px" }}>
        <Button
          fullWidth
          disabled={isLoading && true}
          color="primary"
          variant="contained"
          startIcon={
            isLoading && (
              <CircularProgress
                style={{ marginLeft: 5 }}
                color="inherit"
                size={18}
              />
            )
          }
          disableElevation={true}
          onClick={handleSendCovidForm}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
}
