import React from "react";

import DoneIcon from "@material-ui/icons/Done";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";

export default function MessageStatusIcon({ status, height = null }) {
  let icon;
  let iconHeight = height ? height : "14px";
  switch (status) {
    case "delivered":
      icon = <DoneAllIcon style={{ height: iconHeight, color: "green" }} />;
      break;
    case "sent":
      icon = <DoneIcon style={{ height: iconHeight }} />;
      break;
    case "failed":
      icon = <ErrorOutlineIcon style={{ height: iconHeight, color: "red" }} />;
      break;
    case "queue":
      icon = <QueryBuilderIcon style={{ height: iconHeight, color: "red" }} />;
      break;
    case "received":
      icon = null;
      break;
    default:
      icon = null;
      break;
  }
  return <>{icon}</>;
}
