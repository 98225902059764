import React, { useState } from "react";
import { DFDialog } from "./DFCustoms";
import {
  Box,
  Button,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import useModal from "hooks/useModal";

const typeList = ["Bug/Issue", "Suggestion", "Other"];
function ContactSupport({ classes, user }) {
  const [isOpen, closeModal, openModal] = useModal("contact_support");

  const [type, setType] = useState("Bug");
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);

  const close = () => {
    setStatus(null);
    closeModal();
  };
  const handleSendEmail = async (e) => {
    const message = document.getElementById("contact-support-message").value;
    setLoading(true);
    await window.Email.send({
      Host: "smtp.elasticemail.com",
      Username: "appdev@dentistfind.com",
      Password: "51098E34AAF3B62926DD905BEC6AF0B2D69F",
      To: "support@dentistfind.com",
      From: "appdev@dentistfind.com",
      Subject: `User Feedback Email From ${user.current_office.name}`,
      Body: `
      <div>
      <h2 style='margin:0px'>User Feedback Email </h2>
      <br>
      <h4 style='margin:0px'>Sender's Information</h4>
      <br>
      <h4 style='margin:0px; display:inline;'>Name: </h4>${user?.first_name} ${user?.last_name}<br>
      <h4 style='margin:0px; display:inline;'>Clinic: </h4>${user.current_office.name}<br>
      <h4 style='margin:0px; display:inline;'>Email Address: </h4>${user?.email}<br>
      <br>
      <br>
      <h4 style='margin:0px; display:inline;'>Type of report: </h4> ${type}<br>
      <h4 style='margin:0px'>Message:</h4>
      <p style='margin:0px'>${message}</p>
      </div>`,
    })
      .then(function (message) {
        // alert("mail sent successfully");
        setLoading(false);
        if (message === "OK") {
          setStatus("success");
          console.log(message);
        } else {
          setStatus("error");
          console.log(message);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);

        setStatus("error");
        throw err;
      });
  };

  return (
    <>
      <ListItem
        button
        onClick={() => {
          openModal();
        }}
        classes={{
          selected: classes.selectedMenu,
          gutters: classes.listGutter,
        }}
      >
        <ListItemText primary="User Feedback" />
      </ListItem>
      <DFDialog
        open={isOpen}
        title={"User Feedback"}
        fullWidth
        maxWidth={"xl"}
        actions={(() => {
          if (!status) {
            return (
              <>
                <Button
                  onClick={handleSendEmail}
                  color="primary"
                  disabled={loading}
                >
                  Send Message
                </Button>
                <Button
                  onClick={() => {
                    close();
                  }}
                  disabled={loading}
                >
                  Cancel
                </Button>
              </>
            );
          }

          if (status === "success") {
            return (
              <>
                <Button
                  onClick={() => {
                    close();
                  }}
                >
                  Close
                </Button>
              </>
            );
          }
          if (status === "error") {
            return (
              <>
                <Button
                  onClick={handleSendEmail}
                  color="primary"
                  disabled={loading}
                >
                  Try Again
                </Button>
                <Button
                  onClick={() => {
                    close();
                  }}
                >
                  Cancel
                </Button>
              </>
            );
          }
        })()}
      >
        <Box
          noValidate
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            m: "auto",
            width: "100%",
          }}
        >
          {(() => {
            if (!status) {
              return (
                <>
                  Select Type:
                  <Select
                    fullWidth
                    displayEmpty
                    value={type}
                    style={{ marginBottom: 20 }}
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                    disabled={loading}
                  >
                    {typeList.map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                  Enter Message:
                  <TextField
                    id="contact-support-message"
                    disabled={loading}
                    multiline
                    fullWidth
                    variant="outlined"
                    name="message"
                    style={{ marginTop: 6 }}
                  />
                </>
              );
            }

            if (status === "success") {
              return (
                <>
                  Your message was sent successfully. Thank you for your
                  feedback
                </>
              );
            }

            if (status === "error") {
              return (
                <>
                  Something went wrong while sending your message. Would you
                  like to try again?
                </>
              );
            }
          })()}
        </Box>
      </DFDialog>
    </>
  );
}

export default ContactSupport;
