import React from "react";

import { makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  cardBase: {
    padding: "5px 15px 20px",
    position: "relative",
    zIndex: 2,
    background: "#fff",
    display: "flex",
    alignItems: "center",
    borderBottom: `${theme.palette.divider} 1px solid`,
  },
  notificationCard: {
    display: "flex",
    minWidth: "80vw",
    gap: 5,
    borderBottom: `${theme.palette.divider} 1px solid`,
    padding: "10px 16px",
    cursor: "pointer",
    "&:hover": {
      filter: "brightness(0.85)",
    },
  },
}));

export default function DFCardSkeleton({ number_of_items = 1, type }) {
  const classes = useStyles();
  let skeleton_cards = [];
  for (let i = 0; i < number_of_items; i++) {
    switch (type) {
      case "contact":
        skeleton_cards.push(
          <div
            key={i}
            className={classes.cardBase}
            style={{ padding: "13px 32px" }}
          >
            <div style={{ flexGrow: 1 }}>
              <Skeleton animation="wave" height={30} width="70%" />
            </div>
            <div style={{ display: "flex" }}>
              <Skeleton
                style={{ marginRight: 5 }}
                variant="circle"
                animation="wave"
                height={30}
                width={30}
              />
              <Skeleton
                style={{ marginRight: 5 }}
                variant="circle"
                animation="wave"
                height={30}
                width={30}
              />
              <Skeleton
                variant="circle"
                animation="wave"
                height={30}
                width={30}
              />
            </div>
          </div>
        );
        break;
      case "communication":
        skeleton_cards.push(
          <div
            key={i}
            className={classes.cardBase}
            style={{ display: "grid", padding: "25px 32px" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <Skeleton animation="wave" height={30} width="60%" />
              <Skeleton animation="wave" height={25} width="30%" />
            </div>
            <Skeleton animation="wave" height={20} width="90%" />
            <Skeleton animation="wave" height={20} width="100%" />
          </div>
        );
        break;

      case "appointment":
        skeleton_cards.push(
          <div style={{ padding: "10px 16px" }}>
            <Skeleton
              style={{ marginRight: 5 }}
              variant="text"
              animation="wave"
              height={30}
              width={"50vw"}
            />
            <div
              style={{ display: "flex", marginTop: 10, alignItems: "center" }}
            >
              <Skeleton
                style={{ marginRight: 5 }}
                variant="circle"
                animation="wave"
                height={24}
                width={24}
              />
              <Skeleton
                style={{ marginRight: 5 }}
                variant="text"
                animation="wave"
                height={20}
                width={"65vw"}
              />
            </div>
            <div
              style={{
                display: "flex",
                marginTop: 5,
                marginBottom: 10,
                alignItems: "center",
              }}
            >
              <Skeleton
                style={{ marginRight: 5 }}
                variant="circle"
                animation="wave"
                height={24}
                width={24}
              />
              <Skeleton
                style={{ marginRight: 5 }}
                variant="text"
                animation="wave"
                height={20}
                width={"65vw"}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 20,
              }}
            >
              <Skeleton
                style={{ marginRight: 5 }}
                variant="circle"
                animation="wave"
                height={30}
                width={30}
              />
              <Skeleton
                style={{ marginRight: 5 }}
                variant="circle"
                animation="wave"
                height={30}
                width={30}
              />
              <Skeleton
                style={{ marginRight: 5 }}
                variant="circle"
                animation="wave"
                height={30}
                width={30}
              />
              <Skeleton
                style={{ marginRight: 5 }}
                variant="circle"
                animation="wave"
                height={30}
                width={30}
              />
            </div>
          </div>
        );
        break;

      case "log":
        skeleton_cards.push(
          <div
            key={i}
            className={classes.cardBase}
            style={{ flexDirection: "column" }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ paddingTop: "0.5rem" }}>
                <Skeleton animation="wave" width={"55vw"} height="2rem" />
                <Skeleton animation="wave" width={"50vw"} height="1.5rem" />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "end",
                }}
              >
                <Skeleton animation="wave" width={"22vw"} height="1.2rem" />
                <Skeleton animation="wave" width={"15vw"} height="1rem" />
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Skeleton animation="wave" width={"100vw"} />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "0.5rem",
              }}
            >
              <Skeleton animation="wave" width={"100vw"} />
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Skeleton animation="wave" width={"45vw"} height={"2.8rem"} />
              <Skeleton animation="wave" width={"45vw"} height={"2.8rem"} />
            </div>
          </div>
        );
        break;

      case "notification":
        skeleton_cards.push(
          <div key={i} className={classes.notificationCard}>
            <div style={{ padding: "4px 8px 0 0" }}>
              <Skeleton
                style={{ marginRight: 5 }}
                variant="circle"
                animation="wave"
                height={30}
                width={30}
              />
            </div>
            <div style={{ flexGrow: 1 }}>
              <Skeleton variant="text" animation="wave" width="30vw" />
              <Skeleton variant="text" animation="wave" width="20vw" />
            </div>
            <div>
              <Skeleton variant="text" animation="wave" width="15vw" />
            </div>
          </div>
        );
        break;

      default:
        break;
    }
  }

  return <div>{skeleton_cards}</div>;
}
