import { alpha, makeStyles } from "@material-ui/core/styles";
import { browserName, isDesktop } from "react-device-detect";

export const callPopUpStyles = makeStyles((theme) => ({
  dialerMainContainer: {
    backgroundColor: "rgba(233, 237, 241, 0.5)",
    height: `calc(100vh - ${
      isDesktop && browserName !== "Electron" ? "168.91px" : "120px"
    })`,
    // height: `calc(100vh - 120px)`,
  },
  MessageButton: {
    backgroundColor: theme.palette.warning.main,
    "&:hover": {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  SendMessageButton: {
    marginBottom: 20,
  },
  largeAvatar: {
    position: "relative",
    zIndex: 2,
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  textFieldRoot: {
    borderRadius: 10,
    marginTop: 3,
    marginBottom: 10,
  },
  customFabDefault: {
    boxShadow: "none",
    padding: 35,
    backgroundColor: "#fff",
    border: "1px solid #373737",
  },
  customFabGray: {
    boxShadow: "none",
    padding: 35,
    backgroundColor: "inherit",
    border: "1px solid transparent",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  customFab: {
    boxShadow: "none",
    padding: 35,
  },
  customIconButton: {
    boxShadow: "none",
    padding: 24,
  },
  hoverName: {
    cursor: "pointer",
  },
  callTitle: {
    position: "relative",
    textTransform: "capitalize",
    paddingRight: 55,
    color: "white",
    display: "flex",
    gap: 10,
    alignItems: "center",
  },
}));

export const allConvoStyles = makeStyles((theme) => ({
  textFieldRoot: {
    backgroundColor: "#fff",
    borderRadius: 10,
  },
  paperRight: {
    position: "relative",
    cursor: "pointer",
    "&:hover": {
      boxShadow:
        "0px 6px 6px -3px rgb(0 0 0 / 1%), 0px 10px 14px 1px rgb(0 0 0 / 2%), 0px 4px 18px 3px rgb(0 0 0 / 7%)",
    },
    "&::after": {
      top: " -5px",
      right: " -10px",
      clear: " both",
      width: " 0",
      height: " 0",
      content: ' ""',
      position: " absolute",
      borderLeft: " 20px solid #cbf5ee",
      borderBottom: " 10px solid transparent",
      borderTop: " 10px solid transparent",
      transform: " rotate(-25deg)",
    },
  },
  paperLeft: {
    position: "relative",
    cursor: "pointer",
    "&:hover": {
      boxShadow:
        "0px 6px 6px -3px rgb(0 0 0 / 1%), 0px 10px 14px 1px rgb(0 0 0 / 2%), 0px 4px 18px 3px rgb(0 0 0 / 7%)",
    },
    "&::after": {
      top: " -5px",
      left: " -10px",
      clear: " both",
      width: " 0",
      height: " 0",
      content: ' ""',
      position: " absolute",
      borderRight: ` 20px solid #fff`,
      borderBottom: " 10px solid transparent",
      borderTop: " 10px solid transparent",
      transform: " rotate(25deg)",
    },
  },
}));

export const logCardStyles = makeStyles((theme) => ({
  listRoot: {
    position: "relative",
    cursor: "pointer",
    padding: "25px 32px",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  secondaryStyle: {
    whiteSpace: "pre-wrap",
    overflowWrap: "break-word",
  },

  noWrapTypo: {
    whiteSpace: "unset",
    textOverflow: "unset",
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    overflowWrap: "anywhere",
  },
}));

export const searchFilterStyles = makeStyles((theme) => ({
  search: {
    flexGrow: 1,
    borderRadius: 4,
    border: "1px solid #ddd",
    position: "relative",
    backgroundColor: alpha(theme.palette.common.black, 0.08),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.black, 0.12),
    },

    marginLeft: 0,
    marginRight: 0,
    // [theme.breakpoints.up("sm")]: {
    //   marginLeft: theme.spacing(3),
    //   width: "auto",
    // },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.text.hint,
    fill: theme.palette.text.hint,
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  appbarRoot: {
    top: 56,
    zIndex: 1098,
    transition: "top .3s",
    paddingBottom: "2px",
  },
  drawerPaper: {
    width: "100%",
    height: "90vh",
    borderRadius: "15px 15px 0px 0px",
  },
  selectedFilters: {
    paddingLeft: "1.2em",
    fontWeight: "normal",
    fontSize: "1rem",
    alignItems: "center",
  },
}));

export const drawerStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: "100%",
    height: "90vh",
    borderRadius: "15px 15px 0px 0px",
  },
}));
