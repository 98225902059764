import React from "react";

import { useTheme } from "@material-ui/core/styles";

export default function DFChatHistory(active) {
  const theme = useTheme();
  return (
    <svg
      style={{ position: "absolute" }}
      width="34"
      height="33"
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.28018 26.471L0.90918 32.182V1.99547C0.90918 1.56272 1.0835 1.14769 1.39378 0.841684C1.70407 0.53568 2.12491 0.36377 2.56373 0.36377H32.3455C32.7844 0.36377 33.2052 0.53568 33.5155 0.841684C33.8258 1.14769 34.0001 1.56272 34.0001 1.99547V24.8393C34.0001 25.272 33.8258 25.6871 33.5155 25.9931C33.2052 26.2991 32.7844 26.471 32.3455 26.471H8.28018ZM7.13523 23.2076H30.691V3.62717H4.21827V25.4675L7.13523 23.2076ZM16.2849 15.2465L23.3051 8.32484L25.6446 10.6321L16.2849 19.8626L9.84869 13.5153L12.1899 11.2081L16.2849 15.2465V15.2465Z"
        fill="#373737"
      />
    </svg>
  );
}
