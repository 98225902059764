import { Divider, MenuItem, Select, Typography } from "@material-ui/core";

function Treatment({ onChange, value, treatments }) {
  return (
    <div style={{ display: "grid" }}>
      <Divider style={{ marginBottom: 20 }} />
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Treatment:
      </Typography>

      <Select
        fullWidth
        gutterBottom
        variant="outlined"
        style={{ padding: 0, marginBottom: 20, height: 50 }}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        value={value}
        renderValue={(selected) => {
          if (!value) {
            return (
              <span style={{ color: "rgba(82,89,117,.4)" }}>
                Select Treatment
              </span>
            );
          }

          return value;
        }}
        displayEmpty
      >
        {treatments.map((val, idx) => (
          <MenuItem key={idx} value={val}>
            {val}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

export default Treatment;
