import React from "react";

import PauseIcon from "@material-ui/icons/Pause";
import { useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

function Button({ play, isPlaying, onError, isLoading }) {
  const theme = useTheme();
  return (
    <IconButton
      style={{
        border: `1px solid ${
          onError || isLoading
            ? theme.palette.action.disabled
            : theme.palette.action.active
        }`,
      }}
      disabled={onError || isLoading}
      onClick={(e) => {
        play();
        e.stopPropagation();
      }}
      size="small"
    >
      {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
    </IconButton>
  );
}
export default Button;
