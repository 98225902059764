import { forwardRef, useContext, useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Slide,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CallIcon from "@material-ui/icons/Call";
import CloseIcon from "@material-ui/icons/Close";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import PhoneMissedIcon from "@material-ui/icons/PhoneMissed";
import Skeleton from "@material-ui/lab/Skeleton";
import { SnackbarContext } from "App";
import useCallFeature from "hooks/context-hooks/useCallFeature";
import useUserContext from "hooks/context-hooks/useUserContext";
import useDFLazyQuery from "hooks/useDFLazyQuery";
import useDFMutation from "hooks/useDFMutation";
import useUpsertContact from "hooks/useUpsertContact";
import moment from "moment";
import { DashboardContext } from "pages/dashboard/Dashboard";
import dashboardService from "services/dashboardService";
import {
  appointmentStatusChecker,
  fromAndToFormatter,
  logTypeDetailText,
  logTypeNameIdentifier,
  notifLogIdentifier,
  patientStatus,
  patientStatusIdentifier,
} from "utils/ArrayUtils";
import { LOG_TYPE_ID } from "utils/constants";
import { useUpdateOneCallLog } from "utils/hooks/apiHooks/useUpdateOneCallLog";
import { useUpdateOneWebLog } from "utils/hooks/apiHooks/useUpdateOneWebLog";
import AudioPlayer from "./audioPlayer/AudioPlayer";
import DFIconButton from "./DFCustoms/DFIconButton";
import BookingStatusRadioGroup from "./log-releted-components/BookingStatusRadioGroup";
import ChangeInBooking from "./log-releted-components/ChangeInBooking";
import FollowupDetails from "./log-releted-components/FollowupDetails";
import LogTypeRadioGroup from "./log-releted-components/LogTypeRadioGroup";
import ShowedUpDetails from "./log-releted-components/ShowedUp";
import Treatment from "./log-releted-components/Treatment";

const useStyles = makeStyles((theme) => ({
  secondaryStyle: {
    whiteSpace: "pre-wrap",
    overflowWrap: "break-word",
  },
  hoverPatientName: {
    textTransform: "capitalize",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const payloadInitialValue = {
  booking_change: null,
  booking_date: null,
  treatment_name: null,
  showed_up: null,
  showed_up_reason: null,
  follow_up_1: null,
  follow_up_2: null,
  follow_up_3: null,
  follow_up_4: null,
};
export default function LogDetails({
  singleData,
  open,
  upsertContactCallBack,
  playingAudioRef,
  updateLogs,
  isIndependent,
  close,
  user,
  updateLogDetailsCallBack,
  refetchLogs,
  ...props
}) {
  const dashboardContext = useContext(DashboardContext);
  const { callerData } = useCallFeature();
  const { treatments } = useUserContext();
  const classes = useStyles();
  const theme = useTheme();
  const [openDialog, setOpenDialog] = useState(false);
  const [data, setData] = useState({});
  const [status, setStatus] = useState("");
  const [appointmentStatus, setAppointmentStatus] = useState("");
  const [notes, setNotes] = useState("");
  const { setSnack } = useContext(SnackbarContext);
  const [patientName, setPatientName] = useState();
  const [payload, setPayload] = useState(payloadInitialValue);
  const [updateOneCallLog, loadingUpdateCallLog] = useUpdateOneCallLog();
  const [updateOneWebLog, loadingUpdateOnWebLog] = useUpdateOneWebLog();

  const [renderContactForm, { upsertContact }] = useUpsertContact(
    user,
    (value) => {
      setPatientName(value.first_name + " " + value.last_name);
      if (upsertContactCallBack) upsertContactCallBack(value);
      if (refetchLogs) refetchLogs();
    }
  );
  const handleChange = (field) => {
    return (value) => {
      // eslint-disable-next-line no-useless-computed-key
      setPayload({ ...payload, [field]: value });
    };
  };

  const [updateLog, { loading: loadingUpdate }] = useDFMutation(
    dashboardService.updateOneLog,
    {
      onCompleted: () => {
        setSnack({
          open: true,
          severity: "success",
          message: "Log updated successfully!",
        });
        setOpenDialog(false);
        close();
      },
      onError: (err) => {
        setSnack({
          open: true,
          severity: "error",
          message: `Error! ${err}`,
        });
      },
    }
  );
  const [callQuery, { loading: loadingQuery }] = useDFLazyQuery(
    dashboardService.getOneLog,
    {
      onCompleted: (oneData) => {
        setData({
          ...oneData.data,
          type:
            oneData?.data.call_type === 5
              ? "df_call_logs"
              : oneData?.data?.type,
        });
        setNotes(
          oneData.data.note === null || oneData.data.note === undefined
            ? ""
            : oneData.data.note
        );
        setStatus(oneData.data[logTypeDetailText(oneData.data.type, 0)]);
        setAppointmentStatus(
          appointmentStatusChecker(oneData.data.is_appointment_booked)
        );
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  useEffect(() => {
    setOpenDialog(open);
    if (isIndependent && singleData.office_id !== undefined) {
      callQuery({
        type: singleData.type,
        id: singleData?.others?.[notifLogIdentifier(singleData.type, 0)],
        logType: LOG_TYPE_ID[singleData.type],
        current_office: singleData.office_id,
        user,
      });
    } else {
      setData({
        ...singleData,
        type: singleData?.call_type === 5 ? "df_call_logs" : singleData?.type,
      });
      setStatus(singleData[logTypeNameIdentifier(singleData.type)]);
      setAppointmentStatus(
        appointmentStatusChecker(singleData.is_appointment_booked)
      );
      setNotes(
        singleData.note === null || singleData.note === undefined
          ? ""
          : singleData.note
      );
    }
  }, [open, singleData, isIndependent]);

  useEffect(() => {
    setPayload({
      booking_change: singleData.booking_change,
      booking_date: singleData.booking_date,
      treatment_name: singleData.treatment_name,
      showed_up: singleData.showed_up,
      showed_up_reason: singleData.showed_up_reason,
      follow_up_1: singleData.follow_up_1,
      follow_up_2: singleData.follow_up_2,
      follow_up_3: singleData.follow_up_3,
      follow_up_4: singleData.follow_up_4,
    });
  }, [singleData, open]);

  const handleUpsertContact = () => {
    const newLogData = {
      id: data?.patient_leads[0].id,
      patientID: data.patient_leads[0].id,
      officeID: data.office.id,
      patientStatus: patientStatus(
        data.patient_leads[0].patient_status_name,
        "name",
        "value"
      ),
      patientName:
        data.patient_leads[0].first_name +
        " " +
        data.patient_leads[0].last_name,
    };
    upsertContact(newLogData);
  };

  const handleClose = () => {
    setData({});
    setOpenDialog(false);
    close();
  };

  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
  };
  const handleChangeAppointmentStatus = (e) => {
    setAppointmentStatus(e.target.value);
  };

  const handleSave = async () => {
    if (data.type === "call_log") {
      //for call logs only
      await updateOneCallLog({
        log_id: data?.id,
        call_type: patientStatusIdentifier(status, "number"),
        note: notes,
        is_appointment_booked: appointmentStatus,
        ...payload,
      });
    } else if (data.type === "web_form_log") {
      await updateOneWebLog({
        log_id: data?.id,
        web_log_type: patientStatusIdentifier(status, "number"),
        note: notes,
        is_appointment_booked: appointmentStatus,
        ...payload,
      });
    } else {
      await updateLog({
        //for general type of logs
        user,
        patientID: data?.id,
        callType: patientStatusIdentifier(status, "number"),
        booked: appointmentStatus,
        note: notes,
        logType: data?.type,
        current_office: data?.office?.id,
      });
    }
    if (dashboardContext?.fetchDashboardData) {
      dashboardContext.fetchDashboardData();
    }
    updateLogDetailsCallBack &&
      updateLogDetailsCallBack({
        isBooked: appointmentStatus,
        patientLogType: status,
        note: notes,
        ...payload,
      });
  };
  const handleChangeNotes = (e) => {
    setNotes(e.target.value);
  };

  // const handleOpenHistory = () => {
  //   // console.log("called");
  //   const newLogData = {
  //     id: data.id,
  //     patientID: data.patient_leads[0].id,
  //     officeID: data.office.id,
  //     patientName:
  //       data.patient_leads[0].first_name +
  //       " " +
  //       data?.patient_leads[0]?.last_name,
  //   };
  //   setLogData(newLogData);

  //   setOpenHistory(true);
  // };
  return (
    <>
      <Dialog
        TransitionComponent={Transition}
        fullWidth
        scroll="paper"
        open={openDialog}
        onClose={() => {
          handleClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          style={{ position: "relative", textTransform: "capitalize" }}
        >
          {loadingQuery ||
          loadingUpdate ||
          loadingUpdateCallLog ||
          loadingUpdateOnWebLog ? (
            <Skeleton
              animation="wave"
              component="div"
              height={25}
              width="40%"
            />
          ) : (
            logTypeDetailText(data, 1)
          )}
          <DFIconButton
            style={{ position: "absolute", top: 8, bottom: 8, right: 20 }}
            onClick={handleClose}
            edge="end"
            icon={<CloseIcon />}
          />
        </DialogTitle>
        <DialogContent dividers={true} className="styled-scrollbars">
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            {loadingQuery ||
            loadingUpdate ||
            loadingUpdateCallLog ||
            loadingUpdateOnWebLog ? (
              <Skeleton
                animation="wave"
                component="div"
                height={25}
                width="60%"
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                onClick={handleUpsertContact}
              >
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: 600, textTransform: "capitalize" }}
                  color="primary"
                >
                  {patientName
                    ? patientName
                    : data?.patient_leads?.[0]?.full_name ||
                      data?.patient_leads?.[0]?.first_name +
                        " " +
                        data?.patient_leads?.[0]?.last_name}
                </Typography>

                <EditOutlinedIcon
                  color="primary"
                  style={{ fontSize: "16px", transform: "translateY(5px)" }}
                />
              </div>
            )}
            {loadingQuery ||
            loadingUpdate ||
            loadingUpdateCallLog ||
            loadingUpdateOnWebLog ? (
              <Skeleton
                animation="wave"
                component="div"
                height={25}
                width="30%"
              />
            ) : (
              <Typography variant="caption">
                {moment(data.created_at).fromNow().includes("month")
                  ? moment(data.created_at).format("MMM DD")
                  : moment(data.created_at).fromNow().includes("year")
                  ? moment(data.created_at).format("MMM DD, YYYY")
                  : moment(data.created_at).fromNow()}
              </Typography>
            )}
          </div>
          {(data.type === "call_log" || data.call_type === 5) &&
          !singleData.ongoing_call ? (
            data.recording_sid ? (
              <AudioPlayer
                src={data.recording_url}
                playingAudioRef={playingAudioRef}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 10,
                }}
              >
                {data.recording_sid ||
                (data.id === callerData?.call_log_id &&
                  data.id === callerData?.call_log_id) ? (
                  <CallIcon fontSize="small" />
                ) : (
                  <PhoneMissedIcon
                    fontSize="small"
                    style={{ color: theme.palette.secondary.main }}
                  />
                )}

                <Typography variant="subtitle1">
                  {(() => {
                    if (
                      data.id === callerData?.call_log_id &&
                      callerData?.is_call_log_popup
                    )
                      return "Call in progress. Recording will be available soon.";
                    else if (data.id === callerData?.call_log_id)
                      return "Recording will be available soon. ";
                    else return "Missed Call";
                  })()}
                </Typography>
              </div>
            )
          ) : null}
          {data.type === "sms_log" ||
          data.type === "web_form_log" ||
          data.type === "ChatWidget" ? (
            <div>
              {loadingQuery ||
              loadingUpdate ||
              loadingUpdateCallLog ||
              loadingUpdateOnWebLog ? (
                <>
                  <Skeleton
                    animation="wave"
                    component="div"
                    height={25}
                    width="100%"
                  />
                  <Skeleton
                    animation="wave"
                    component="div"
                    height={25}
                    width="100%"
                  />
                  <Skeleton
                    animation="wave"
                    component="div"
                    height={25}
                    width="60vw"
                  />
                </>
              ) : (
                <Typography className={classes.secondaryStyle}>
                  {data.body || data.comment}
                </Typography>
              )}
            </div>
          ) : null}
          {/* if outgoing call, dont show button, change in the future  */}
          {/* {!data.call_type === 5 &&
            (loadingQuery || loadingUpdate ? (
              <Skeleton
                animation="wave"
                component="div"
                height={50}
                width="100%"
              />
            ) : (
              <Button
                disableElevation
                variant="outlined"
                color="primary"
                fullWidth
                style={{ margin: "20px 0" }}
                onClick={handleOpenHistory}
              >
                {LOG_TYPE_NAME[data.type]}
              </Button>
            ))} */}

          {data.chatwidget_image_url && (
            <>
              <img
                src={data.chatwidget_image_url}
                height={100}
                alt=""
                width={100}
                style={{
                  border: "1px solid #5e5b5b",
                  marginBottom: 10,
                  marginTop: 10,
                  borderRadius: 10,
                }}
              />
            </>
          )}
          <LogTypeRadioGroup value={status} onChange={handleChangeStatus} />
          <BookingStatusRadioGroup
            value={appointmentStatus}
            onChange={handleChangeAppointmentStatus}
          />
          {(data.type === "call_log" || data.type === "web_form_log") && (
            <>
              <Treatment
                onChange={handleChange("treatment_name")}
                value={payload.treatment_name}
                treatments={treatments}
              />
              <ChangeInBooking
                onChange={handleChange("booking_change")}
                value={payload.booking_change === true ? "Yes" : "No"}
              />
              <ShowedUpDetails handleChange={handleChange} payload={payload} />
              <FollowupDetails handleChange={handleChange} payload={payload} />
            </>
          )}
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Date:
          </Typography>
          {loadingQuery ||
          loadingUpdate ||
          loadingUpdateCallLog ||
          loadingUpdateOnWebLog ? (
            <Skeleton
              animation="wave"
              component="div"
              height={25}
              width="70%"
            />
          ) : (
            <Typography paragraph>
              {moment(data.created_at).format("ddd, MMM DD, YYYY - hh:mm A")}
            </Typography>
          )}
          <Divider style={{ marginBottom: 20 }} />
          <Typography variant="body2" color="textSecondary" gutterBottom>
            From:
          </Typography>
          <Typography paragraph>{fromAndToFormatter(data, "from")}</Typography>

          <Divider style={{ marginBottom: 20 }} />
          <Typography variant="body2" color="textSecondary" gutterBottom>
            To:
          </Typography>
          <Typography paragraph>{fromAndToFormatter(data, "to")}</Typography>
          <Divider style={{ marginBottom: 20 }} />

          {data.type === "call_log" ? (
            <>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Call Status:
              </Typography>
              {loadingQuery ||
              loadingUpdate ||
              loadingUpdateCallLog ||
              loadingUpdateOnWebLog ? (
                <Skeleton
                  animation="wave"
                  component="div"
                  height={25}
                  width="50%"
                />
              ) : (
                <Typography
                  paragraph
                  style={{
                    color: `${
                      data.dial_call_status === "no-answer"
                        ? theme.palette.secondary.main
                        : "rgba(0,0,0,.8)"
                    }`,
                  }}
                >
                  {data.dial_call_status}
                </Typography>
              )}
              <Divider style={{ marginBottom: 20 }} />
            </>
          ) : null}
          {data.type === "sms_log" ? (
            <>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                SMS Status:
              </Typography>
              {loadingQuery ||
              loadingUpdate ||
              loadingUpdateCallLog ||
              loadingUpdateOnWebLog ? (
                <Skeleton
                  animation="wave"
                  component="div"
                  height={25}
                  width="50%"
                />
              ) : (
                <Typography
                  paragraph
                  style={{
                    color: `${
                      data.sms_status === "undelivered" ||
                      data.sms_status === "unknown"
                        ? theme.palette.secondary.main
                        : "rgba(0,0,0,.8)"
                    }`,
                  }}
                >
                  {data.sms_status}
                </Typography>
              )}
              <Divider style={{ marginBottom: 20 }} />
            </>
          ) : null}
          {data.type === "web_form_log" ? (
            <>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                URL:
              </Typography>
              {loadingQuery ||
              loadingUpdate ||
              loadingUpdateCallLog ||
              loadingUpdateOnWebLog ? (
                <Skeleton
                  animation="wave"
                  component="div"
                  height={25}
                  width="100%"
                />
              ) : (
                <Typography paragraph>
                  {data.site_url ? data.site_url : "N/A"}
                </Typography>
              )}
              <Divider style={{ marginBottom: 20 }} />
            </>
          ) : null}
          {data.type === "call_log" ? (
            <>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Call ID:
              </Typography>
              {loadingQuery ||
              loadingUpdate ||
              loadingUpdateCallLog ||
              loadingUpdateOnWebLog ? (
                <Skeleton
                  animation="wave"
                  component="div"
                  height={25}
                  width="40%"
                />
              ) : (
                <Typography paragraph>{data.call_log_index}</Typography>
              )}
              <Divider style={{ marginBottom: 20 }} />
            </>
          ) : null}
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Ad Type:
          </Typography>
          {loadingQuery ||
          loadingUpdate ||
          loadingUpdateCallLog ||
          loadingUpdateOnWebLog ? (
            <Skeleton
              animation="wave"
              component="div"
              height={25}
              width="60%"
            />
          ) : (
            <Typography paragraph>
              {data.ads_type_name ? data.ads_type_name : "N/A"}
            </Typography>
          )}
          {data.type === "call_log" ? (
            <Divider style={{ marginBottom: 20 }} />
          ) : null}

          {data.type === "call_log" ? (
            <>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Note:
              </Typography>
              <TextField
                name="note"
                multiline
                minRows={4}
                fullWidth
                placeholder="Add notes"
                onChange={handleChangeNotes}
                value={notes}
                variant="outlined"
                size="small"
                disabled={
                  loadingQuery ||
                  loadingUpdate ||
                  loadingUpdateCallLog ||
                  loadingUpdateOnWebLog
                }
              />
            </>
          ) : null}
        </DialogContent>
        <DialogActions style={{ padding: "16px 24px" }}>
          <Button
            variant="contained"
            disableElevation
            disabled={
              loadingQuery ||
              loadingUpdate ||
              loadingUpdateCallLog ||
              loadingUpdateOnWebLog
                ? true
                : false
            }
            onClick={handleSave}
            color="primary"
            autoFocus
            fullWidth
          >
            Save
          </Button>
        </DialogActions>
        {renderContactForm()}
      </Dialog>
    </>
  );
}
