import React, { useEffect, useState } from "react";

import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function DFAutoCompletePatient({
  formik,
  name,
  gutterBottom,
  label,
  type,
  options,
  getOptionLabel,
  renderOption,
  required,
  ...props
}) {
  const [val, setVal] = useState("");
  useEffect(() => {
    if (options) {
      options.map((vals, idx) => {
        if (formik.initialValues[name] === vals.id) {
          setVal(vals);
        }
        return vals;
      });
    }
  }, [formik.initialValues[name], options]);
  const handleChange = (event, newVal) => {
    setVal(newVal);
    formik.setFieldValue(name, newVal ? newVal.id : "");
  };
  return (
    <div style={gutterBottom ? { marginBottom: 10 } : {}}>
      <Typography variant="caption" color="textSecondary">
        {label}
        {required ? "*" : ""}
      </Typography>

      <Autocomplete
        value={val}
        onChange={handleChange}
        options={options}
        getOptionLabel={(option) => (option.full_name ? option.full_name : "")}
        renderOption={(option) => (
          <div style={{ display: "grid" }}>
            <Typography variant="body1">{option.full_name}</Typography>
            <Typography variant="body2" color="textSecondary">
              {option.phone}
            </Typography>
          </div>
        )}
        {...props}
        renderInput={(params) => (
          <TextField
            {...params}
            {...props}
            variant="outlined"
            error={formik.touched[name] && Boolean(formik.errors[name])}
            helperText={formik.touched[name] && formik.errors[name]}
          />
        )}
      />
    </div>
  );
}
