import React from "react";

import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

export default function DFSelectField({
  formik,
  name,
  gutterBottom,
  label,
  options,
  required,
  placeholder,
  ...props
}) {
  return (
    <div style={gutterBottom ? { marginBottom: 10 } : {}}>
      <Typography variant="caption" color="textSecondary">
        {label}
        {required ? "*" : ""}
      </Typography>

      <FormControl
        {...props}
        variant="outlined"
        error={formik.touched[name] && Boolean(formik.errors[name])}
      >
        <Select
          name={name}
          displayEmpty
          value={formik.values[name]}
          onChange={formik.handleChange}
          {...props}
          renderValue={(selected) => {
            if (selected?.length === 0) {
              return (
                <span style={{ color: "rgba(82,89,117,.4)" }}>
                  {placeholder}
                </span>
              );
            }
            const newSelected = options.find((item) => item.value === selected);

            return newSelected?.name || "";
          }}
        >
          {options?.map((val, idx) => (
            <MenuItem key={idx} value={val.value}>
              {val.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>
          {formik.touched[name] && formik.errors[name]}
        </FormHelperText>
      </FormControl>
    </div>
  );
}
