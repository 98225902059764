import { createContext, useEffect, useState } from "react";

import "App.css";
import DFSplashScreen from "components/DFSplashScreen";
import usePubnubFeature from "hooks/usePubnubFeature";
import { PubNubProvider } from "pubnub-react";
import userService from "services/userService";
import { delay } from "utils/commonUtils";
import { useGetTreatmentList } from "utils/hooks/apiHooks/useGetTreatmentList";
export const UserContext = createContext();

function UserManager({ history, setSnack, children }) {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")) || {}
  );

  const [treatments] = useGetTreatmentList({
    api_token: user?.data?.token,
    current_office: user?.data?.current_office?.id,
  });
  const { pubnub, checkKeys } = usePubnubFeature(user?.data);
  // const [loading, setLoading] = useState(false);

  const checkRoles = async (params) => {
    console.log("checkRoles");
    // setLoading(true);
    try {
      let { data } = await userService.getOneUser(params, 1);
      if (data?.data?.offices?.length <= 0) {
        setSnack({
          open: true,
          severity: "error",
          message: `Your account is currently not linked to an office. Kindly reach out to support@dentistfind.com for assistance.`,
        });
        return;
      }
      const filteredOffice = data?.data?.offices?.filter(
        (o) => o.id === user?.data?.current_office?.id
      );

      let current_office;
      if (user?.data?.current_office) {
        if (filteredOffice.length === 0)
          current_office = data?.data?.offices?.[0];
        else current_office = filteredOffice[0];
      } else {
        current_office = data?.data?.offices?.[0];
      }

      const newData = {
        ...data?.data,
        token: params?.token,
        checked: params.checked,
        current_office: current_office,
      };
      const userData = {
        ...data,
        data: {
          ...newData,
        },
      };
      localStorage.setItem("user", JSON.stringify(userData));
      setUser(userData);
      return true;
    } catch (err) {
      console.log(err);
      if (err.response) {
        // client received an error response (5xx, 4xx)
        console.log(
          "client received an error response (5xx, 4xx)",
          err.response
        );
        if (
          err.response.data.message === "JWT Token Expired!" ||
          err.response.data.message === "Invalid Token"
        ) {
          const { data } = user;
          if (data?.checked) {
            data.checked = false;
          }
          // debugger;
          console.log(user);
          localStorage.setItem(
            "user",
            JSON.stringify({
              data: {
                email: data?.email,
                current_office: data?.current_office,
              },
            })
          );

          // debugger;
          if (window.location.hash !== "#/") {
            setSnack({
              open: true,
              severity: "error",
              message: `Session expired!`,
            });
            window.location.replace("#/");
          }
          return;
        }
        setSnack({
          open: true,
          severity: "error",
          message: `Error! ${err.response.data.message}`,
        });
        return;
      } else if (err.request) {
        console.log("client never received a response, or request never left");
        // client never received a response, or request never left
      } else {
        console.log(err);
        // anything else
      }
    }
    // setLoading(false);
  };

  const logoutUser = () => {
    if (localStorage.getItem("user") === null) return;
    try {
      const { data } = user;

      const newData = {
        email: data.email,
        checked: false,
        current_office: data.current_office,
      };
      localStorage.setItem(
        "user",
        JSON.stringify({
          data: newData,
        })
      );
      setUser({
        data: newData,
      });
      window.location.replace("#/");
    } catch (err) {
      console.log(err);
    }
  };

  const switchClinic = (state, history, location) => {
    const dataFiltered = user?.data?.offices?.filter((val, idx) => {
      return val.id === state.selectedClinic;
    });

    const newData = {
      ...user?.data,
      current_office: dataFiltered[0],
    };
    const userData = {
      ...user,
      // data: newData,
      data: { ...newData, current_office: dataFiltered[0] },
    };
    localStorage.setItem("user", JSON.stringify(userData));
    // setUser(userData);
    // if (location.pathname === "/dashboard") {
    //   debugger;
    //   window.location.reload(true);
    // } else {
    //   debugger;

    //   history.push("/dashboard");
    // }
    // await delay(1000);
    // window.location.reload();
    // location;
    history.go(0);
    // history.push("/dashboard");
  };

  useEffect(() => {
    const func = async () => {
      await checkRoles(user?.data);
      await delay(3000);
      checkKeys();
    };
    if (history.location.hash !== "#/" || !user?.data?.current_office?.id) {
      // if (history.location.hash !== "#/") {
      func();
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        user: user.data,
        setUser,
        checkRoles,
        logoutUser,
        switchClinic,
        treatments,
      }}
    >
      {/* <AndroidPushTestUI /> */}
      <PubNubProvider client={pubnub}>
        {!user?.data?.current_office?.id && history.location.hash !== "#/" ? (
          <DFSplashScreen />
        ) : (
          <>{children}</>
        )}
      </PubNubProvider>
    </UserContext.Provider>
  );
}

export default UserManager;
