import React from "react";

import { useTheme } from "@material-ui/core/styles";

export default function DFSendCovidForm(active) {
  const theme = useTheme();
  return (
    // style={{ position: "absolute" }}

    <svg
      style={{ position: "absolute" }}
      width="28"
      height="34"
      viewBox="0 0 28 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.54541 5.14549V29.5819C1.54541 30.392 1.86723 31.1689 2.44007 31.7417C3.0129 32.3146 3.78984 32.6364 4.59996 32.6364H22.9272C23.7373 32.6364 24.5143 32.3146 25.0871 31.7417C25.66 31.1689 25.9818 30.392 25.9818 29.5819V11.7769C25.9817 11.37 25.9004 10.9672 25.7425 10.5921C25.5846 10.2171 25.3534 9.87735 25.0624 9.59291L18.2813 2.96149C17.7106 2.40351 16.9442 2.09104 16.1461 2.09094H4.59996C3.78984 2.09094 3.0129 2.41276 2.44007 2.9856C1.86723 3.55844 1.54541 4.33537 1.54541 5.14549V5.14549Z"
        stroke="#373737"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.8179 2.09095V8.20004C16.8179 9.01016 17.1397 9.78709 17.7125 10.3599C18.2854 10.9328 19.0623 11.2546 19.8724 11.2546H25.9815"
        stroke="#373737"
        stroke-width="2.5"
        stroke-linejoin="round"
      />
    </svg>
  );
}
