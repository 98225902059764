import React from "react";

import { colors, Typography, makeStyles, useTheme } from "@material-ui/core";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";

import { logStatus } from "utils/ArrayUtils";

ChartJS.register(ArcElement, Tooltip);

const useStyles = makeStyles((theme) => ({
  root: { height: 220, position: "relative" },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-between",
    gap: 5,
  },
  rootChart: {
    position: "absolute",
    inset: 0,
    zIndex: 1,
  },
  centerLabel: {
    position: "absolute",
    inset: 0,
    display: "grid",
    placeItems: "center",
  },
}));

export default function DashboardChart({ pieData, totalCount }) {
  const classes = useStyles();
  const theme = useTheme();
  const pieDataCount = pieData.map((val, idx) => {
    return val.count;
  });
  const pieDataName = pieData.map((val, idx) => {
    return val.name;
  });
  const pieDataColor = pieData.map((val, idx) => {
    return logStatus(val.key, "name", "main_color");
  });

  const data = {
    datasets: [
      {
        data: pieDataCount,
        backgroundColor: pieDataColor,
        borderWidth: 0,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white,
      },
    ],
    labels: pieDataName,
  };
  const doughnutOptions = {
    cutout: "78%",
    layout: { padding: 0 },
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: "index",
      titleFontColor: theme.palette.text.primary,
    },
  };

  return (
    <div className={classes.root}>
      <div className={classes.rootChart}>
        <Doughnut data={data} options={doughnutOptions} />
      </div>
      <div className={classes.centerLabel}>
        <div>
          <Typography variant="h3" align="center">
            <b>{totalCount}</b>
          </Typography>
          <Typography variant="caption" component="p" align="center">
            LOGS
          </Typography>
        </div>
      </div>
    </div>
  );
}
