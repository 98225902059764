import config from "config/config";
import { useEffect, useState } from "react";
import { GET } from "utils/restUtils";

export const useGetTreatmentList = ({ api_token, current_office }) => {
  const [treatments, setTreatments] = useState([]);
  const callApi = async () => {
    const baseUrl = `${config.BASE_URL}/api/v1/web_logs/treatments`;
    const params = {
      api_token,
      current_office,
    };

    const queryString = new URLSearchParams(params).toString();
    return GET(`${baseUrl}?${queryString}`);
  };
  const func = async () => {
    try {
      await callApi().then((response) => {
        setTreatments(response.data.treatment_name);
      });
    } catch {
      console.log("error");
    }
  };
  useEffect(() => {
    if (api_token && current_office) {
      func();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api_token, current_office]);

  return [treatments];
};
