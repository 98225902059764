import {
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import React from "react";

function ChangeInBooking({ onChange, value, isLoading }) {
  return (
    <>
      <Divider style={{ marginBottom: 20 }} />
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Change in Booking:
      </Typography>

      <RadioGroup
        style={{ marginBottom: 10 }}
        row
        name="booking"
        value={value}
        onChange={(e) => {
          onChange(e.currentTarget.value === "Yes" ? true : false);
        }}
      >
        {[
          { value: "Yes", name: "Yes" },
          { value: "No", name: "No" },
        ].map((option, index) => (
          <FormControlLabel
            disabled={isLoading}
            key={index}
            value={option.value}
            control={<Radio color="primary" />}
            label={option.name}
          />
        ))}
      </RadioGroup>
    </>
  );
}

export default ChangeInBooking;
