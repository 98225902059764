import { useState } from "react";
import UpsertAppointment from "pages/appointments/UpsertAppointment";
import useCallFeature from "./context-hooks/useCallFeature";
import moment from "moment";
import useModal from "./useModal";

function useUpsertAppointment(user, callBackAfterUpsert = () => {}) {
  const [appointmentData, setAppointmentData] = useState({});
  const [isOpen, closeModal, openModal] = useModal("appointment_form");

  const { patientList } = useCallFeature();

  const openAppointmentForm = (data) => {
    if (patientList?.length !== 0) {
      let patientID = patientList?.filter((val) => data.id === val.id);
      const obj = {
        ...data,
        patient_lead_id: patientID?.[0]?.id,
        date: data.date,
        time: data.date ? moment(data.date).format("h:mm A") : "07:30 AM",
        note: data.note ? data.note : "",
      };
      setAppointmentData(obj);
    }
    openModal();
  };

  const renderAppointmentForm = () => {
    return (
      <UpsertAppointment //
        callBackAfterUpsert={callBackAfterUpsert}
        user={user}
        data={appointmentData}
        open={isOpen}
        close={closeModal}
      />
    );
  };
  return [renderAppointmentForm, { openAppointmentForm }];
}

export default useUpsertAppointment;
