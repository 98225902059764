import config, { ENVIRONMENT } from "config/config";
import { GET, PUT } from "utils/restUtils";

export const getAllCallLogs = (
  params,
  page,
  type,
  startDate,
  endDate,
  search,
  taggingStatus,
  status,
  source
) => {
  return GET(
    `${config.BASE_URL}/api/v1/call_logs?api_token=${params.token}&page=${page}&current_office=${params.current_office.id}&call_type=${type}&start_date=${startDate}&end_date=${endDate}&search=${search}&tagging_status=${taggingStatus}&status=${status}&source=${source}`
  );
};

export const getOneCallLog = (params, call_log_id) => {
  return GET(
    `${config.BASE_URL}/api/v1/call_logs/${call_log_id}?api_token=${params.token}&current_office=${params.current_office.id}`
  );
};

export const updateOneCallLog = (
  params,
  log_id,
  callType,
  booked,
  note,
  current_office
) => {
  if (ENVIRONMENT === "STAGING") console.log("updateOneCallLog");
  const baseUrl = `${config.BASE_URL}/api/v1/call_logs/${log_id}`;
  const queryParams = new URLSearchParams({
    api_token: params?.token,
    current_office,
    call_type: callType,
    is_appointment_booked: booked,
    booking_change: null,
    booking_date: null,
    treatment_name: null,
    showed_up_reason: null,
    follow_up_1: null,
    follow_up_2: null,
    follow_up_3: null,
    follow_up_4: null,
  });

  if (note !== "none") queryParams.append("note", note);

  return PUT(`${baseUrl}?${queryParams.toString()}`);
};

export const getCallHistory = (params, call_log_id, page) => {
  GET(
    `${config.BASE_URL}/api/v1/call_logs/${call_log_id}/all_logs?api_token=${params?.token}&page=${page}`
  );
};
export const getSource = (params) =>
  GET(`${config.BASE_URL}/api/v1/ads_types?api_token=${params?.token}`);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getOneCallLog,
  getAllCallLogs,
  updateOneCallLog,
  getCallHistory,
  getSource,
};
