import useContactService from "hooks/api-hooks/useContactService";
import { useContext, useState } from "react";
import { SnackbarContext } from "App";
import UpsertContact from "pages/contacts/UpsertContact";
import { DFPhoneNumber, getCountryCode } from "utils/ArrayUtils";
import useModal from "hooks/useModal";
const initialVal = {
  first_name: "",
  last_name: "",
  phone1: "1",
  phone1_is_textable: "",
  email: "",
  country_id: "",
  state_id: "",
  postal_code: "",
  city: "",
  patient_status: "",
  source_id: "",
  medium_id: "",
  contact_type: "",
};
function useUpsertContact(user, callBackAfterUpsert = () => {}) {
  const [upsertContactData, setUpsertContactData] = useState(null);
  const [isOpen, closeModal, openModal] = useModal("contact_form");
  const [isLoading, setIsLoading] = useState(false);
  const { setSnack } = useContext(SnackbarContext);
  const [countryCode, setCountryCode] = useState("1");

  const { getPatientInfo: fetchPatientInfo } = useContactService();

  const getPatientInfo = async (data) => {
    try {
      if (!isLoading) setIsLoading(true);
      let { data: patientData } = await fetchPatientInfo(data.id);
      setIsLoading(false);
      setCountryCode(getCountryCode(patientData?.data?.phone1_unformatted));
      let phoneNumber = new DFPhoneNumber(
        patientData?.data?.phone1_unformatted
      ).formatNational();

      return {
        id: patientData?.data?.id,
        officeID: patientData?.data?.office?.id,
        first_name: patientData?.data?.first_name,
        last_name: patientData?.data?.last_name,
        phone1: phoneNumber.value,
        email: patientData?.data?.email ? patientData?.data?.email : "",
        country_id: patientData?.data?.address?.country_id,
        state_id: patientData?.data?.address?.state_id,
        postal_code: patientData?.data?.address?.postal_code,
        city: patientData?.data?.address?.city,
        patient_status: patientData?.data?.patient_status,
        phone1_is_textable: patientData?.data?.phone1_is_textable,
        // medium_id: patientData?.data?.medium?.id,
        // source_id: patientData?.data?.source?.id,
        contact_type: patientData?.data?.contact_type,
      };
    } catch (err) {
      setIsLoading(false);
      setSnack({
        open: true,
        severity: "error",
        message: `Something went wrong.`,
      });
      console.log(err);
    }
  };

  const upsertContact = async (data) => {
    openModal();

    if (data?.fromDialer) {
      data = { ...initialVal, phone1: data.phone1 };
    }
    if (data.id) {
      data = await getPatientInfo(data);
    }
    setUpsertContactData(data);
  };

  const renderContactForm = () => {
    return (
      <UpsertContact //
        user={user}
        data={upsertContactData}
        setUpsertContactData={setUpsertContactData}
        callBackAfterUpsert={callBackAfterUpsert}
        isOpen={isOpen}
        close={closeModal}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
      />
    );
  };
  return [renderContactForm, { setUpsertContactData, upsertContact }];
}

export default useUpsertContact;
