import useCommunication from "hooks/context-hooks/useCommunication";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { LoaderNiMichaelGwapo } from "components/DFCustoms";
import useLogHistory from "hooks/useLogHistory";
import CommunicationCard from "./CommunicationCard";

export default function Communications({ filterPosition, ...props }) {
  const { ref, inView } = useInView();
  const {
    displayFilter,
    filtersPresent,
    communications,
    fetchNextPage,
    isFetchingNextPage,
    isFetching,
    isLoading,
  } = useCommunication();

  const [renderLogHistory, { openLogHistory }] = useLogHistory(props.user);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  const showConvo = (e) => {
    const firstName = e.first_name ? e.first_name : "";
    const lastName = e.last_name ? e.last_name : "";
    const patientData = {
      patientID: e._id,
      officeID: e.office_id,
      patientName: `${firstName} ${lastName}`,
      patientStatus: e.patient_status,
      phone1: e.phone1,
    };
    openLogHistory(patientData, e.log_id);
  };

  return (
    <div
      style={{
        position: "relative",
        minHeight: "80vh",
        ...(!filterPosition && { paddingTop: filtersPresent ? 80 : 56 }),
      }}
    >
      {displayFilter(filterPosition)}
      {renderLogHistory()}
      <LoaderNiMichaelGwapo
        number_of_items={10}
        type="communication"
        isLoading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
        showEmptyMessage={
          !isFetching &&
          (communications === undefined || communications[0].data.length === 0)
        }
      >
        {communications?.map((page) =>
          page?.data?.map((val, idx) => (
            <CommunicationCard
              key={val._id}
              ref={page?.data?.length === idx + 1 ? ref : null}
              params={val}
              history={props.history}
              token={props.user.token}
              isLoading={isLoading}
              showConvo={showConvo}
            />
          ))
        )}
      </LoaderNiMichaelGwapo>
    </div>
  );
}
