import { useEffect, useRef, useState } from "react";

function useDFLazyQuery(queryFn, { onCompleted, onError }) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const callQuery = async (variables) => {
    try {
      if (!loading) setLoading(true);
      let { data: oneData } = await queryFn(variables);

      setData({
        ...oneData.data,
        type:
          oneData?.data.call_type === 5 ? "df_call_logs" : oneData?.data?.type,
      });

      setLoading(false);
      if (onCompleted) onCompleted(oneData);
    } catch (err) {
      if (onError) onError(err);
    }
  };

  return [callQuery, { data, loading }];
}

export default useDFLazyQuery;
