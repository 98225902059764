import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useFormik } from "formik";
import useCallFeature from "hooks/context-hooks/useCallFeature";
import moment from "moment";
import { useContext, useState } from "react";
import * as Yup from "yup";
import { SnackbarContext } from "App";
import { drawerStyles } from "assets/materialStyles";
import DFAutoCompletePatient from "components/DFCustomFormFields/DFAutoCompletePatient";
import DFDatePicker from "components/DFCustomFormFields/DFDatePicker";
import DFTextField from "components/DFCustomFormFields/DFTextField";
import DFTimePicker from "components/DFCustomFormFields/DFTimePicker";
import DFIconButton from "components/DFCustoms/DFIconButton";
import appointmentService from "services/appointmentService";

const validationSchema = Yup.object({
  patient_lead_id: Yup.string().required("Patient name is required."),
  date: Yup.string().required("Appointment date is required").nullable(),
  // time: Yup.string().required("Appointment time is required"),
});

const initialValues = {
  patient_lead_id: "",
  date: "",
  time: "07:30 AM",
  note: "",
};
export default function UpsertAppointment({
  open,
  close,
  data,
  user,
  callBackAfterUpsert,
}) {
  const classes = drawerStyles();
  const { patientList } = useCallFeature();
  const [isLoading, setIsLoading] = useState(false);
  const { setSnack } = useContext(SnackbarContext);

  const formik = useFormik({
    initialValues: data || initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const newDate = moment(values.date).format("MM-DD-yyyy");
      const newData = {
        appointment: {
          patient_lead_id: values.patient_lead_id,
          at: moment(newDate + " " + values.time)._d,
          note: values.note,
          office_id: user.current_office.id,
          created_by_id: user?.id,
        },
      };
      if (data?.appID) {
        const { created_by_id, ...editData } = newData;
        handleUpdateAppointment(editData);
      } else {
        handleCreateAppointment(newData);
      }
    },
  });

  const handleClose = () => {
    formik.resetForm();
    close();
  };

  const handleUpdateAppointment = async (e) => {
    try {
      if (!isLoading) setIsLoading(true);
      await appointmentService.updateAppointmentDate(
        {
          token: user.token,
        },
        data?.appID,
        e
      );
      setIsLoading(false);
      setSnack({
        open: true,
        severity: "success",
        message: "Appointment updated successfully!",
      });
      callBackAfterUpsert();
      handleClose();
    } catch (err) {
      setIsLoading(false);
      setSnack({
        open: true,
        severity: "error",
        message: `Error! ${err}`,
      });
      console.log(err);
    }
  };

  const handleCreateAppointment = async (data) => {
    try {
      if (!isLoading) setIsLoading(true);
      await appointmentService.createAppointment(
        {
          id: user?.id,
          current_office: user.current_office,
          token: user.token,
        },
        data
      );
      setIsLoading(false);
      setSnack({
        open: true,
        severity: "success",
        message: "Appointment created successfully!",
      });
      callBackAfterUpsert();
      handleClose();
    } catch (err) {
      setIsLoading(false);
      setSnack({
        open: true,
        severity: "error",
        message: `Error! ${err}`,
      });
      console.log(err);
    }
  };

  return (
    <Drawer
      variant="temporary"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="bottom"
      open={open}
      onClose={handleClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle
          style={{ position: "relative", textTransform: "capitalize" }}
        >
          {data?.appID ? "Update" : "Book"} Appointment
          <DFIconButton
            style={{ position: "absolute", top: 8, bottom: 8, right: 20 }}
            onClick={handleClose}
            edge="end"
            icon={<CloseIcon />}
          />
        </DialogTitle>
        <DialogContent dividers={true}>
          <DFAutoCompletePatient
            label="Patient"
            options={patientList}
            name="patient_lead_id"
            size="small"
            placeholder="Input patient name"
            formik={formik}
            fullWidth
            required
            disabled={isLoading || data?.id ? true : false}
            gutterBottom
          />
          <DFDatePicker
            format="MMMM DD, YYYY"
            name="date"
            label="Date"
            placeholder="Select date"
            size="small"
            minDate={new Date()}
            variant="inline"
            formik={formik}
            required
            disabled={isLoading}
            fullWidth
            gutterBottom
          />
          {/* <Typography variant="caption" color="textSecondary">
            Select Time
          </Typography> */}

          <DFTimePicker
            // beginLimit="06:00AM"
            // endLimit="10:00PM"
            // disabled={isLoading}
            // step={5}
            label="Time"
            name="time"
            size="small"
            placeholder="Select time"
            formik={formik}
            required
            fullWidth
            gutterBottom
          />
          <DFTextField
            disabled={isLoading}
            label="Note"
            name="note"
            size="small"
            placeholder="Add note"
            formik={formik}
            fullWidth
            multiline
            minRows={4}
            gutterBottom
          />
          <Typography
            style={{ marginTop: 10 }}
            variant="caption"
            color="textSecondary"
            component="p"
          >
            <InfoOutlinedIcon fontSize="inherit" /> Link to COVID-19 form is
            included upon booking an appointment.
          </Typography>
        </DialogContent>
        <DialogActions style={{ padding: "16px 24px" }}>
          <Button
            type="submit"
            fullWidth
            disabled={isLoading}
            color="primary"
            variant="contained"
            startIcon={
              isLoading && (
                <CircularProgress
                  style={{ marginLeft: 5 }}
                  color="inherit"
                  size={18}
                />
              )
            }
            disableElevation={true}
          >
            {data?.appID ? "Update" : "Book"}
          </Button>
        </DialogActions>
      </form>
    </Drawer>
  );
}
