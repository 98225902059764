import React from "react";

import { Paper, Typography } from "@material-ui/core";
import moment from "moment";

import { LOG_TYPE_NAME_ICON } from "utils/constants";
import useModal from "hooks/useModal";
import ImageViewer from "./ImageViewer";
function BubbleChatWidgetLog({ handleOpenDialog, val, classes }) {
  const [isOpenImage, closeModalImage, openModalImage] = useModal("image");

  const handleClickImage = (e) => {
    e.stopPropagation();
    e.preventDefault();
    openModalImage();
  };

  const handleCloseImage = (e) => {
    e.stopPropagation();
    e.preventDefault();
    closeModalImage();
  };
  val.chatwidget_image_url && console.log(isOpenImage);
  return (
    <Paper
      onClick={() => {
        !isOpenImage && handleOpenDialog(val);
      }}
      elevation={0}
      className={
        val.type_name === "Office Sms" ? classes.paperRight : classes.paperLeft
      }
      style={{
        padding: "15px 15px",
        maxWidth: "85%",
        display: "flex",
        gap: 10,
        flexDirection: "row",
        justifySelf: `${val.type_name === "Office Sms" ? "end" : "start"}`,
        backgroundColor: `${
          val.type_name === "Office Sms" ? "#cbf5ee" : "#fff"
        }`,
      }}
    >
      <ImageViewer
        handleCancel={handleCloseImage}
        open={isOpenImage}
        src={val.chatwidget_image_url}
      />
      <div style={{ marginTop: 3 }}>{LOG_TYPE_NAME_ICON[val?.type]}</div>
      <div>
        <Typography
          gutterBottom
          style={{
            whiteSpace: "pre-wrap",
            display: "inline-block",
            wordBreak: "break-word",
          }}
        >
          {val.body || val.comment}
        </Typography>
        {val.chatwidget_image_url && (
          <>
            <br />
            <img
              onClick={handleClickImage}
              src={val.chatwidget_image_url}
              height={100}
              alt=""
              width={100}
              style={{
                border: "1px solid #5e5b5b",
                marginBottom: 10,
                marginTop: 10,
                borderRadius: 10,
              }}
            />
          </>
        )}
        <Typography
          component="div"
          variant="caption"
          style={{ fontSize: 10, width: "100%" }}
          color="textSecondary"
          align="right"
        >
          {moment(val.created_at).format("hh:mm A")} via Chat Widget
        </Typography>
      </div>
    </Paper>
  );
}

export default BubbleChatWidgetLog;
