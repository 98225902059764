import { Box, Button, LinearProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DFDialog from "./DFCustoms/DFDialog";
import { delay } from "utils/commonUtils";
const ipcRenderer = window?.ipcRenderer;

// List of statuses
// * update-available
// * update-downloaded
// * update-downloading
// * update-installing
// * update-not-available
const intitialValue = {
  message: "",
  status: "",
  title: "",

  // title: "New Version Available",
  // message: ` `,
  // status: "update-available",
  // currentVersion: "8.8.8",
  // latestVersion: "9.9.9",
  // title: "Download Complete",
  // message: `Update has finished downloading. App will now restart to install the update.`,
  // status: "update-downloaded",
};
function AutoUpdater() {
  const [state, setState] = useState(intitialValue);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setState({
      status: "update-downloaded",
      message: `Update downloading`,
      title: "Update Available",
    });
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
    setState(intitialValue);
  };

  const func = async () => {
    await delay(2000);
    setOpen(false);
  };

  useEffect(() => {
    ipcRenderer?.on("update-dom", (event) => {
      if (
        // event.status === "update-available" ||
        event.status === "update-downloaded"
        // event.status === "update-downloading"
      ) {
        setState({ ...event });
        setOpen(true);
        // if (event.status === "update-downloading") {
        //   func();
        // }
        return;
      }
    });
  }, [state]);

  const handleConfirm = () => {
    setOpen(false);
    ipcRenderer?.send("trigger-install", "Trigger download event recieved");
    return;
  };

  const dialogContent = () => {
    return (
      <div style={{ textAlign: "center" }}>
        A new update is available for installation.
        <br />
        Do you want to restart the app now to install the update?
      </div>
    );
  };
  return (
    <div>
      {/* <Button onClick={handleClickOpen}>Open Dialog</Button> */}
      {state.status === "update-downloading" && !open && (
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
            background: "black",
          }}
        >
          <LinearProgress style={{ height: 5 }} />
        </Box>
      )}
      <DFDialog
        open={open}
        title={state.title}
        content={<>{dialogContent()}</>}
        // onClose={handleCancel}
        actions={
          <>
            {state.status === "update-downloaded" && (
              <>
                <Button onClick={() => handleCancel()} color="default">
                  Restart Later
                </Button>

                <Button onClick={() => handleConfirm()} color="primary">
                  Yes
                </Button>
              </>
            )}
          </>
        }
      />
    </div>
  );
}

export default AutoUpdater;
