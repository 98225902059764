import { IconButton, Tooltip } from "@material-ui/core";
import React from "react";

function DFIconButton({
  onClick,
  icon,
  text,
  tooltip,
  color,
  style,
  edge,
  ...props
}) {
  if (tooltip) {
    return (
      <Tooltip title={tooltip}>
        <IconButton
          onClick={onClick}
          aria-label="sms"
          style={{ color: color, ...style }}
          edge={edge}
          {...props}
        >
          {icon}
        </IconButton>
      </Tooltip>
    );
  }
  return (
    <IconButton
      onClick={onClick}
      style={{ color: color, ...style }}
      aria-label="sms"
      edge={edge}
      {...props}
    >
      {icon}
    </IconButton>
  );
}

export default DFIconButton;
