import React, { useState } from "react";

import {
  FormControl,
  FormHelperText,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";

function DFInput({ name, label, control, rules, gutterBottom, ...props }) {
  const [toggle, setToggle] = useState(false);
  const handleClick = () => {
    setToggle(!toggle);
  };
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState, formState: { errors } }) => (
        <FormControl
          style={gutterBottom ? { marginBottom: 20 } : {}}
          {...props}
          error={fieldState.invalid}
          fullWidth
        >
          <Typography variant="caption" color="textSecondary">
            {label}
          </Typography>
          <OutlinedInput
            type={toggle ? "text" : "password"}
            {...field}
            placeholder={label}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={handleClick} edge="end">
                  {toggle ? (
                    <VisibilityOutlinedIcon fontSize="small" />
                  ) : (
                    <VisibilityOffOutlinedIcon fontSize="small" />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText>{errors[name]?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
}

export default DFInput;
