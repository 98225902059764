import { SnackbarContext } from "App";
import useSnackBar from "hooks/useSnackBar";
import moment from "moment";
import { usePubNub } from "pubnub-react";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import callingService from "services/callingService";
import { delay, parseUrlParameters, pubnubCleanUp } from "utils/commonUtils";
import CallPopup from "./CallPopup";
// import { incomingCallTest } from "utils/tests";
import { PushNotifications } from "@capacitor/push-notifications";
import { appMode, modesMap } from "config/config";
import useContactService, {
  getContactTypes,
} from "hooks/api-hooks/useContactService";
import useCommunication from "hooks/context-hooks/useCommunication";
import useUserContext from "hooks/context-hooks/useUserContext";
import useLogHistory from "hooks/useLogHistory";
import useModal from "hooks/useModal";

export const CallContext = createContext();

function CallFeature({ ...props }) {
  const bandwidthRtc = new window.BandwidthRtc();
  const pubnub = usePubNub();
  const { setSnack } = useContext(SnackbarContext);
  const { user } = useUserContext();
  const { getPatientList } = useContactService();
  const [channels] = useState([user?.id]);
  const [returnCall, setReturnCall] = useState(false);
  // const [newSingleCall, setNewSingleCall] = usePersistedState("callData", "");
  const [newSingleCall, setNewSingleCall] = useState();
  const { refetch: refetchCommunications } = useCommunication();

  const [missedCall, setMissedCall] = useState(undefined);
  const [isOpen, closeModal, openModal] = useModal("call_popup");
  const [remoteStream, setRemoteStream] = useState();
  const [timer, setTimer] = useState("");
  const [callOnGoing, setCallOnGoing] = useState(null);
  const increment = useRef(null);

  const [patientList, setPatientList] = useState([]);
  const [contactTypes, setContactTypes] = useState([]);

  const { renderSnackBar, showSnack } = useSnackBar({ user, ...props });

  const ringing = useRef(
    new Audio("https://voice-recordings-staging.s3.amazonaws.com/ringing.mp3")
  );

  const soundNotif = useRef(
    new Audio(
      "https://assets.mixkit.co/active_storage/sfx/2358/2358-preview.mp3"
    )
  );

  const ring = {
    play: () => {
      ringing.current.play();
    },
    stop: () => {
      ringing.current.pause();
      ringing.current.currentTime = 0;
    },
  };

  const audio = useRef(new Audio());

  const createStreamListeners = () => {
    // This event will fire any time a new stream is sent to us
    bandwidthRtc.onStreamAvailable((RtcStream) => {
      console.log("receiving audio!", RtcStream);
      // RtcStream?.mediaStream?.getAudioTracks();
      // console.log(RtcStream?.mediaStream?.getAudioTracks());
      ring.stop();
      audio.current.srcObject = RtcStream.mediaStream;
      audio.current.play();
      setCallOnGoing(true);
      setRemoteStream(RtcStream);
    });

    // This event will fire any time a stream is no longer being sent to us
    bandwidthRtc.onStreamUnavailable(() => {
      console.log("no longer receiving audio");
      setCallOnGoing(false);
      bandwidthRtc.unpublish();
      audio.current.srcObject = null;
      audio.current.pause();
      setRemoteStream(undefined);
      setReturnCall(false);
    });
  };

  const handleNotifications = async (event) => {
    const message = event.message;
    if (
      (typeof message === "string" || message.hasOwnProperty("text")) &&
      !callOnGoing
    ) {
      const text = message.text || message;
      const parsedText = JSON.parse(text);
      console.log("=================PubNub Notification ================ ", {
        ...event,
        message: parsedText,
      });

      const fullName = parsedText?.patient?.full_name
        ? parsedText?.patient?.full_name
        : "";
      const patientID = parsedText?.patient?.id;
      const timetoken = new Date(event.timetoken / 1e4);
      const FinalData = {
        answered_by: "",
        ...parsedText,
        patient: {
          patientID: patientID,
          officeID: "",
          patientName: fullName,
          patientStatus: parsedText?.patient?.patient_status,
          phone1: parsedText?.patient?.phone,
        },
        fullName,
        timetoken,
        patientID,
      };
      if (parsedText?.missed_call) {
        setNewSingleCall({
          ...parsedText,
        });
        setMissedCall(parsedText);
        ring.stop();
        return;
      }

      if (
        parsedText?.event_type === "missed" &&
        parsedText?.event_cause === "cancel" &&
        parsedText?.error_message === "Canceled by originator"
      ) {
        setNewSingleCall({
          ...parsedText,
        });
        setMissedCall({ ...parsedText, missed_call: true });
        ring.stop();
        return;
      }
      //
      if (
        parsedText?.event_type === "call-ended" ||
        parsedText?.outgoing_call_initiated === "call_disconnected"
      ) {
        setNewSingleCall((prevState) => {
          return {
            ...parsedText,
            call_log_id: prevState?.call_log_id,
          };
        });

        ring.stop();
        closeModal();
        setReturnCall(false);
        return;
      }

      if (parsedText?.call_in_progress) {
        setNewSingleCall({
          ...parsedText,
          error_message: "",
          missed_call: null,
          missed_call_cause: "",
        });
        closeModal();
        // console.log("answered By someone ");
        showSnack(
          {
            answeredBy: parsedText?.answered_by,
            key: new Date().getTime() + Math.random(),
          },
          true
        );
        setReturnCall(false);
        ring.stop();
        try {
          await bandwidthRtc.unpublish();
        } catch {}

        return;
      }

      if (
        !FinalData.is_from_push_notif &&
        FinalData?.office?.id !== user?.current_office?.id
      ) {
        return;
      }

      if (
        FinalData.type === "sms" ||
        FinalData.type === "web-form" ||
        FinalData.type === "chat_widget"
      ) {
        soundNotif.current.play();
        showSnack({ FinalData, key: new Date().getTime() + Math.random() });
      }

      if (callOnGoing) {
        console.log("a call is ongoing, ignoring the new incoming call");
        return;
      }

      if (
        FinalData.type === "call" &&
        FinalData?.is_call_log_popup
        // && !newSingleCall?.call_in_progress
      ) {
        ring.play();
        bandwidthRtc.publish({
          audio: true,
          video: false,
          advanced: {
            deviceId: "communications",
          },
        });
        setNewSingleCall({
          ...FinalData,
          error_message: "",
          missed_call: null,
          missed_call_cause: "",
        });
        setCallOnGoing(null);
        setReturnCall(true);
        openModal();
      }
    }
  };

  const getPatientListFn = async () => {
    try {
      let { data } = await getPatientList();
      setPatientList(data.data);
    } catch {}
  };

  const getContactTypesFn = async () => {
    try {
      let { data: contactTypes } = await getContactTypes({
        headers: {
          "api-token": user?.token,
        },
      });
      let remappedContactTypes = [];
      contactTypes?.data?.map((item) => {
        remappedContactTypes.push({ name: item[0], value: item[1] });
      });
      setContactTypes(remappedContactTypes);
    } catch {}
  };

  useEffect(() => {
    if (missedCall) {
      setNewSingleCall({
        ...newSingleCall,
        error_message: missedCall.error_message,
        missed_call: missedCall.missed_call,
        missed_call_cause: missedCall.missed_call_cause,
      });
      setCallOnGoing(false);
    }
  }, [missedCall]);

  useEffect(() => {
    // Check Notification Permission
    try {
      pubnub.hereNow(
        {
          channels: [channels],
          includeUUIDs: true,
          includeState: true,
        },
        (status, response) => {
          if (!status.error) {
            callingService
              .getCallToken(user)
              .then((resp) => {
                // console.log("token", resp?.data?.token);
                if (!resp || !resp.data || !resp.data.token) {
                  console.error("Invalid response or missing token");
                  return;
                }

                // try {
                bandwidthRtc
                  .connect({ deviceToken: resp?.data?.token })
                  .then(async () => {
                    console.log("connected to bandwidth webrtc!");
                  })
                  .catch((err) => {
                    console.log(err);
                    // throw err;
                  });
                // } catch (err) {
                //   console.log(err);
                //   // throw err;
                // }
              })
              .catch((err) => {
                console.log(err);
                // throw err;
              });
          }
          // console.log("pubnub", status, response);
          // handle status, response
        }
      );
      // if (location.pathname === "/patients" && ) {
      // }
    } catch (err) {
      console.log(err);
      throw err;
    }

    getPatientListFn();
    getContactTypesFn();
    createStreamListeners();
  }, []);

  const makeACall = (e) => {
    const callingData = {
      error_message: "",
      missed_call: false,
      missed_call_cause: "",
      from: "",
      office: { id: user?.current_office.id },
      patient: {
        id: "",
        phone: "",
      },
      fullName: "",
      patientID: "",
      callType: "outgoing",
    };

    bandwidthRtc.publish(
      {
        audio: true,
        video: false,
      },
      undefined,
      "usermedia"
    );

    if (e?.dialer) {
      const dialerData = {
        ...callingData,
        notExistingContact: true,
        from: e?.phone1,
        patient: {
          ...callingData?.patient,
          phone: e?.phone1,
        },
      };
      callingService
        .outgoingCallDialer(user, e.phone1)
        .then((result) => {
          // console.log(result.data);
          setNewSingleCall({
            ...dialerData,
            call_log_id: result?.data?.df_call_log,
            call_sid: result?.data.outgoing_call_sid,
          });
          setReturnCall(true);
          openModal();
          setCallOnGoing(null);
        })
        .catch((err) => {
          setSnack({
            open: true,
            severity: "error",
            message: "Invalid number",
          });
        });
    } else {
      const contactData = {
        callType: "outgoing",
        from: e?.phone1,
        office: {
          id: e?.office?.id || e?.officeID,
        },
        patient: {
          ...e,
          phone: e?.phone1,
        },
        fullName: e?.first_name
          ? e?.first_name + " " + e?.last_name
          : e.patientName,
        patientID: e?.id || e?.patientID,
      };

      callingService
        .outgoingCall(user, e.id || e?.patientID)
        .then((result) => {
          // console.log(result.data);
          setNewSingleCall({
            ...contactData,
            call_log_id: result?.data?.df_call_log,
            call_sid: result?.data.outgoing_call_sid,
          });

          setReturnCall(true);
          openModal();
          setCallOnGoing(null);
        })
        .catch((err) => {
          setSnack({
            open: true,
            severity: "error",
            message: "Invalid number",
          });
        });
    }
  };

  const handleTabClose = () => {
    if (!!remoteStream) {
      callingService.hangUpCall(user, newSingleCall?.call_sid);

      callingService.hangUpCallOutgoing(newSingleCall?.call_sid);
    }
  };

  useEffect(() => {
    if (!!remoteStream) {
      var startTimestamp = moment().startOf("day");
      increment.current = setInterval(function () {
        startTimestamp.add(1, "seconds");
        setTimer(startTimestamp.format("mm:ss"));
      }, 1000);
    } else {
      setTimer("");
      clearInterval(increment.current);
    }

    const listeners = {
      message: (event) => {
        refetchCommunications();
        handleNotifications(event);
      },
    };
    pubnub.addListener(listeners);
    pubnub.subscribe({ channels });

    window.addEventListener("beforeunload", handleTabClose);
    return () => {
      setTimer("");
      window.removeEventListener("beforeunload", handleTabClose);
      clearInterval(increment.current);
      pubnubCleanUp(pubnub, listeners);
    };
  }, [remoteStream]);

  const [renderLogHistory, { openLogHistory }] = useLogHistory(user);

  const triggerIncomingCall = async (patientData) => {
    const obj = {
      answer_type: false,
      is_call_log_popup: true,
      from: patientData?.phone1,
      type: "call",
      call_type: 2,
      call_log_id: null,
      call_sid: patientData?.call_sid,
      patient: {
        id: patientData?.patientID,
        full_name: patientData?.patientName,
        first_name: "",
        last_name: "",
        phone: "+14165626874",
        patient_status: patientData?.patientStatus,
      },
      is_window_notification: true,
      city: null,
      is_from_push_notif: true,
    };
    ringing.current.addEventListener("loadeddata", () => {
      // After the audio has loaded, you can play it.
      ringing.current.play();
    });
    handleNotifications({ message: JSON.stringify(obj) });
  };

  const handlePushNotifClick = async (platform, notifData) => {
    let patientData;
    let notificationType;
    if (platform === "mobile") {
      patientData = {
        patientID: notifData?.patientID,
        officeID: notifData?.officeID,
        patientName: notifData?.patientName,
        patientStatus: parseInt(notifData?.patientStatus),
        phone1: notifData?.phone1,
        call_sid: notifData?.call_sid,
      };
      notificationType = notifData?.notification_type;
    } else if (platform === "web") {
      const parameters = parseUrlParameters();
      if (Object.keys(parameters).length !== 0) {
        patientData = {
          patientID: parameters?.id,
          officeID: parameters?.officeId,
          patientName: parameters?.patientName,
          patientStatus: parseInt(parameters?.patientStatus),
          phone1: parameters?.phone,
          call_sid: parameters?.call_sid,
        };
      }
      notificationType = parameters?.notification_type;
    }

    if (patientData?.patientID) {
      if (["chatwidget", "sms"].includes(notificationType)) {
        await delay(1000);
        await openLogHistory(patientData);
      } else if (
        notificationType === "call_log" ||
        notificationType === "incoming_call"
      ) {
        triggerIncomingCall(patientData);
      }
    }
  };
  useEffect(() => {
    handlePushNotifClick("web");

    if (appMode !== modesMap.ANDROID) return;

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification) => {
        console.log("Push received: " + JSON.stringify(notification));
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification) => {
        console.log(
          "Yaku: Push action performed=> ",
          JSON.stringify(notification.notification.data)
        );
        handlePushNotifClick("mobile", notification.notification.data);
      }
    );
  }, []);

  return (
    <>
      <CallContext.Provider
        value={{
          patientList,
          contactTypes,
          makeACall,
          callerData: newSingleCall,
          openCall: isOpen,
          showReturnToCallButton: returnCall || callOnGoing,
          openCallPopup: openModal,
          timer,
        }}
      >
        <audio
          srcObject={remoteStream ? remoteStream.mediaStream : null}
        ></audio>
        {renderLogHistory()}

        {/* <div>
          <Button
            onClick={() => {
              incomingCallTest(
                setNewSingleCall,
                setReturnCall,
                openModal,
                closeModal,
                setCallOnGoing,
                ring
              );

              const { value } = await PubnubHandler.echo({
                value: "Hello World!",
              });
              console.log("Response from native:", value);
            }}
          >
            Trigger Incoming
          </Button>
        </div> */}
        {props.children}

        {renderSnackBar()}
        <CallPopup
          ringing={ringing}
          ring={ring}
          openCall={isOpen}
          closeModal={closeModal}
          setReturnCall={setReturnCall}
          callData={newSingleCall}
          setCallData={setNewSingleCall}
          missedCall={missedCall}
          bandwidthRtc={bandwidthRtc}
          remoteStream={remoteStream}
          callOnGoing={callOnGoing}
          user={user}
          // {...newProps}
        />
      </CallContext.Provider>
    </>
  );
}

export default CallFeature;
