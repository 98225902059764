import React, { memo } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import useCallFeature from "hooks/context-hooks/useCallFeature";
import useUpsertAppointment from "hooks/useUpsertAppointment";
import useUpsertContact from "hooks/useUpsertContact";
import { patientStatus } from "utils/ArrayUtils";
import AllConvoNew from "./AllConvoNew";
import DFIconButton from "./DFCustoms/DFIconButton";
import { DFOutgoingCall } from "./DFIcons";
import PatientStatusIcons from "./DFIcons/PatientStatusIcons";
import SendMessageBox from "./SendMessageBox";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const AllLogHistoryNew = ({
  isOpen,
  logHistoryProps,
  close,
  updatepatient,
  patient,
  user,
}) => {
  const [renderContactForm, { upsertContact }] = useUpsertContact(
    user,
    updatepatient
  );
  const [renderAppointmentForm, { openAppointmentForm }] =
    useUpsertAppointment(user);

  const { makeACall } = useCallFeature();

  const handleOpenDetails = () => {
    upsertContact({
      ...patient,
      id: patient.patientID,
    });
  };

  const handleOpenAppointment = () => {
    openAppointmentForm({
      ...patient,
      id: patient.patientID,
    });
  };

  const handleCall = (event) => {
    makeACall(patient);
    event.stopPropagation();
  };

  return (
    <Dialog
      fullScreen
      TransitionComponent={Transition}
      scroll="paper"
      open={isOpen}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        disableTypography
        style={{
          textTransform: "capitalize",
          padding: "8px 0",
          backgroundColor: `${patientStatus(
            patient.patientStatus,
            "value",
            "main_color"
          )}`,
          color: "white",
          display: "flex",
          gap: 8,
          alignItems: "center",
        }}
      >
        <DFIconButton
          onClick={close}
          color="inherit"
          icon={<ArrowBackIosIcon />}
        />

        <PatientStatusIcons
          status={patientStatus(patient.patientStatus, "value", "name")}
        />

        <Typography
          noWrap
          variant="h6"
          onClick={handleOpenDetails}
          style={{ cursor: "pointer" }}
        >
          {patient.patientName}
        </Typography>
        <div
          style={{
            display: "flex",
            color: "white",
            marginLeft: "auto",
            marginRight: "5px",
          }}
        >
          <DFIconButton
            onClick={handleOpenDetails}
            color="inherit"
            icon={<InfoOutlinedIcon />}
          />

          <DFIconButton
            onClick={handleCall}
            color="inherit"
            icon={<DFOutgoingCall />}
          />

          <DFIconButton
            onClick={handleOpenAppointment}
            color="inherit"
            icon={<CalendarTodayIcon />}
          />
        </div>
      </DialogTitle>
      <DialogContent
        style={{
          backgroundColor: `${patientStatus(
            patient.patientStatus,
            "value",
            "light_color"
          )}`,
        }}
        dividers={true}
      >
        <AllConvoNew
          logHistoryProps={logHistoryProps}
          logData={patient}
          updatepatient={updatepatient}
          user={user}
        />
      </DialogContent>
      <DialogActions>
        <SendMessageBox logHistoryProps={logHistoryProps} patient={patient} />
      </DialogActions>
      {renderContactForm()}
      {renderAppointmentForm()}
    </Dialog>
  );
};
export default memo(AllLogHistoryNew);
