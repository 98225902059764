import { GET, POST } from "utils/restUtils";
import config from "config/config";

const phoneNumberFormatter = (num) => {
  //this function removes country code
  var symbols = {
    "@": "%40",
    "&amp;": "%26",
    "*": "%2A",
    "+": "%2B",
    "/": "%2F",
    "&lt;": "%3C",
    "&gt;": "%3E",
  };
  const newNum = num.replace(/([@*+/]|&(amp|lt|gt);)/g, function (m) {
    return symbols[m];
  });

  return newNum;
};

export const getCallToken = (params) =>
  POST(
    `${config.BASE_URL}/calls/token_generate?user_id=${params?.id}&office_id=${params?.current_office?.id}`
  );

export const outgoingCall = (params, patient_id) =>
  GET(
    `${config.BASE_URL}/calls/initiate_outgoing_call?user_id=${params?.id}&office_id=${params?.current_office?.id}&patient_id=${patient_id}`
  );
export const outgoingCallDialer = (params, phone_number) =>
  GET(
    `${config.BASE_URL}/calls/initiate_outgoing_call?user_id=${
      params?.id
    }&office_id=${params?.current_office?.id}&phone=${phoneNumberFormatter(
      phone_number
    )}&dialer_call=true`
  );

export const getCallRecording = (apiVersion, account_sid, recording_sid) =>
  GET(
    `https://api.twilio .com/${apiVersion}/Accounts/${account_sid}/Recordings/${recording_sid}`
  );

export const hangUpCall = (params, call_sid) => {
  return POST(
    `${config.BASE_URL}/calls/token_generate?user_id=${params?.id}&office_id=${params?.current_office?.id}&modify_call=hang_up&incoming_call_sid=${call_sid}`
  );
};
export const hangUpCallOutgoing = (call_sid) => {
  return POST(
    `${config.BASE_URL}/calls/hangup_outgoing_call?outgoing_call_sid=${call_sid}`
  );
};

export const answerCall = (params, call_sid) => {
  return POST(
    `${config.BASE_URL}/calls/token_generate?user_id=${params?.id}&office_id=${params?.current_office?.id}&modify_call=true&incoming_call_sid=${call_sid}`
  );
};

export default {
  getCallToken,
  getCallRecording,
  hangUpCall,
  answerCall,
  outgoingCall,
  outgoingCallDialer,
  hangUpCallOutgoing,
};
