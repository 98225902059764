import { useEffect, useState } from "react";

import {
  AppBar,
  Badge,
  Button,
  Popover,
  Toolbar,
  Typography,
  useTheme,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import { usePubNub } from "pubnub-react";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "react-query";

import AutoUpdater from "components/AutoUpdater";
import { DFCardSkeleton } from "components/DFCustoms";
import DFIconButton from "components/DFCustoms/DFIconButton";
import LogDetails from "components/LogDetails";
import Notifications from "components/Notifications";
import useNotificationService from "hooks/api-hooks/useNotificationService";
import useCallFeature from "hooks/context-hooks/useCallFeature";
import useModal from "hooks/useModal";
import { patientStatus } from "utils/ArrayUtils";
import { pubnubCleanUp } from "utils/commonUtils";
import { LOG_TYPE_ICON } from "utils/constants";
import { isContactExists } from "utils/db";
import ViewToggle from "./ViewToggle";
const ipcRenderer = window?.ipcRenderer;
const useStyles = makeStyles((theme) => ({
  notifHover: {
    display: "flex",
    minWidth: "80vw",
    gap: 5,
    borderBottom: `${theme.palette.divider} 1px solid`,
    padding: "10px 16px",
    cursor: "pointer",
    "&:hover": {
      filter: "brightness(0.85)",
    },
  },
}));

export default function Header({
  title,
  user,
  drawerToggle,
  notification,
  revertContacts,
  setRevertContacts,
  isSyncing,
  backButtonCallback,
  ...props
}) {
  const { ref, inView } = useInView();
  const classes = useStyles();
  const pubnub = usePubNub();
  const [channels] = useState([user?.id]);
  const theme = useTheme();
  const { showReturnToCallButton, openCallPopup, timer } = useCallFeature();
  const [anchorEl, closeNotifPopover, openNotifPopover] =
    useModal("notif_popover");
  const [isOpenLogDetailsModal, closeLogDetailsModal, openLogDetailsModal] =
    useModal("log_details_notif");
  const [isOpenNotifModal, closeNotifModal, openNotifModal] =
    useModal("notif_modal");

  const { viewNotificationList, updateNotificationList } =
    useNotificationService();

  const [singleData, setSingleData] = useState({});
  const { data, fetchNextPage, refetch, isFetchingNextPage } = useInfiniteQuery(
    ["notifications"],
    async ({ pageParam = 1 }) => {
      const { data: fetchResult } = await viewNotificationList(pageParam);
      const returnValue = await isContactExists(
        data?.pages[0]?.data[0]?.patient_id,
        data?.pages[0]?.data[0]?.office_id,
        user.token,
        isSyncing
      );
      if (returnValue) {
        console.log("reverting to old contacts implementation");
        setRevertContacts(true);
      }

      return fetchResult;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages?.length + 1;
        return lastPage?.data?.length > 0 ? nextPage : undefined;
      },
    }
  );

  const [unreadCount, setUnreadCount] = useState(0);

  const notifications = data?.pages;
  let count = 0;

  notifications?.map((page) => {
    let dataCount = page?.data?.filter(
      (val) => val.seen_at === undefined || val.seen_at === null
    );
    return (count += dataCount.length);
  });

  useEffect(() => {
    let notificationsCount = 0;
    notifications?.map((page) => {
      page?.data?.map((val) => {
        if (val.seen_at === undefined || val.seen_at === null) {
          notificationsCount += 1;
        }
      });
    });
    setUnreadCount(notificationsCount);
  }, [notifications]);
  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);
  useEffect(() => {
    handleUpdateNotif(notification);
  }, [notification]);

  useEffect(() => {
    const listeners = {
      message: refetch,
    };
    pubnub.addListener(listeners);
    pubnub.subscribe({ channels });
    return () => {
      pubnubCleanUp(pubnub, listeners);
    };
  }, []);

  const handleClickNotifBell = (event) => {
    openNotifPopover(event.currentTarget);
  };

  const handleCloseNotifPopover = () => {
    closeNotifPopover();
  };

  const handleOpenNotifModal = () => {
    openNotifModal(true);
  };

  const handleBack = () => {
    //if a custom backButtonCallback function is defined, invoke the function
    if (backButtonCallback) {
      backButtonCallback();
    } else {
      props.history.goBack();
    }
  };

  const handleDrawerToggle = () => {
    drawerToggle();
  };

  const openLogDetails = (val) => {
    handleUpdateNotif(val?.id);
    setSingleData(val);
    openLogDetailsModal();
  };

  const handleUpdateNotif = async (id) => {
    try {
      await updateNotificationList(id);
      refetch();
    } catch (err) {
      refetch();
      console.log(err);
    }
  };

  useEffect(() => {
    ipcRenderer?.send(
      "check-for-updates",
      "Trigger check for updates event recieved"
    );
  }, []);

  return (
    <>
      <AppBar position="sticky" color="primary">
        {showReturnToCallButton && (
          <Button
            className="blink-2"
            style={{
              borderRadius: 0,
              backgroundColor: `${theme.palette.success.main}`,
              color: "#fff",
            }}
            fullWidth
            onClick={() => openCallPopup()}
          >
            Return to call {timer}
          </Button>
        )}
        <AutoUpdater />
        <Toolbar>
          {props.hasBackButton ? (
            <DFIconButton
              edge="start"
              onClick={handleBack}
              color="inherit"
              aria-label="menu"
              icon={<ArrowBackIosIcon />}
            />
          ) : null}
          <Typography
            variant="subtitle1"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textTransform: "uppercase",
              fontSize: 18,
            }}
          >
            <p style={{ marginTop: 4 }}>
              {title || props?.match?.params?.header_title}{" "}
              <ViewToggle title={title} />
            </p>
          </Typography>
          <div style={{ flexGrow: 1 }} />
          <DFIconButton
            onClick={handleClickNotifBell}
            color="inherit"
            aria-label="menu"
            icon={
              <Badge
                overlap="rectangular"
                badgeContent={count}
                color="secondary"
              >
                <NotificationsNoneIcon />
              </Badge>
            }
          />
          <DFIconButton
            edge="end"
            onClick={handleDrawerToggle}
            color="inherit"
            aria-label="menu"
            icon={<MenuIcon />}
          />
        </Toolbar>
        <Popover
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(!isOpenNotifModal && anchorEl)}
          onClose={handleCloseNotifPopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          style={{ maxHeight: "70vh" }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              position: "sticky",
              top: 0,
              bottom: 10,
              backgroundColor: "white",
              zIndex: 5,
              boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            }}
          >
            <div
              onClick={handleOpenNotifModal}
              style={{
                cursor: "pointer",
                textDecoration: "none",
                color: "#4584db",
                marginLeft: "1rem",
              }}
            >
              View all Notifications
            </div>
            <DFIconButton
              onClick={handleCloseNotifPopover}
              icon={<CloseIcon />}
            />
          </div>
          {notifications?.map((page) =>
            page?.data
              ?.filter(
                (val) => val.seen_at === undefined || val.seen_at === null
              )
              .map((val, idx) => (
                <div
                  onClick={() => openLogDetails(val)}
                  key={val.id}
                  className={classes.notifHover}
                  style={{
                    backgroundColor: `${patientStatus(
                      val.patient_status,
                      "name",
                      "light_color"
                    )}`,
                    borderBottom: `1px solid ${patientStatus(
                      val.patient_status,
                      "name",
                      "light_color"
                    )}`,
                  }}
                  ref={page?.data?.length === idx + 1 ? ref : null}
                >
                  <div style={{ padding: "4px 8px 0 0" }}>
                    <Badge
                      variant="dot"
                      anchorOrigin={{ vertical: "top", horizontal: "left" }}
                      invisible={val?.seen_at === null ? false : true}
                      color="secondary"
                    >
                      {LOG_TYPE_ICON[val?.type]}
                    </Badge>
                  </div>
                  <div style={{ flexGrow: 1 }}>
                    <Typography variant="subtitle1">
                      <b>{val.patient_name}</b>
                    </Typography>
                    <Typography variant="subtitle2">
                      {val.others.log_status}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      component="span"
                    >
                      {val.created_at + " ago"}
                    </Typography>
                  </div>
                </div>
              ))
          )}
          {unreadCount === 0 && isFetchingNextPage === false ? (
            <div style={{ padding: "1rem 2rem" }}>
              <Typography>No Unread Notifications</Typography>
            </div>
          ) : null}
          {isFetchingNextPage && (
            <DFCardSkeleton number_of_items={1} type="notification" />
          )}
        </Popover>
        <LogDetails
          user={user}
          open={isOpenLogDetailsModal}
          refetchLogs={refetch}
          singleData={singleData}
          close={closeLogDetailsModal}
          isIndependent
        />
      </AppBar>
      <Notifications
        user={user}
        notifications={notifications}
        open={isOpenNotifModal}
        close={closeNotifModal}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        handleUpdateNotif={handleUpdateNotif}
      />
    </>
  );
}
