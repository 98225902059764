import moment from "moment";
import { createContext } from "react";
import { useInfiniteQuery } from "react-query";
import useUserContext from "hooks/context-hooks/useUserContext";
import useSearchFilter from "hooks/useSearchFilter";
import useCommunicationsService from "hooks/api-hooks/useCommunicationsService";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export const CommunicationData = createContext();
const initialState = {
  patientTypeFilter: "",
  sortFilter: "-1",
  logTypeFilter: "",
  taggingFilter: "",
  callStatusFilter: "",
  sourceFilter: "",
  dateRange: [
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ],
};

const listFilters = ["Patient Type", "Sort Conversation", "Date Range"];
function CommunicationDataProvider({ children }) {
  const { user } = useUserContext();
  const history = useHistory();
  const { getAllCommunications } = useCommunicationsService();
  const [displayFilter, { search, filters, filtersPresent }] = useSearchFilter({
    listFilters: listFilters,
    initialState: initialState,
    localStorageKey: "communications",
    searchBy: "name",
    resetFiltersOnchange: true,
  });

  const {
    data,
    fetchNextPage,
    isFetchingNextPage,
    isFetching,
    isLoading,
    refetch,
  } = useInfiniteQuery(
    ["communications", filters, search],

    async ({ pageParam = 1, queryKey }) => {
      const filters = queryKey[1];
      const search = queryKey[2];
      const { data } = await getAllCommunications({
        sort: filters.sortFilter,
        search: search,
        filter: filters.patientTypeFilter,
        startDate: filters.dateRange[0].startDate
          ? moment(filters.dateRange[0].startDate).format("DD-MM-YYYY")
          : "",
        endDate: filters.dateRange[0].endDate
          ? moment(filters.dateRange[0].endDate).format("DD-MM-YYYY")
          : "",
        page: pageParam,
      });

      return data;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages?.length + 1;
        return lastPage?.data?.length > 0 ? nextPage : undefined;
      },
      enabled: !!user?.current_office?.id,
    },
    { keepPreviousData: true }
    // {
    //   retry: 1,
    // }
  );
  return (
    <CommunicationData.Provider
      value={{
        displayFilter: displayFilter,
        filtersPresent: filtersPresent,
        communications: data?.pages,
        fetchNextPage: fetchNextPage,
        refetch: refetch,
        isLoading: isLoading,
        isFetchingNextPage: isFetchingNextPage,
        isFetching: isFetching,
        // setUser: setUser,
      }}
    >
      {children}
    </CommunicationData.Provider>
  );
}

export default CommunicationDataProvider;
