import { DFDialog } from "./DFCustoms";

export default function ImageViewer({ handleCancel, open, src }: any) {
  return (
    <DFDialog
      open={open}
      title={""}
      fullWidth
      maxWidth={"xl"}
      content={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={src}
            alt=""
            style={{
              border: "1px solid #5e5b5b",
              marginBottom: 10,
              marginTop: 10,
              borderRadius: 10,
              maxHeight: "80vh",
              maxWidth: "100%",
              objectFit: "contain",
            }}
          />
        </div>
      }
      onClose={handleCancel}
    />
  );
}
