import useContactService from "hooks/api-hooks/useContactService";
import { createContext, useState } from "react";
import {
  db,
  fetchRecentlyUpdatedContacts,
  saveContactToDb,
  updateLatestSync,
} from "utils/db";
const SYNC_TYPE = { ALL: "ALL", RECENTLY_UPDATED: "RECENTLY_UPDATED" };

export const ContactListContext = createContext();

function ContactListManager(props) {
  const [isSyncing, setIsSyncing] = useState(false);
  const { getAllContacts } = useContactService();
  const fetchAllContacts = async (user) => {
    var page = 1;
    var endOfPage = false;
    var arr = [];
    // localStorage.setItem("isSyncing", "true");
    while (!endOfPage) {
      // while (page !== 5) {
      console.log("fetching page ", page);
      try {
        const { data } = await getAllContacts({
          sort: "%2Bfirst_name",
          search: "",
          filter: "",
          page: page,
        });
        arr = arr.concat(data.data);
        page++;
      } catch (e) {
        endOfPage = true;

        console.log(e);
      }
    }

    arr.map((item) => {
      saveContactToDb(item);
      return null;
    });

    await updateLatestSync(user.current_office?.id, true);
  };

  const syncContacts = async (user, isSyncing, syncType) => {
    if (!isSyncing) {
      setIsSyncing(true);
      await func(user, syncType);
      setIsSyncing(false);
    } else {
      console.log("syncing is already in progress, aborting...");
    }
  };

  const func = async (user, syncType) => {
    console.log("Syncing.....");
    if (syncType === SYNC_TYPE.ALL) {
      await fetchAllContacts(user);
    } else if (syncType === SYNC_TYPE.RECENTLY_UPDATED) {
      console.log("fetching recently updated contacts");
      await fetchRecentlyUpdatedContacts(user);
    }

    console.log("Done syncing....");
  };

  const checkSync = async (user) => {
    let isDexieError = JSON.parse(localStorage.getItem("dexieError"));
    if (isDexieError === true) {
      return;
    }

    let count = await db.patient_leads
      .where("office_id")
      .equals(user.current_office?.id)
      .count();

    if (count === 0 && !isSyncing) {
      console.log(
        "database is empty and no sync is in progress. syncing all contacts"
      );
      syncContacts(user, isSyncing, SYNC_TYPE.ALL);
      return;
    }
    let oneHour = 1800000;
    let fortyEightHours = 172800000;
    // let oneHour = 15000;
    // let fortyEightHours = 60000;
    let timeNow = Date.now();
    let latest_sync;
    try {
      latest_sync = JSON.parse(localStorage.getItem("latest_sync"));

      let timeSince48HourSync =
        latest_sync?.[user.current_office?.id]?.fortyEightHourSync;
      if (timeSince48HourSync) {
        if (timeNow - timeSince48HourSync >= fortyEightHours) {
          console.log(
            "48 hour has passed since last full sync",
            timeNow - timeSince48HourSync
          );
          syncContacts(user, isSyncing, SYNC_TYPE.ALL);
          return;
        } else console.log("No need for full sync yet");
      } else {
        console.log(
          "latest_sync of current office is null, fetching all contacts... "
        );
        syncContacts(user, isSyncing, SYNC_TYPE.ALL);
        return;
      }

      let timeSinceLastHourlySync =
        latest_sync?.[user.current_office?.id]?.hourlySync;

      if (timeSinceLastHourlySync) {
        if (timeNow - timeSinceLastHourlySync >= oneHour) {
          console.log(
            "1 hour has passed since last partial sync",
            timeNow - timeSinceLastHourlySync
          );
          syncContacts(user, isSyncing, SYNC_TYPE.RECENTLY_UPDATED);
        } else console.log("No need for partial sync yet");
      } else {
        console.log(
          "latest_sync of current office is null, fetching all contacts... ",
          latest_sync
        );
        syncContacts(user, isSyncing, SYNC_TYPE.ALL);
      }
    } catch {
      latest_sync = Date.now();
      console.log(
        "error occured while getting latest_sync data from local storage, refetching all contacts"
      );
      syncContacts(user, isSyncing, SYNC_TYPE.ALL);
    }
  };

  return (
    <ContactListContext.Provider value={{ isSyncing, syncContacts, checkSync }}>
      {props.children}
    </ContactListContext.Provider>
  );
}

export default ContactListManager;
