import React from "react";
import { DFLogoFull } from "./DFIcons";
import { useTheme } from "@material-ui/core";

function DFSplashScreen() {
  const theme = useTheme();

  return (
    <>
      <div
        style={{
          position: "absolute",
          inset: 0,
          background: theme.palette.primary.main,
          display: "grid",
          placeItems: "center",
        }}
      >
        <DFLogoFull width="75vw" color={"#fff"} />
      </div>
      <div
        id="clear-cache"
        style={{
          position: "absolute",
          display: "grid",
          placeItems: "center",
          fontSize: 14,
          inset: "95% 0px 0px 0px",
        }}
        onClick={() => {
          localStorage.removeItem("user");
          window.location.replace("#/");
        }}
      >
        Taking too long to load? Click here to refresh and try again.{" "}
      </div>
    </>
  );
}

export default DFSplashScreen;
