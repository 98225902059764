import { CommunicationData } from "pages/communications/CommunicationDataProvider";
import { useContext } from "react";

function useCommunication() {
  const {
    displayFilter,
    filtersPresent,
    communications,
    fetchNextPage,
    refetch,
    isLoading,
    isFetchingNextPage,
    isFetching,
  } = useContext(CommunicationData);
  return {
    displayFilter,
    filtersPresent,
    communications,
    fetchNextPage,
    refetch,
    isLoading,
    isFetchingNextPage,
    isFetching,
  };
}

export default useCommunication;
