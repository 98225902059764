import { GET, PUT } from "utils/restUtils";
import config from "config/config";

export const getMessageHistory = (params, customHeader) => {
  return GET(
    `${config.BASE_URL}/api/v1/patient_leads/${params?.id}/message_history?current_office=${params?.office}`,
    customHeader
  );
};
export const sendSms = (params, customHeader, data) => {
  return PUT(
    `${config.BASE_URL}/api/v1/patient_leads/${params.id}/send_sms?current_office=${params.office}&sms_content=${data.message}&api_token=${params.token}`,
    customHeader
  );
};

export const getAllSMSLogs = (
  params,
  page,
  type,
  startDate,
  endDate,
  search,
  status
) => {
  return GET(
    `${config.BASE_URL}/api/v1/sms_logs?api_token=${params.token}&page=${page}&current_office=${params.current_office.id}&sms_type=${type}&start_date=${startDate}&end_date=${endDate}&search=${search}&status=${status}`
  );
};

export const updateOneSMSLog = (
  params,
  patientID,
  smsType,
  booked,
  note,
  current_office
) => {
  if (smsType !== "none" && booked !== "none" && note === "none") {
    return PUT(
      `${config.BASE_URL}/api/v1/sms_logs/${patientID}?api_token=${params?.token}&current_office=${current_office}&sms_type=${smsType}&is_appointment_booked=${booked}`
    );
  } else {
    return PUT(
      `${config.BASE_URL}/api/v1/sms_logs/${patientID}?api_token=${params?.token}&current_office=${current_office}&sms_type=${smsType}&is_appointment_booked=${booked}&note=${note}`
    );
  }
};

export const getOneSMSLog = (params, sms_log_id) => {
  return GET(
    `${config.BASE_URL}/api/v1/sms_logs/${sms_log_id}?api_token=${params.token}&current_office=${params.current_office.id}`
  );
};

//=================================Chat Widget APIs===============================

export const sendToWiget = (params, customHeader, data) => {
  return PUT(
    `${config.BASE_URL}/api/v1/chat_widgets/office_response?lead_id=${params.lead_id}&message=${params.message}&current_office=${params.office}`
  );
};

export default {
  getMessageHistory,
  sendSms,
  getAllSMSLogs,
  updateOneSMSLog,
  getOneSMSLog,
  //Chat Widet APIs
  sendToWiget,
};
