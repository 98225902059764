import React from "react";

import Slider from "@material-ui/core/Slider";
import { LinearProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";
const IOSSlider = withStyles((theme) => ({
  root: {
    color: "#000",
    height: 2,
    padding: "15px 0 2px",
  },
  thumb: {
    height: 10,
    width: 10,
    // backgroundColor: "#000",
    boxShadow: iOSBoxShadow,
    marginTop: -4,
    marginLeft: -5,
    "&:focus, &:hover, &$active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 12px)",
    top: -22,
    "& *": {
      background: "transparent",
      color: "#000",
    },
  },
  track: {
    height: 2,
    // backgroundColor: theme.palette.action.disabled,
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
  },
  mark: {
    backgroundColor: "#bfbfbf",
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: "currentColor",
  },
}))(Slider);

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 2,
    margin: "15px 0 5px",
  },
  colorPrimary: {
    backgroundColor: "#bfbfbf",
  },
  bar: {
    backgroundColor: "#6d6d6d",
  },
}))(LinearProgress);
function AudioSlider({ percentage = 0, onChange, onError, isLoading }) {
  return isLoading ? (
    <BorderLinearProgress style={{ width: "100%" }} />
  ) : (
    <IOSSlider
      disabled={onError}
      value={percentage}
      onChange={(e, b) => {
        onChange(e, b);
        e.stopPropagation();
      }}
      onClick={(e) => e.stopPropagation()}
      step={0.01}
    />
  );
  // <Slider value={percentage} style={{paddingBottom: 0}} color="secondary" step={0.01} onChange={onChange}/>
}

export default AudioSlider;
