import { Menu, MenuItem, Popover } from "@material-ui/core";
import { useState } from "react";

function useSendMessage(sendTextMessage) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    console.log(event.currentTarget);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (mode) => {
    if (mode) sendTextMessage(mode);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const RenderPopperMenu = (props) => (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={(e) => handleClose(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={(e) => handleClose("sms_log")}>
            Send via SMS
          </MenuItem>
          <MenuItem onClick={(e) => handleClose("ChatWidget")}>
            Send via Chat
          </MenuItem>
        </Menu>
      </Popover>
    </>
  );
  return { RenderPopperMenu, handleClick };
}

export default useSendMessage;
