import { GET, PUT, POST, DELETE } from "utils/restUtils";
import config from "config/config";

export const loginUser = (data) =>
  POST(`${config.BASE_URL}/api/v1/sessions`, data);

export const logoutUser = (params) =>
  DELETE(
    `${config.BASE_URL}/api/v1/sessions/${params.id}&api_token=${params.token}`
  );

export const getOneUser = (params, page) => {
  return GET(
    `${config.BASE_URL}/api/v1/users/${params?.id}?api_token=${params?.token}&page=${page}`
  );
};
export const updateOneUser = (params, page, data) => {
  return PUT(
    `${config.BASE_URL}/api/v1/users/${params.id}?api_token=${params.token}&page=${page}&current_office=${params.current_office}`,
    data
  );
};
export const getOneClinic = (params, page) => {
  return GET(
    `${config.BASE_URL}/api/v1/accounts/${params?.current_office?.id}?api_token=${params.token}`
  );
};
export const updateOneClinic = (params, page, data) => {
  return PUT(
    `${config.BASE_URL}/api/v1/accounts/${params.current_office}?api_token=${params.token}`,
    data
  );
};
export const createResetToken = (data) => {
  return POST(`${config.BASE_URL}/api/v1/passwords?email=${data.email}`);
};

export const activateNotification = ({ userId, subscription }) => {
  return fetch(`${config.BASE_URL}/api/v1/activate_notification`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      user_id: userId,
      subscription,
    }),
  });
};

export default {
  createResetToken,
  logoutUser,
  loginUser,
  getOneUser,
  updateOneUser,
  getOneClinic,
  updateOneClinic,
  activateNotification,
};
