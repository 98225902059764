import React from "react";

import { Paper, Typography } from "@material-ui/core";
import moment from "moment";

import { LOG_TYPE_NAME_ICON } from "utils/constants";
import { MessageStatusIcon } from "./DFIcons";

function BubbleSmsLog({ val, handleOpenDialog, classes }) {
  return (
    <Paper
      onClick={() => (val.id ? handleOpenDialog(val) : "")}
      elevation={0}
      className={
        val.sms_status === "received" ? classes.paperLeft : classes.paperRight
      }
      style={{
        padding: "15px 15px",
        maxWidth: "85%",
        display: "flex",
        gap: 10,
        flexDirection: `${"row"}`,
        justifySelf: `${val.sms_status === "received" ? "start" : "end"}`,
        backgroundColor: `${
          val.sms_status === "received" ? "#fff" : "#cbf5ee"
        }`,
      }}
    >
      <div style={{ marginTop: 3 }}>{LOG_TYPE_NAME_ICON[val?.type]}</div>
      <div>
        <Typography
          gutterBottom
          style={{
            whiteSpace: "pre-wrap",
            display: "inline-block",
            wordBreak: "break-word",
          }}
        >
          {val.body || val.comment}
        </Typography>
        <div style={{ display: "flex" }}>
          <Typography
            component="div"
            variant="caption"
            style={{
              fontSize: 10,
              width: "100%",
            }}
            color="textSecondary"
            align={"right"}
          >
            {moment(val.created_at).format("hh:mm A")} via SMS
          </Typography>
          <MessageStatusIcon status={val.sms_status} />
        </div>
      </div>

      <Typography
        component="p"
        variant="caption"
        align="right"
        style={{
          fontSize: ".6rem",
          color: "rgba(0,0,0,.5)",
          marginTop: 15,
        }}
      >
        {val.sms_status === "queued"
          ? "Sending..."
          : !val.sms_status === "delivered"
          ? "Failed to send"
          : ""}
      </Typography>
    </Paper>
  );
}

export default BubbleSmsLog;
