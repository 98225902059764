import { GET, PUT, POST } from "utils/restUtils";
import config from "config/config";

export const getAllAppointments = (params, pageNumber, startDate, endDate) => {
  return GET(
    `${config.BASE_URL}/api/v1/covid_forms?api_token=${params.token}&page=${pageNumber}&current_office=${params.current_office.id}&start_date=${startDate}&end_date=${endDate}`
  );
};
export const getAllAppointmentsNew = (
  params,
  pageNumber,
  startDate,
  endDate
) => {
  return GET(
    `${config.BASE_URL}/api/v1/appointments?page=${pageNumber}&current_office=${params.current_office.id}&start_date=${startDate}&end_date=${endDate}`,
    {
      headers: {
        "api-token": params.token,
      },
    }
  );
};
export const getOneAppointment = (params, id) => {
  return GET(
    `${config.BASE_URL}/api/v1/covid_forms/${id}?api_token=${params.token}`
  );
};

export const getAppointmentStatistics = (
  params,
  startDate,
  endDate,
  headers
) => {
  return GET(
    `${config.BASE_URL}/api/v1/appointments/office_appointment_statics?current_office=${params.current_office.id}&start_date=${startDate}&end_date=${endDate}`,
    headers
  );
};
export const resendCovidForm = (params, id) => {
  return GET(
    `${config.BASE_URL}/api/v1/covid_forms/${id}/resend_covid_form?api_token=${params.token}`
  );
};
export const getOneAppointmentData = (params, id) => {
  return GET(
    `${config.BASE_URL}/api/v1/appointments/${id}/edit?api_token=${params.token}`
  );
};
export const updateForm = (params, id, data) => {
  return PUT(
    `${config.BASE_URL}/api/v1/covid_forms/${id}?api_token=${params.token}`,
    data
  );
};
export const updateAppointmentStatus = (params, id, status) => {
  return PUT(
    `${config.BASE_URL}/api/v1/appointments/${id}?api_token=${params.token}&status=${status}`
  );
};
export const updateAppointmentDate = (params, id, data) => {
  return PUT(
    `${config.BASE_URL}/api/v1/appointments/${id}?api_token=${params.token}`,
    data
  );
};
export const createAppointment = (params, data) => {
  return POST(
    `${config.BASE_URL}/api/v1/appointments?api_token=${params.token}`,
    data
  );
};
export const SendCovidForm = (params, id, data, lang) => {
  return GET(
    `${config.BASE_URL}/api/v1/patient_leads/${id}/new_covid_form_send?api_token=${params.token}&send_via_sms=${data.sms}&send_via_email=${data.email}&covid_form_language=${lang}`
  );
};
export const CovidFormHistory = (params, id) => {
  return GET(
    `${config.BASE_URL}/api/v1/patient_leads/${id}/covid_forms?api_token=${params.token}`
  );
};

export default {
  getAppointmentStatistics,
  CovidFormHistory,
  SendCovidForm,
  getOneAppointmentData,
  resendCovidForm,
  updateAppointmentStatus,
  updateAppointmentDate,
  createAppointment,
  getAllAppointments,
  getOneAppointment,
  updateForm,
  getAllAppointmentsNew,
};
